let baseURL = 'https://us-central1-reading-train-b9c37.cloudfunctions.net/deleteUser'

export async function DeleteUser(json){
    let response = await fetch(  baseURL, {
        method: 'POST', mode: 'cors',
        headers: {
         'Content-Type': 'application/json;charset=utf-8',
         Accept: '*/*',
        'Accept-Language': 'uk',
        },
        body: JSON.stringify(json)
     });
     let result = await response.json();
    return result
}
