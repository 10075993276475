export default function count(width, heigth, maxWidth, maxHeight)
{
    let countW, countH

    // countW = width*maxHeight/heigth
    
    countH = heigth*maxWidth/width
    if(countH > maxHeight)
    {
        countH = maxHeight
    }

    countW = width * countH / heigth
    

    return {countW, countH}
}