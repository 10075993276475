import React from 'react'
import classes from './BackArrow.module.scss'
import back from './img/icon-back.png'
import circle from './img/button-circle-white.png'
import planck from './img/button-pole.jpg'
import { getUnit } from '../Level/route'
class BackArrow extends React.PureComponent{
    constructor(props){
        super(props)
        this.state={
            linkarray: ['/', '/unit', '/preview', '/', '/sing', '/sing/start' ]
        }
        this.goBack = this.goBack.bind(this)
    }

    goBack(){
        if(this.props.history.location.pathname === '/preview' || this.props.history.location.pathname ===  '/newwords' ||
        this.props.history.location.pathname === '/test'  ){
            this.props.history.push('/unit', getUnit())
        }
        else{
            if(this.props.history.location.pathname === '/unit'){
                this.props.history.push('/level')
            }else{
                this.props.history.goBack()
            }
        }
    }
    UNSAFE_componentWillMount(){
    }

    render(){
        return(
            <div onClick={this.goBack} className={classes.BackArrow}>
                <img className={classes.back} alt='' src={back} />
                <img className={classes.circle} alt='' src={circle} />
                <img className={classes.planck} alt='' src={planck} />
            </div>
        )
    }
} 

export default BackArrow