import React from 'react'
import classes from './Train.module.scss'
import TraineCar from './TrainCar/TrainCar'
import traineEngine from '../../../../source/trains/train-0-0-engine.png'
import promt from '../../../../source/trains/train-prompt.png'
import wheelSmall from '../../../../source/trains/train-0-0-wheel-small.png'
import wheelLarge from '../../../../source/trains/train-0-0-wheel-large.png'
import iconListen from '../../../../source/trains/icon-listen.png'
import HTML5Backend from 'react-dnd-html5-backend'
import { DndProvider } from "react-dnd";
import cn from 'classnames'

class Train extends React.PureComponent {
    constructor(props){
        super(props);
        this.state={
            ansSumm: [0,300,449,579,689,779,869,909, 949, 949],
            width: 0,
            index: [],
            present:{
                index: [1,2,3,4],
            }
        }
    }
    async componentWillReceiveProps(){
        let z = 0
        this.state.index = []
        if(this.props.present)
        {
            for(let i = 0; i < 4; i++){
                this.state.index.push(i)
                z += this.state.ansSumm[i]
            }  
        }
        else
        {
            for(let i = 0; i < this.props.numberOfAnswer; i++){
                this.state.index.push(i)
                z += this.state.ansSumm[i]
            }    
        }
            let a = []
            this.state.index.map(x => {
                if(!a.includes(x)) {
                a.push(x)
                }
                return 0
            })
            this.state.index = a
            for(let i = 0; i < a.length; i++){
                z += this.state.ansSumm[i]
            }
            await this.setState({
                width: z
            }) 
       
       this.forceUpdate()
    }
    async UNSAFE_componentWillMount(){
        let x = 0
        for(let i = 0; i < this.props.numberOfAnswer; i++){
            this.state.index.push(i)
            x += this.state.ansSumm[i]
        }
        await this.setState({
            width: x
        })

    }
    
    
   
    render(){
        let z = [0,1,2,3]
        return(
            <DndProvider backend={HTML5Backend}>
            <div id='Train' className={classes.Train} style={{width: this.state.ansSumm[this.state.index.length]+'px'}}>
                <div className={classes.traineEngine}>
                    
                    <div className={classes.promt} onClick={this.props.play}>
                        <img className={classes.promtImg}  src = {promt} alt='promt' />
                        <img className={classes.promtListenIcon} src = {iconListen} alt='listen' />
                    </div>
                    
                    <img className={classes.engine} src = {traineEngine} alt='' />
                    <div className={classes.whell}> 
                        <img id="wheel" className={classes.small} src = {wheelSmall} alt='' />
                        <img id="wheel" className={classes.small} src = {wheelSmall} alt='' />
                        <img id="wheel" className={classes.large} src = {wheelLarge} alt='' />
                        <img id="wheel" className={classes.large} src = {wheelLarge} alt='' />
                    </div>
                </div>
                
                <div className={classes.traineCar}>
                    { this.props.present ?
                    z.map((data) => {
                        return <TraineCar present={false}  id={data}/>
                    })                    
                     : this.state.index.map(data =>{
                        return <TraineCar present={false} id={data}/>
                    })}
                </div>
                
                
            </div>
            </DndProvider>
        )
    }
}

export default Train