import React from 'react'
import classes from './Block.module.scss'
import ribbon from '../../../../../source/Images/trains/present-ribbon@2x.png'
import ScaleText from "react-scale-text";
class Block extends React.PureComponent {
    constructor(props){
        super(props);
        this.state = {

        }
    }

    async componentDidUpdate(prevProps, prevState){
        if( prevProps.img !== this.props.img ){
            this.forceUpdate()
        }
    }


    

    componentWillReceiveProps(){
        this.forceUpdate()
    }
    render(){
        let text = this.props.text ? this.props.text.replaceAll("_", " ") : this.props.text
        return(
            <div id="block" className={classes.block}   style={this.props.style1 ? {height:'auto', width:'91px'}:{  }}>
                <div style={this.props.style1 ? {height:'auto', width:'91px'}:{   padding: '10px', width:'60px', height:'60px'}}>
                {this.props.text ? 
                    <ScaleText>
                        <div id='text' className={classes.text}> 
                        {text} 
                        </div>
                    </ScaleText>
                    :
                    <div style={this.props.style1 ? {position:'relative'}:{}} className={classes.img}> 
                    {this.props.style1
                    ?
                    <img alt='' style={{position:'absolute', left:'16%', top:'-18%'}} src = {ribbon} />
                    :
                    <div />
                    }
                    <img style={this.props.style1 ? {width: '100%', height:'100%', paddingTop:'0'}:{}} src={this.props.img} alt='' /> 
                    
                    </div>
                }
                </div>
            </div>
        )
    }
}

export default Block