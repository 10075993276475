import { connect } from 'react-redux';
import { compose} from 'recompose';

import RegisterPage from './RegisterPage';

const mapStateToProps = state => ({
    data: state,
});

export default compose(
    connect(mapStateToProps),
)(RegisterPage);
