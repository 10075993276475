import React from 'react'
import classes from './OneWord.module.scss'
import FullBlock from '../FullBlock/FullBlock'
import { oneWord, makeWord, makeSentence, findCreatesWith, multiCorrect }from '../../Functions/OneWord' 
import HTML5Backend from 'react-dnd-html5-backend'
import { DndProvider } from "react-dnd";
const update = require('immutability-helper');
class OneWord extends React.PureComponent {
    constructor(props){
        super(props);
        this.state={
            oneWord: {words: [], correct: ''},
            correct: [],
            quizSetting: []
        }
    }
    deleteItem = id => {
      }


    
      moveCard = (dragIndex, hoverIndex) => {
        const { cards } = this.state
        const dragCard = cards[dragIndex]
    
        this.setState(
          update(this.state, {
            cards: {
              $splice: [[dragIndex, 1], [hoverIndex, 0, dragCard]],
            },
          }),
        )
      }
    
    async componentWillUpdate(){
      
    }


    checkLenght(text){
      let check = text
      if(check){
        while( check.indexOf(' ') !== -1 ){
          check.replaceAll(' ', '')
        }
        if(check.length === 0 ){
          return false
        }
        else{
          return true
        }
      }else{
        return false
      }
    }

    async updateRef(){
      if(this.props.present){
        let obj = {
          words: [1,2,3,4], correct: ''
        }
        await this.setState({
          quizSetting: obj
      })
      }
      else
      {

      
      let plist = require('plist')
      try {
        const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/quiz.js`);
        let json = await plist.parse(src)
        await this.setState({
            quizSetting: json
        })
      } catch (err) {

      }
      switch(this.props.type){
        case '0a': 
          {
            
            let mas = multiCorrect(this.props.pages.questions[0].correct,this.state.quizSetting['0a'][this.props.pages.identifier.slice(0, 5)].trainCars , this.state.quizSetting['0a'][this.props.pages.identifier.slice(0, 5)].distractors)
            mas.words = mas.words.sort(function(){ return Math.random() - 0.5})
            await this.setState({
              oneWord: mas
            })
            this.props.getCorrect(mas.correct) //, numberOfAnswer = this.state.quizSetting['0a'][this.props.pages.identifier.slice(0, 5).answersMissing])
            break;
          }

        case '0b':
          {
            let oneWord2 = oneWord(this.props.pages.questions[0].correct,this.state.quizSetting['0b'][this.props.pages.identifier.slice(0, 5)].trainCars , this.state.quizSetting['0a'][this.props.pages.identifier.slice(0, 5)].distractors)
            oneWord2.words = oneWord2.words.sort(function(){ return Math.random() - 0.5})
            await this.setState({
                oneWord: oneWord2
            })
            this.props.getCorrect(oneWord2.correct)
            break
          }
        
        case '0c':
          {
            let oneWord2 = oneWord(this.props.pages.questions[0].correct,this.state.quizSetting['0b'][this.props.pages.identifier.slice(0, 5)].trainCars , this.state.quizSetting['0a'][this.props.pages.identifier.slice(0, 5)].distractors)
            oneWord2.words = oneWord2.words.sort(function(){ return Math.random() - 0.5})
            await this.setState({
                oneWord: oneWord2
            })
            this.props.getCorrect(oneWord2.correct)
            break
          }

        case '1a':
          {
            let obg = {
              page: this.props.pages.pages,
              level:this.props.pages.identifier.slice(0,5)
            }
            let makeS = makeSentence(obg)
            let makeSCopy =  makeS.slice(0)
            makeSCopy = makeSCopy.sort(function(){ return Math.random() - 0.5})
            obg = {
              words: makeSCopy,
              correct: makeS
            }
            obg.words = obg.words.sort(function(){ return Math.random() - 0.5})
            await this.setState({
              oneWord: obg
            })
            this.props.getCorrect(makeS)
            break
          }

        case '1b':
          {
            let obg = {
              page: this.props.pages.pages,
              level:this.props.pages.identifier.slice(0,5)
            }
            let makeS = makeSentence(obg,this.state.quizSetting['0b'][this.props.pages.identifier.slice(0, 5)].trainCars , this.state.quizSetting['0a'][this.props.pages.identifier.slice(0, 5)].distractors)
            let makeSCopy =  makeS.slice(0)
            // makeSCopy.words.sort(function(){ return Math.random() - 0.5})
            makeSCopy = makeSCopy.sort(function(){ return Math.random() - 0.5})
            obg = {
              words: makeSCopy
            }
            await this.setState({
              oneWord: obg
            })
            this.props.getCorrect(makeS)
            break;
          }

        case '2a':
          {
            let word =  makeWord(this.props.pages.questions[2].correct, this.state.quizSetting['2a'][this.props.pages.identifier.slice(0, 5)].distractors)
            word.words = word.words.sort(function(){ return Math.random() - 0.5})
            await this.setState({
              oneWord: word
            })
            this.props.getCorrect(word.word)
            break
          }

        case '2b':
          {
            let word =  makeWord(this.props.pages.questions[0].correct, this.state.quizSetting['2b'][this.props.pages.identifier.slice(0, 5)].distractors)
            word.words = word.words.sort(function(){ return Math.random() - 0.5})
            await this.setState({
              oneWord: word
            })
            this.props.getCorrect(word.word)
            break;
          }

        case '3a':
          {
            let obg = {
              correct: this.props.pages.questions[3].correct,
              incorrect: this.props.pages.questions[3].incorrect
            }

            let mas = findCreatesWith(obg,this.state.quizSetting['0b'][this.props.pages.identifier.slice(0, 5)].trainCars , this.state.quizSetting['0a'][this.props.pages.identifier.slice(0, 5)].distractors)
            obg = {
              words: mas.correct.concat(mas.incorrect)
            }
            obg.words = obg.words.sort(function(){ return Math.random() - 0.5})
            
            await this.setState({
              oneWord: obg
            })

            this.props.getCorrect(mas.correct)
            break;
          }
        default:{
        }
      }
    }
    }

  componentDidUpdate(){
    if(!this.props.present)
    {

      for(let i = 0; i < 10; i++){
        if( document.getElementById( i +'Car') )
          
            if(document.getElementById( i +'Car').childNodes[1])
            {
                let id = document.getElementById( i +'Car').childNodes[1].id.slice(0,1)
                if(  document.getElementById( id + 'drag'))
                document.getElementById( id + 'drag').appendChild(document.getElementById( i +'Car').childNodes[1])
            }
    }
      if(this.props.type === '1a' || this.props.type === '2a' || this.props.type === '2b'){
        setTimeout( () => {
          for(let i = 0; i < 10; i++){
            if( document.getElementById( i +'Car') )
              
                if(document.getElementById( i +'Car').childNodes[1])
                {
                    let id = document.getElementById( i +'Car').childNodes[1].id.slice(0,1)
                    if(  document.getElementById( id + 'drag'))
                    document.getElementById( id + 'drag').appendChild(document.getElementById( i +'Car').childNodes[1])
                }
        }
          let counts = 0 
          let maxNumberAlready = this.props.pages.identifier.slice(0, 5) === '00001' ? 2 : this.props.pages.identifier.slice(0, 5) === '00002' ? 1 : 0

          for( let i = 0; i < this.state.oneWord.words.length; i++)
          {
            let  check = Math.floor(Math.random()*10) % 2 
            if( counts <= this.state.oneWord.words.length - 3 ){
              check = Math.floor(Math.random()*10) % 2
            }
            else{
              check = 1
            }
            if( check === 0 && maxNumberAlready > counts ){
              counts = counts + 1
              if( document.getElementById( i + 'drag')){
                if( document.getElementById( i + 'Car')){
                    if(this.state.oneWord.correct)
                    {
                      if(this.state.oneWord.correct.indexOf(this.state.oneWord.words[i]) !== -1)
                      {
                        let drag = document.getElementById( i + 'drag').childNodes[0]
                        let drop = document.getElementById( this.state.oneWord.correct.indexOf(this.state.oneWord.words[i]) + 'Car')
                        if( drag && drop ){
                          if(drop.childNodes.length === 1){
                            drop.appendChild(drag)
                          }
                        }
                      }
                    }
                    else{
                      if(this.state.oneWord.word)
                      if(this.state.oneWord.word.indexOf(this.state.oneWord.words[i]) !== -1)
                      {
                        let drag = document.getElementById( i + 'drag').childNodes[0]
                        let drop = document.getElementById( this.state.oneWord.word.indexOf(this.state.oneWord.words[i]) + 'Car')
                        if( drag && drop ){
                          if(drop.childNodes.length === 1){
                            drop.appendChild(drag)
                          }
                        }
                      }
                    }
                }
              }
            }
          }
        }, 5 )
       
      }
    }
  }

    render(){
      let z = [1,2,3,4]
        return(
        <DndProvider backend={HTML5Backend}>
            <div className={classes.oneWord}>
                <div
                 className={classes.response}>
                   {
                     this.props.present ? 
                        z.map((data,i) =>{
                          return(
                            <div key = {i} id={ i + 'drag'}
                              className={classes.block}> 
                              <FullBlock id={i} present = {this.props.present} text={'data'} key = {i}  shema ={ this.props.pages.schema}  handleDrop={(id) => this.deleteItem(id)} />
                           </div>
                          )
                        })
                        :
                     this.state.oneWord.words.map((data, i) => {
                      // error with word contain spaceces 
                      let dataCopy = data
                      data = data.replaceAll(" ", '_')
                          return(
                          <div key = {i} id={ i + 'drag'}
                             className={classes.block}> 
                              <FullBlock id={i} 
                              txt={dataCopy}
                              display={ this.checkLenght(data) ?  'block'  :  'none'  }
                              leng = {this.props.numberOfAnswer }
                              type={this.props.type === '3a' ? true : false}  
                              text={data} key = {i}  shema ={ this.props.pages.schema}  handleDrop={(id) => this.deleteItem(id)} />
                          </div>)  
                      
                      
                    })
                   }
                </div>
            </div>
        </DndProvider>
        )
    }
}

export default (OneWord)