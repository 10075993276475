import { connect } from 'react-redux';
import { compose } from 'recompose';

import BooksPreview from './BooksPreview';

const mapStateToProps = state => ({
    data: state,
});

export default compose(
    connect(mapStateToProps),
)(BooksPreview);
