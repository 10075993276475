import React from 'react'
import {
  // BrowserRouter as Router,
  Route,
  Switch,
  Router,
} from 'react-router-dom'
import { Provider } from 'react-redux';
import {routes} from './routes'
import history  from './history'
import { CookiesProvider } from 'react-cookie';
import BaseLayout from './scenes/BaseLayout/BaseLayout'
const RouteWithSubRoutes = (route) => (
  <Route path={route.path} render={(props) => (
    <route.component {...props} routes={route.routes}/>
  )}/>
)

class App extends React.PureComponent {

  render() {
    const { store } = this.props;
    return (
      <CookiesProvider>
        <Provider store={store}>
          <Router history = {history}>
            <BaseLayout history = {history}>
              <Switch>
              {routes.map((route) => (
                <RouteWithSubRoutes key={route.path} {...route} />
                ))}
              </Switch>
                </BaseLayout>
          </Router>
        </Provider>  
      </CookiesProvider>
      
    )
  }
}

export default App