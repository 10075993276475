import React from 'react'
import classes from './Block.module.scss'
class PresentBlock extends React.PureComponent {
    constructor(props){
        super(props);
        this.state = {
            img: []
        }
    }

    componentWillReceiveProps(){
        this.forceUpdate()
    }

    async UNSAFE_componentWillMount(){
        let img = []
        try {
            const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/Images/trains/present-${Math.floor(Math.random()*5)}@2x.png`);
            img.push(src)
        } catch (err) {
        }
        try {
            const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/Images/trains/present-${Math.floor(Math.random()*5)}@2x.png`);
            img.push(src)
        } catch (err) {
        }
        this.setState({
            img: img
        })
    }
    render(){
        return(
            <div id="block" className={classes.block}>
                    <div className={classes.img}> <img src={this.state.img[0]} alt='' /> </div>
                    <div className={classes.img}> <img src={this.state.img[1]} alt='' /> </div>
            </div>
        )
    }
}

export default PresentBlock