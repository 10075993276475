import Books from './scenes/Books/components/BooksPreview'
import BooksPage from './scenes/Books/components/BooksPage'
import Level from './scenes/Level'
import Finnal from './scenes/Books/components/FinalPage'
import Tests from './scenes/AfterReadingTests'
import Unit from './scenes/Level/components/Unit'
import LoginPage from './scenes/UserDetail/LoginPage'
import SingBook from './scenes/SingBook'
import SingStart from './scenes/SingBook/Listening/Listening'
import RegisterPage from './scenes/UserDetail/RegisterPage'
export const routes = [
  {
    path: '/',
    exact: true,
    component: RegisterPage //LoginPage
    
  },
  {
    path: '/login',//it`s part time must be '/'
    exact: true,
    component: LoginPage 
    
  },
  {
    path: '/register',
    exact: true,
    component: RegisterPage
    
  },
  {
    path: '/level',
    exact: true,
    component: Level
    
  },
  {
    path: '/sing',
    exact: true,
    component: SingBook
  },
  {
    path: '/sing/start',
    exact: true,
    component: SingStart
  },
  {
    path: '/level',
    exact: true,
    component: Level
    
  },
    {
      path: '/bookspage',
      exact: true,
      component: BooksPage
    },
    {
      path: '/preview',
      exact: true,
      component: Books
    },
    {
      path:'/newwords',
      exact: true,
      component: Finnal
    },
    {
      path:'/test',
      exact: true,
      component: Tests
    },
    {
      path:'/unit',
      exact: true,
      component: Unit
    }
  ]