 let LEVEL = ''
 let UnitInfo = ''

 export function setLevel(data){
     LEVEL = data
 }

 export function getLevel(){
    return LEVEL
}


export function setUnit(data){
    UnitInfo = data
}

export function getUnit(){
   return UnitInfo
}