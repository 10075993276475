import { connect } from 'react-redux';
import { compose} from 'recompose';

import LoginPage from './LoginPage';

const mapStateToProps = state => ({
    data: state,
});

export default compose(
    connect(mapStateToProps),
)(LoginPage);
