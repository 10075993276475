import React from 'react'
// const Artists = lazy(() => import(‘./Artists’))

import classes from './Level.module.scss'
import LevelRender from './components/LevelRender/LevelRender'
import ChosseLevel from './components/ChosseLevel/ChosseLevel'
import { BoxLoading } from 'react-loadingg';
import {setLevel, getLevel, getUnit, setUnit} from './route'
import UserDetail from '../UserDetail/UserDetail'
class Level extends React.Component {
    constructor(props){
        super(props);

        this.state ={
            level: getLevel(),
            currentUnit: '',
            unit: [],
            song: new Audio(),
            currentUnitRender: false,
            loaded: false,
            unitInfo: {
                img: [],
                name: [],
                bg: []
            },
            title: [],
            userAccountShow: false
        }
        this.LevelChange = this.LevelChange.bind(this)
        this.closeUnit = this.closeUnit.bind(this)
        this.checkUnit = this.checkUnit.bind(this)
        this.playAudio = this.playAudio.bind(this)
        this.saveTitle = this.saveTitle.bind(this)
    }


    async componentWillMount(){
       
    }

    componentWillUnmount()
    {
        this.state.song.pause()
    }
    
    async playAudio(words, unit){
        let word = words
        let z = ''
        word = word.toLowerCase()
        // word  = word.slice(0, -1);
        // word = word.replaceAll('.', '')
        try {
            const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/ReadingTrain-Core/ReadingTrain-Core/Courses/${this.state.level}/${unit}/${word}.mp3`);
            z = src
          } catch (err) {
            this.setState({ err: err.toString() });
          }
        this.state.song.src = z  
        this.state.song.play();
    }


    async checkUnit(unitId, unitName){
        await this.setState({
            currentUnit: unitId,
            currentUnitRender: true,
        })
        this.forceUpdate()
        let plist = require('plist');
        let module = ''
        try {
            const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/ReadingTrain-Core/ReadingTrain-Core/Courses/${this.state.level}/${unitId}/unit.js`);
            let json = await plist.parse(src)
            module = json.modules
            // await this.setState({
            //     modules: json.modules
            // })
          } catch (err) {
          }

          let info = {
            identifier: [],
            img: [],
            json: [],
            bg: [],
        }
        for(let i = 0; i< module.length; i++ )
        {
            // parse book xml to json file
            try {
                const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/ReadingTrain-Core/ReadingTrain-Core/Courses/${this.state.level}/${unitId}/${module[i].identifier}/module.js`);
                let json = await plist.parse(src)
                info.json.push(json)
                
              } catch (err) {
              }

            // parse book img
            try {
                const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/ReadingTrain-Core/ReadingTrain-Core/Courses/${this.state.level}/${unitId}/${module[i].identifier}/0.jpg`);
                info.img.push(src)
                
            } catch (err) {
            this.setState({ err: err.toString() });
          }
        info.identifier.push(module[i].identifier)
        
        }
        info.bg = this.state.unitInfo;

        let backgroung = ''
        try 
        {
            const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/Images/country/splash-bg-low.png`);
            backgroung = src
            
        } catch (err) 
        {
        }
        if(this.state.level === '00004')
        {
            this.props.history.push('/sing', { bg:backgroung, title: unitName, unit: this.state.currentUnit})
        }
        else
        {
            let unitData = {level: this.state.level, unit: 
                this.state.currentUnit, unitId: unitId, unitName: unitName, module: module, info: info, background: backgroung}
            setUnit(unitData)
            this.props.history.push('/unit', {level: this.state.level, unit: 
                this.state.currentUnit, unitId: unitId, unitName: unitName, module: module, info: info, background: backgroung})
        }
        // <Unit parentProps = {this.props} level={this.state.level} unit = {this.state.currentUnit} close={this.closeUnit}  />

    }


    closeUnit(){
        this.setState({
            currentUnit: ''
        })
    }

    async LevelChange(e){
        switch(e.target.innerHTML.slice(1,-1))
        {
            case 'Level A':{
                        await this.getXML('00001')
                        this.setState({
                            userAccountShow: false
                        })
                break;
            }
            case 'Level B':{
                        await this.getXML('00002')
                        this.setState({
                            userAccountShow: false
                        })
                break;
            }
            case 'Level C':{
                        await this.getXML('00003')
                        this.setState({
                            userAccountShow: false
                        })
                break;
            }
            case 'Songs':{
                await this.getXML('00004')
                this.setState({
                    userAccountShow: false
                })
                break;
            }
            case 'Account': {
                setLevel('00005')
                this.setState({
                    userAccountShow: true,
                    level: '00005'
                })
                this.forceUpdate()
                break;
            }
        }
        this.forceUpdate();
    }
    



    async getXML(level){
        let plist = require('plist');
        setLevel(level)
        if(level === '00004')
        {
            let obg = []
            try {
            await this.setState({
                currentUnitRender: true
            })
                const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/ReadingTrain-Core/ReadingTrain-Core/Songbooks/IncludedSongbooks.js`);
                let json = await plist.parse(src)
                for(let i = 0; i < json.length; i++)
                {
                    const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/ReadingTrain-Core/ReadingTrain-Core/Songbooks/${json[i]}/songbook.js`);
                    let json1 = await plist.parse(src)
                    obg.push(json1)
                }
            await this.setState({
                level: level,
                unit: obg,
                currentUnitRender: false
            })
            } catch (err) {
            }
        }
        else
        {
            if(level !== '00005'){
                try {
                    const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/ReadingTrain-Core/ReadingTrain-Core/Courses/${level}/course.js`);
                    let json = await plist.parse(src)
                    await this.setState({
                        level: level,
                        unit: json.units,
                        pages: json.pages
                    })
                } catch (err) {
                }
            }
        }
        
    }
    async UNSAFE_componentWillMount(){
        let level = getLevel()
        if(level === '')
        {
            await this.getXML('00001')
        }
        else{
            if(level !== '00005')
            await this.getXML(level)
        }
    }

    async componentDidMount(){
        let info = ''
        await this.props.data.allData.then(await function(result) { info = result});
        this.setState({
            unitInfo: info,
            loaded: true
        })
    }


    saveTitle(title)
    {
        if(!this.state.title.includes(title))
        this.state.title.push(title)
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(this.state.title !== nextState.title)
        {
            return false
        }
        else
        {
            return true
        }
    }

    render(){
        return(
            <div className={classes.makeSmall}>
                <div className={ classes.rtLevel}>
                    <div style={this.state.currentUnitRender ? {backgroundColor:'rgba(50, 50, 50, 0.8)', position:'fixed', width:'1000px', height:'693px', zIndex:'50'} : {opacity:'0'}}>

                                <BoxLoading />
                            
                    </div>
                    
                    <div>
                            <ChosseLevel history={this.props.history} level={this.state.level} onClick={this.LevelChange} />
                        <div className={classes.bg}
                        //  style={{background:`url(${bg})`}} 
                        >
                            {getLevel() === '00005' ? <UserDetail history={this.props.history} /> :
                            <div className={classes.unit}> 
            
                                {this.state.unit.map((data,i) => {
                                    return   <LevelRender 
                                            getTitle={this.saveTitle}
                                            bg={this.state.unitInfo[i]}  
                                            checkUnit={this.checkUnit} 
                                            key={i} 
                                            unitChosen ={this.UnitChosen}  
                                            level={this.state.level}
                                            title = {this.state.level ==='00004' ? this.state.unit[i].name : this.state.title[i] ? this.state.title[i] : null} 
                                            unit = {this.state.unit[i].identifier} 
                                        />
                                    
                                })
                            }
                            </div>
                            }
                        </div>
                    </div>
                    
                    
                </div>
            </div>
        )
    }
}

export default Level