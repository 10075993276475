import React from 'react'
import classes from './RegisterPage.module.scss'
import Input from '../components/Input/Input'
import  '../components/validate'
import { validetaName, validetaZip, validetaEmail, validetaPassword } from '../components/validate'
import firebase from '../../../Firebase/fireconfig'
import {auth, db} from '../../../Firebase/fireconfig'
import UserInfo from './components/UserInfo'
import UserType from './components/UserType'
class RegisterPage extends React.PureComponent {
    constructor(props){
        super(props)
        this.state = {
            selectedCreate: 'educators',
        }
        this.createUser = this.createUser.bind(this)
    }

    createUser(tag){
        if(tag === ''){
            this.props.history.push('login')
        }else{
            this.setState({
                selectedCreate: tag
            })
        }
    }


    render(){
        return(
        <div style={{ height:'100%', minHeight:'100vh', display:'flex', flexDirection:'column', justifyContent:'center', paddingTop:'20px' }} className={classes.RegisterPage}>
            
            {
                this.state.selectedCreate === 'child' ? <UserInfo create = {this.createUser} tag={this.state.selectedCreate} history={this.props.history} /> :
                this.state.selectedCreate === 'educators' ? <UserInfo create = {this.createUser} tag={this.state.selectedCreate} history={this.props.history} /> :
                <UserType history={this.props.history} create = {this.createUser} />
            }
            
        </div>)
    }
}

export default RegisterPage