import React from 'react'
import classes from './Header.module.scss'
import logo from './img/logo.png'
import { NavLink } from 'react-router-dom'
import { withCookies, Cookies } from 'react-cookie';
class Header extends React.PureComponent{
    constructor(props){
        super(props)
        const { cookies } = this.props; 
        this.state={
            email: this.props.cookies.cookies.email
        }
        this.clearUser = this.clearUser.bind(this)
        this.updatePage = this.updatePage.bind(this)
    }

    updatePage(){

    }
    async clearUser(ev){
        ev.preventDefault()
        if(ev.target.innerHTML === ' Logout ')
        {
            const { cookies } = this.props;
            cookies.remove('email')
            cookies.remove('password')
            cookies.set('email', '', { path: '/' });
            cookies.set('password', '', { path: '/' })
            await this.setState({
                email: ''
            })
            this.forceUpdate()
            this.forceUpdate()
            this.forceUpdate()
        }
        else
        {
            if(this.props.cookies.cookies.email === '')
            {
                this.props.history.push('/login')
                document.location.reload(true)
            }
            else
            {
                alert("You are already logged")
            }
        }
       
    }

    async componentWillReceiveProps(){
        this.forceUpdate()
        this.forceUpdate()
        this.forceUpdate()    
    }
    async UNSAFE_componentWillMount(){
        // browser.cookies.onChanged.addListener(this.updatePage)
    }
    render(){
        const { cookies } = this.props;
        return(
            <div className={classes.header}>
                <div className={classes.logo}>
                    <NavLink to='/'>
                        <img alt='' src={logo} />
                    </NavLink>
                </div>
                <div className={classes.button}>
                    <NavLink to='/register'>
                        <button onClick={this.clearUser}> {"Sign Up" } </button>
                    </NavLink>
                    <NavLink to='#'>
                        <button onClick={this.clearUser}> {this.props.cookies.cookies.email === '' ? 'Login'  :"Logout"} </button>
                    </NavLink>
                </div>  
            </div>
        )
    }
}

export default  withCookies(Header)