import React from 'react'
import classes from './BaseLayout.module.scss'
import Header from '../Header'
import {auth} from '../../Firebase/fireconfig'
class BaseLayout extends React.Component{
    constructor(props){
        super(props)
        this.state ={

        }
    }

    
    

    render(){
        if(auth.currentUser){

        }else{
            if( window.location.pathname === '/register' || window.location.pathname === '/login' ){

            }else{
                this.props.history.push('login')
            }
        }
        return(
            <div>
            {/* <Header history = {this.props.history}/> */}
            <div className="content">
              {this.props.children}
            </div>
          </div>
        )
    }
}

export default BaseLayout