import React from 'react'
import classes from './TraineCar.module.scss'
import wheel from '../../../../../source/trains/train-0-0-wheel-large.png'
import traineCar from '../../../../../source/trains/train-0-0-car.png'
import { DropTarget } from 'react-dnd';
import PresentBlock from '../../PresentBlock/PresentBlock'
function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    hovered: monitor.isOver(),
    item: monitor.getItem(),
  }
}


const itemSource={
    

    drop(props, monitor, component) {
        const item = monitor.getItem()
        return {
            id: props.id + "Car",
            ids: item.component.props.id + "Card",
            type: 'move'
          }
    }

    
}


class TraineCar extends React.PureComponent{
    constructor(props){
        super(props);
        this.state ={

        }
    }


    render(){
        const { connectDropTarget} = this.props;
        return  connectDropTarget(
            <div id={this.props.id + "Car"} className={classes.TraineCar}>
                {this.props.present 
                ?
                    <div style={{width:'100%', height:'100%', zIndex:"10"}} >
                        <img className={classes.traineCar} src={traineCar} style={{zIndex:'-16'}} alt=''/>
                        <PresentBlock />
                        <div className={classes.wheel}>
                            <img id="wheel" src={wheel} alt='' />
                            <img id="wheel" className={classes.secondWhell} src={wheel} alt='' />
                        </div> 
                    </div>
                :
                    <div style={{width:'100%', height:'100%', zIndex:"10"}} >
                        <img className={classes.traineCar} src={traineCar} style={{zIndex:'-16'}} alt=''/>
                        <div className={classes.wheel}>
                            <img id="wheel" src={wheel} alt='' />
                            <img id="wheel" className={classes.secondWhell} src={wheel} alt='' />
                        </div>
                    </div>
                }
                
            </div>
        )
    }
}

export default DropTarget('item', itemSource, collect)(TraineCar)