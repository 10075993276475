function remove_duplicates(arr) {
    let s = new Set(arr);
    let it = s.values();
    return Array.from(it);
}

export function oneWord(wordList, correctNum, falseNum){
    let newArray = []
    for(let i = 0; i < wordList.length; i++)
    {
        newArray.push(wordList[i].toLowerCase())
    }
    wordList  = remove_duplicates(newArray)
    wordList = wordList.sort(function(){ return Math.random() - 0.5})
    let correct = wordList[0]
    wordList = wordList.filter(item => item !== correct)
    let chekedWord = []
    let correctWord = []
    for(let i = 0; i < correctNum; i++)
    {
        correctWord.push(correct)
        chekedWord.push(correct)
    }
    for(let i = 1; i < falseNum + 1; i++)
    {
        chekedWord.push(wordList[i])
    }
    return {words: chekedWord, correct:correctWord }
}


export function makeWord(wordList,  falseNum){
    const randomWord = Math.floor(Math.random() * wordList.length)
    let word = wordList[randomWord]
    let characterMas = []
    let characters = 'abcdefghijklmnopqrstuvwxyz';
    let r = Math.floor(Math.random() * characters.length)
    let addedCharacter = characters[r]
    for(let i = 0; i< word.length; i++)
    {
        characterMas.push(word[i])
    }
    for(let i = 0; i < falseNum; i++)
    {
        while(characterMas.includes(addedCharacter)){
            let r = Math.floor(Math.random() * characters.length)
            addedCharacter = characters[r]
        }
        characterMas.push(addedCharacter)
    }
    let returnObg = {
        word: word,
        words: characterMas
    }
    return returnObg
}

export function makeSentence(wordList){
    const randomPageNum = Math.floor(Math.random() * (wordList.page.length - 1)) + 1
    let randomPage = wordList.page[randomPageNum]
    let sentence = ''
    switch(wordList.level){
        case '00001':
            {
                sentence = randomPage.assets[0].text.slice(3,-4);
                break;
            }
        case '00002':
            {
                let sentenceMas = []
                let word = randomPage.assets[0].text.slice(3,-4);
                word = word.replaceAll('!', '.')
                word = word.replaceAll('?', '.')
                word = word.replaceAll('!"', '')
                word = word.replaceAll('?"', '')
                word = word.replaceAll('."', '')
                word = word.replaceAll('"', '')
                sentenceMas.push(word.slice(0, word.indexOf('.')+1))
                sentenceMas.push(word.slice(word.indexOf('.')+2, word.length))
                const randomSentence = Math.floor(Math.random() * sentenceMas.length)
                sentence = sentenceMas[randomSentence]
                break;
            }
        case '00003':
            {
                let sentenceMas = []
                let word = randomPage.assets[0].text.slice(3,-4);
                word = word.replaceAll('!', '.')
                word = word.replaceAll('?', '.')
                word = word.replaceAll('!"', '')
                word = word.replaceAll('?"', '')
                word = word.replaceAll('."', '')
                word = word.replaceAll('"', '')
                sentenceMas.push(word.slice(0, word.indexOf('.')+1))
                word = word.slice(word.indexOf('.')+2, word.length)
                sentenceMas.push(word.slice(0, word.indexOf('.')+1))
                sentenceMas.push(word.slice(word.indexOf('.')+2, word.length))
                const randomSentence = Math.floor(Math.random() * sentenceMas.length)
                sentence = sentenceMas[randomSentence]
                if(sentence.length === 0){
                    const randomSentence = Math.floor(Math.random() * sentenceMas.length)
                    sentence = sentenceMas[randomSentence]
                    if(sentence.length === 0){
                        const randomSentence = Math.floor(Math.random() * sentenceMas.length)
                        sentence = sentenceMas[randomSentence]
                        if(sentence.length === 0){
                            const randomSentence = Math.floor(Math.random() * sentenceMas.length)
                            sentence = sentenceMas[randomSentence]
                        }
                    }
                }
                break;
            }
        default: {
        }
    }
    let words = sentence.split(" ");
    if(words[words.length-1]==='')
    words.pop()
    return words
}


export function findCreatesWith(wordList){
    let correct = wordList.correct
    let incorrect = wordList.incorrect
    let obg = {
        correct: correct.sort(function(){ return Math.random() - 0.5}).slice(0, 4),
        incorrect: incorrect.sort(function(){ return Math.random() - 0.5}).slice(0, 1)
    }
    return obg
}


export function multiCorrect(wordList, correctNum, falseNum){
    let newArray = []
    for(let i = 0; i < wordList.length; i++)
    {
        newArray.push(wordList[i].toLowerCase())
    }
    wordList  = remove_duplicates(newArray)
    wordList = wordList.sort(function(){ return Math.random() - 0.5})
    let correct = wordList[0]
    wordList = wordList.filter(item => item !== correct)
    let chekedWord = []
    let correctWord = []
    for(let i = 0; i < correctNum; i++)
    {
        correctWord.push(correct)
        chekedWord.push(correct)
    }
    for(let i = 1; i < falseNum + 1; i++)
    {
        chekedWord.push(wordList[i])
    }
    return {words: chekedWord, correct:correctWord }
}


