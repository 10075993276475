import React from 'react'
import classes from './Present.module.scss'
import coin from '../../../../source/Images/menus/coin-music@2x.png'
import { setLevel } from '../../../Level/route'
class Present extends React.PureComponent{
    constructor(props){
        super(props);
        this.state={
            bg: '',
            title: '',
            img: '',
            showBook: true,
            json: '',
        }
        this.renderBook = this.renderBook.bind(this)
    }

    async UNSAFE_componentWillMount(){
        let obg = {
            bg: '',
            title: '',
            img: '',
            json: '',
        }
        try 
        {
            const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/Images/patterns/pattern-${Math.floor(Math.random()*24) + 1}.png`);
            obg.bg = src
        } 
        catch (err) {
        }
        try {
            let  book = this.props.book
            let bookNum = parseInt(book.slice(book.length-2,book.length))
            bookNum += 1
            if(bookNum < 10)
            {
                book = book.slice(0, -2) + '0' + bookNum
            }
            else
            {
                book = book.slice(0, -2) + bookNum
            }
            let level = this.props.unit.split('-')
            const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/ReadingTrain-Core/ReadingTrain-Core/Courses/${level[0]}/${this.props.unit}/${book}/0.jpg`);
            obg.img = src
            
        } catch (err) {
        }

      try {
        let  book = this.props.book
        let bookNum = parseInt(book.slice(book.length-2,book.length))
        bookNum += 1
        if(bookNum < 10)
        {
            book = book.slice(0, -2) + '0' + bookNum
        }
        else
        {
            book = book.slice(0, -2) + bookNum
        }
        let level = this.props.unit.split('-')
        const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/ReadingTrain-Core/ReadingTrain-Core/Courses/${level[0]}/${this.props.unit}/${book}/module.js`);
        let plist = require('plist')
        let json = await plist.parse(src)
        obg.json = json
        obg.title = json.name
        
      } catch (err) {
         this.setState({
             showBook: false
         })
      }

      this.setState({
        bg: obg.bg,
        title: obg.title,
        img: obg.img,
        json: obg.json,
      })
    }

    renderBook(){
        let  book = this.props.book
        let bookNum = parseInt(book.slice(book.length-2,book.length))
        bookNum += 1
        if(bookNum < 10)
        {
            book = book.slice(0, -2) + '0' + bookNum
        }
        else
        {
            book = book.slice(0, -2) + bookNum
        }
        this.props.history.push('/preview', {level: this.props.level, unit: this.props.unit, book: book, json: this.state.json, picture: this.state.img, name: this.state.title, bg: this.state.bg})
        return
    }

    render(){
        return(
            <div className={classes.present}>
                <div className={classes.coin}>
                    <img onClick={ () => { setLevel('00004'); this.props.history.push('/level', {type:'song'})}} src={coin} alt='' />
                </div>
                {this.state.showBook ?
                <div className={classes.book} onClick={this.renderBook}>
                    <div className={classes.leftSide}>
                        {/* <img src={binding} alt="" /> */}
                    </div>
                    <div style={{backgroundImage:"url("+this.state.bg+")"}} className={classes.mainSide}>
                        <div className={classes.main}>
                            <div className={classes.text}>
                                {this.state.title}
                            </div>
                            <div className={classes.img}>
                                <img src={this.state.img} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div />
            }
                
            </div>
        )
    }
}

export default Present