import React from 'react'
import classes from './ExclusiveWord.module.scss'
// import z from '../../../../../../source/ReadingTrain-Core/ReadingTrain-Core/SchemaImages'
class ExclusiveWord extends React.PureComponent{
    constructor(props){
        super(props);
        this.state = {
            song: new Audio(),
            src: '/asd',
            srcimg: '/dsa',
            text: '',
            timer: '',
            allowToPlay: true,
            playHelp: true
        }
        this.playAudio = this.playAudio.bind(this)
    }

    async UNSAFE_componentWillMount(){
        this.state.timer = setInterval( async () =>  {
            this.timmer()
         },
         18000
         )
        let word = this.props.text
        word = word.toLowerCase()
        word = word.replaceAll('.', '')
        try {
            const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../../../source/ReadingTrain-Core/ReadingTrain-Core/SchemaImages/${word}.jpg`);
        await this.setState({ srcimg: src });
      } catch (err) {
      }
      try{
          if(this.props.words[this.props.text])
          {
             await this.setState({
                text: this.props.words[this.props.text].definition
             }) 
          }
          else
          {
            await this.setState({
                text: this.props.words[this.props.text+'s'].definition
             }) 
          }
    }
    catch(err){
        this.props.onError()
    }
    }

    componentWillUnmount()
    {
        clearInterval(this.state.timer)
        this.state.song.pause()
    }

    async timmer(){
        if(this.state.playHelp){
            clearInterval(this.state.timer)
            this.state.count = this.state.count + 1
            let z = ''
            try {
                        const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../../../source/TutorialAudio/tutorial-book-4.mp3`);
                        this.state.playHelp = false
                        z = src
                }
            catch (err) 
            {
            }
                this.state.song.src = z
                this.state.song.play()
        }
       
}

    async playAudio(ev){
        ev.preventDefault()
        ev.stopPropagation()
        clearInterval(this.state.timer)
        let word = this.props.text
        let z = ''
        word = word.toLowerCase()
        // word  = word.slice(1, -1);
        word = word.replaceAll('.', '')
        try {
            const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../../../source/ReadingTrain-Core/ReadingTrain-Core/SchemaDefinitionAudio/${word}.mp3`);
            z = src
          } catch (err) {
            this.setState({ err: err.toString() });
          }

         
          if(this.state.allowToPlay){
            this.state.song.src = z 
            this.state.allowToPlay = false 
            this.state.song.play()
            this.state.song.onended = () => {
                this.state.allowToPlay = true
            }
        }
       
    }

    async componentWillReceiveProps()
    {
       
    }
    render(){
        return(
                <div style={this.props.padding ? {padding: this.props.padding} : {}} onClick={ (ev) => {ev.stopPropagation(); ev.preventDefault(); this.state.song.pause(); this.props.hide() }} className={classes.rtExclusiveWord}>
                    <div onClick={(ev) => { ev.stopPropagation(); ev.preventDefault(); }} className={classes.mainSide}>
                        <div className={classes.title}>
                            <img alt='' src={this.state.srcimg}/>
                            <div className={classes.Titletext}> {this.props.text} </div>
                        </div>

                        <div onClick={this.playAudio} className={classes.text}>
                                {this.state.text}
                        </div>
                    </div>
                </div>
        )
    }
}

export default ExclusiveWord