import React from 'react'
import classes from './UserType.module.scss'
import child from '../../img/dog.png'
import educators from '../../img/fox.jpg'
import { Button } from '@material-ui/core'
class UserType extends React.PureComponent{
    constructor(props){
        super(props)
        this.state ={

        }
    }

    render(){
        return(
            <div className={classes.userType}>
                <div style={{marginTop:'-100px'}} className={classes.title}>Create Your Account</div>
                <div style={{marginTop:'100px'}} className={classes.block}>
                    <div style={{position:'relative'}} className={classes.userTypeBlock}>
                        <img alt="" src={child} />
                        <div className={classes.type}>Child</div>
                        <div className={classes.text}>Unlock the best books, music and more!</div>
                        <Button onClick={(ev) => {this.props.create('child') }} style={{position:'absolute', bottom:'0', backgroundColor:'#FFDEE0'}}> Create account </Button>
                    </div>
                    <div className={classes.userTypeBlock}>
                        <img alt="" src={educators} />
                        <div className={classes.type}>Adults</div>
                        <div className={classes.text}>Register children to monitor progress.</div>
                        <Button onClick={(ev) => {this.props.create('educators') }} style={{backgroundColor:'#FFDEE0'}}> Create account </Button>
                    </div>    
                </div>
                <Button onClick={(ev) => {this.props.history.push('login') }} style={{width:'605px', marginRight:'24px', marginTop:'20px', backgroundColor:'#FFDEE0'}}> Login </Button>
               
            </div>
        )
    }
}

export default UserType