import React from 'react'
import classes from  './RenderBook.module.scss'
import count from '../../../../Books/countWidthHeight'
import '../../LevelRender/spiner.css'
import FittedImage from 'react-fitted-image'
import { LazyLoadComponent } from 'react-lazy-load-image-component';
class RenderBook extends React.PureComponent {
    constructor(props){
        super(props);
        this.state={
            src:'',
            json: '',
        }
    }




    async getXML(){
        let plist = require('plist');
        try {
            const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../../source/ReadingTrain-Core/ReadingTrain-Core/Courses/${this.props.level}/${this.props.unit}/${this.props.book}/module.js`);
            let json = await plist.parse(src)
            await this.setState({
                json: json
            })
          } catch (err) {
            this.setState({ err: err.toString() });
          }
    }

    async getImg(){
        try {
            const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../../source/ReadingTrain-Core/ReadingTrain-Core/Courses/${this.props.level}/${this.props.unit}/${this.props.book}/0.jpg`);
        
            await this.setState({ src: src });
        } catch (err) {
        this.setState({ err: err.toString() });
      }
    }
    async UNSAFE_componentWillMount(){
        let widthModify = ''
        let heightModify = ''
        switch(this.props.type){
            case 'a':
                {
                    widthModify = 0.2368
                    heightModify = 0.362
                    break;
                }
            case 'h':
                {
                    widthModify = 0.1801
                    heightModify = 0.298
                    break;
                }
            case 'q':
                {
                    widthModify = 0.1234
                    heightModify = 0.17
                    break;
                }
            default:
                {
                }
        }
        let img = new Image()
        img.src  = this.props.img
        img.onload = () => {
            let size = count(img.naturalWidth, img.naturalHeight, window.innerWidth*parseFloat(widthModify) , window.innerHeight*parseFloat(heightModify))
            this.setState(
                {
                    height: size.countH,
                    width: size.countW
                }
            )
        }
        await this.getImg()
        await this.getXML()
        this.forceUpdate()
    }

    

    render(){
        // let bg=require(`../../../../../source/patterns/pattern-${Math.ceil(Math.random()*24)}.png`)
        return(
            <div onClick={() =>{this.props.onClick(this.props.id, this.props.level, this.props.unit, this.props.book, this.props.json, this.props.img, this.props.json.name, this.props.bg  )}} 
            key={this.props.id} id={this.props.id} 
            tabIndex={this.props.id} className={classes.rtRenderBook} 
            
            >
                <div className={classes.leftSide}> </div>
                <div className={classes.mainSide} id='mainSide' style ={ { backgroundImage: "url("+this.props.bg+")" } }>
                    <div className={classes.mainInfo}>
                        <div style={this.props.type === 'h' ? {fontSize:'10px'} : this.props.type === 'q' ? {fontSize:'6px'} : {fontSize:'17px'}} className={classes.title}> {this.props.json.name} </div>
                        <LazyLoadComponent>
                        <div style={this.props.type === 'h' ? {height:'auto', maxHeight:'100px'} : this.props.type === 'q' ? {height:'auto', maxHeight:'calc( 100% - 70px)'} : {height:'auto', maxHeight:'calc( 100% - 100px)'}}>
                        <FittedImage
                            style={this.props.type === 'h' ? {maxHeight:'96px'} : this.props.type === 'q' ? {maxHeight:'58px'} :  {maxHeight:'159px'}}
                            fit="auto"
                            loader={<div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>}
                            onLoad={(...args) => console.log(...args)}
                            onError={(...args) => console.log(...args)}
                            src={this.props.img}
                            />
                        {/* <img style={{maxWidth:'80%', maxHeight:'50%'}}  src={this.props.img} /> */}
                            </div>
                            </LazyLoadComponent>
                    </div>
                </div>
                
            </div>
        )
    }
}

export default RenderBook