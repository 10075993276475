import React from 'react'
import classes from './BooksPage.module.scss'
// import unit from '../../../../source/ReadingTrain-Core/ReadingTrain-Core/Courses/00001/00001-01/00001-01-01'
import cn from 'classnames'
import Exclusive from './components/ExclusiveWord/ExclusiveWord'
import Quid from './components/Quit/Quid'
import nextImg from '../../../../source/Images/menus/book-page-next@2x.png'
import './style.css'
import FlippingPages from 'flipping-pages'
/* IMPORTANT */
import 'flipping-pages/FlippingPages.css'
import FlipPage from 'react-flip-page'
import MicRecorder from 'mic-recorder-to-mp3'
import {store, db, auth} from '../../../../Firebase/fireconfig'
// import HTMLFlipBook from "react-pageflip";
let target

class BooksPage extends React.PureComponent {
    
    constructor(props){
        super(props);
        
        this.state = {
            currentPage: 0,
            song: new Audio(),
            src: '/asd',
            pages: '',
            text: [],
            exclusiveShow: false,
            word: '',
            shemaData: '',
            pageData: {
                img: [],
                text: [],
                mp3: [],
            },
            numberOfPage: [1,2,3,4,5,6,7],
            loaded: false,
            timer: '',
            timerStart: '',
            count: 1,
            recorder: new MicRecorder({bitRate: 128}),
            times: [],
            startTime: null,
            playHelpAudio: true            
        }
        this.playAudio = this.playAudio.bind(this);
        this.playTitle = this.playTitle.bind(this);
        this.Next = this.Next.bind(this)
        this.Prev = this.Prev.bind(this)
        this.hideExclusive = this.hideExclusive.bind(this)
        this.preloadBook = this.preloadBook.bind(this)
        this.onErrorExclusiv = this.onErrorExclusiv.bind(this)
    }


    async timmer(){
        if(this.state.playHelpAudio){
            this.state.count = this.state.count + 1
            let z = ''
            try {
                        if(this.state.count % 2 === 0)
                        {
                            const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/TutorialAudio/tutorial-book-3.mp3`);
                            z = src
                        }
                        else
                        {
                            const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/TutorialAudio/tutorial-book-2.mp3`);
                            this.state.playHelpAudio = false
                            z = src
                        }
                    }
            catch (err) 
            {
            }
            
                this.state.song.src = z
                this.state.song.play()
        }
}

    componentWillUnmount()
    {
        clearInterval(this.state.timer)
        this.state.song.pause()
        this.state.recorder.stop()
    }

    async onErrorExclusiv(){
        await this.setState({exclusiveShow: false})
    }

    playback(){
        this.state.song.src = this.props.location.state.recordedData.url 
        this.state.song.play()
        let value = Object.values(this.props.location.state.recordedData.times)
        value.map( (data, i) => {
            setTimeout(() => {  this.Next() }, data*1000);
        } )
    }

    async preloadBook(){
            await this.setState({
                pageData: this.props.location.state.info
            })
            if(!this.props.location.state.playback)
            try{
                switch(this.props.location.state.state.level){
                    case '00001':
                        {
                            this.state.song.src = this.state.pageData.mp3[this.state.currentPage][0]
                            this.state.song.play();
                            break;
                        }
                    case '00002':
                        {
                            if(this.state.currentPage === 0)
                            {
                                try{
                                    this.state.song.src = this.state.pageData.mp3[this.state.currentPage][0]
                                    this.state.song.play(); 
                                }
                                catch(err){
                                } 
                            }
                            else
                            {
                                this.state.song.src = this.state.pageData.mp3[this.state.currentPage][0]
                                this.state.song.play();
                                this.state.song.onended = (src = this.state.pageData.mp3[this.state.currentPage][1], audio = this.state.song) => {
                                    audio.src = src
                                    audio.play()
                                }
                            }
                            break
                        }
                    case '00003':
                        {
                            if(this.state.currentPage === 0)
                            {
                                try{
                                    this.state.song.src = this.state.pageData.mp3[this.state.currentPage][0]
                                    this.state.song.play(); 
                                }
                                catch(err){
                                } 
                            }
                            break
                        }
                    default: {
                    }
                }
                
            } catch(err){
            }
            this.setState({
                loaded: true
            })
    }

    loadImage = async (imgFileName) => {
        try {
            const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/ReadingTrain-Core/ReadingTrain-Core/Courses/${this.props.location.state.state.level}/${this.props.location.state.state.unit}/${this.props.location.state.state.book}/${imgFileName}`);
            return src
        } catch (err) {
        this.setState({ err: err.toString() });
        }
    }; 

    hideExclusive(){
        clearInterval(this.state.timer)
        this.state.timer = setInterval( async () =>  {
            this.timmer()
         },
         12000
         )
        this.setState(
            {
                exclusiveShow: false
            }
        )
    }
    
    async playTitle(ev){
        ev.preventDefault()
        let word = ev.target.textContent
        
        let z = ''
        word = word.toLowerCase()
        word  = word.slice(1, -1);
        word = word.replaceAll('.', '')
        word = word.replaceAll('?', '')
        word = word.replaceAll('!', '')
        while(word.slice(word.length-1, word.length) === ' ')
        {
            word  = word.slice(0, word.length-1);
        }
        while(word.slice(0, 1) === ' ')
        {
            word  = word.slice(1, word.length);
        }
        try {
            const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/ReadingTrain-Core/ReadingTrain-Core/Courses/${this.props.location.state.state.level}/${this.props.location.state.state.unit}/${this.props.location.state.state.book}/${word}.mp3`);
            z = src
          } catch (err) {
            this.setState({ err: err.toString() });
          }
        this.state.song.src = z  
        this.state.song.play();
    }



    async playText(source){
    }



    async playAudio(ev){
        ev.preventDefault()
        ev.target.classList.add('glow')
        target = ev.target
        clearInterval(this.state.timer)
        if(!this.props.location.state.playback){
            this.state.timer = setInterval( async () =>  {
                this.timmer()
             },
             12000
             )
        }
        let currentSelectTextIndex =  parseInt(ev.target.getAttribute("index"))
        let stringLength = this.state.pageData.text.length
        let wordAddLeftword = `` 
        let wordAddRightword = ``
        if(currentSelectTextIndex > 0)
        {
            wordAddLeftword = ev.target.textContent
            wordAddLeftword = wordAddLeftword.toLowerCase()
            wordAddLeftword = wordAddLeftword.slice(1,-1)
            wordAddLeftword = wordAddLeftword.replaceAll('.', '')
            wordAddLeftword = wordAddLeftword.replaceAll(',', '')
            wordAddLeftword = wordAddLeftword.replaceAll('?', '')
            wordAddLeftword = wordAddLeftword.replaceAll('!', '')
            wordAddLeftword = this.state.pageData.text[this.state.currentPage-1][currentSelectTextIndex - 1] + ' ' + wordAddLeftword
        }
        if(currentSelectTextIndex < stringLength - 1)
        {
            wordAddRightword = ev.target.textContent
            wordAddRightword = wordAddRightword.toLowerCase()
            wordAddRightword = wordAddRightword.slice(1,-1)
            wordAddRightword = wordAddRightword.replaceAll('.', '')
            wordAddRightword = wordAddRightword.replaceAll(',', '')
            wordAddRightword = wordAddRightword.replaceAll('?', '')
            wordAddRightword = wordAddRightword.replaceAll('!', '')
            wordAddRightword = wordAddRightword + ' ' + this.state.pageData.text[this.state.currentPage-1][currentSelectTextIndex + 1]
            wordAddRightword = wordAddRightword.replaceAll('.', '')
        }  
        let word = ''
        let z = ''
        if(this.state.shemaData[wordAddLeftword])
            word = wordAddLeftword
        else
            if(this.state.shemaData[wordAddRightword])
                word = wordAddRightword
            else
            {
                 word = ev.target.textContent
        
                word = word.toLowerCase()
                word  = word.slice(1, -1);
                word = word.replaceAll('.', '')
                word = word.replaceAll(',', '')
                word = word.replaceAll('?', '')
                word = word.replaceAll('!', '')
                word = word.replaceAll(`'`, '')
                word = word.replaceAll(`"`, '')
            }
       
        if(this.props.location.state.state.json.schema.includes(word)){
        clearInterval(this.state.timer)
            this.setState({
             exclusiveShow: true,
             word: word,
            })
        }
        // this.props.location.state.state.json.schema.map( data => {
        //     if(word.includes(data)){
        //         clearInterval(this.state.timer)
        //         this.setState({
        //          exclusiveShow: true,
        //          word: data,
        //         })
        //     }
        // })

        // else{
        //     let TryWord = word.slice(0, -1)
        //     if(this.props.location.state.state.json.schema.includes(TryWord)){
        //         this.setState({
        //          exclusiveShow: true,
        //          word: TryWord,
        //         })
        //     }
        // }
        
        try {
            const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/ReadingTrain-Core/ReadingTrain-Core/WordAudio/${word}.mp3`);
            z = src
            this.state.song.src = z  
            this.state.song.play();
            this.state.song.onended = () => { 
                target.classList.remove('glow') }
          } catch (err) {
            try {
                word = word.slice(0, -1)
                if(this.props.location.state.state.json.schema.includes(word)){
                    this.setState({
                     exclusiveShow: true,
                     word: word,
                    })
                }       
                const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/ReadingTrain-Core/ReadingTrain-Core/WordAudio/${word}.mp3`);
                z = src
                this.state.song.src = z  
                this.state.song.play();
                this.state.song.onended = () => { 
                    target.classList.remove('glow') }
              } catch (err) {
              }
          }
    }

    async UNSAFE_componentWillMount(){
        if(!this.props.location.state.playback)
        if(!this.props.location.state.listen){
            this.state.timer = setInterval( async () =>  {
               this.timmer()
            },
            8000
            )
        }
        if(this.props.location.state.recording)
        {
             this.state.timerStart = Math.floor(new Date().getTime() / 1000)
             this.state.recorder.start().then(() => {
           
                }).catch((e) => {
                    console.error(e);
                });
                }
        if(this.props.location.state.playback){
            this.playback()
        }
        await this.preloadBook()
   }

   async componentDidMount(){
        let z = ''
        await this.props.data.infoAll.then(await function(result) { z = result});
        await this.setState({shemaData: z})
        if(this.props.location.state.listen)
        {
            this.playText(this.props.location.state.state.json.pages[0].assets[0].name+ ' ')
        }
   }
   


  

 async Next(){
    this.state.times.push(this.state.timerStart - Math.floor(new Date().getTime()/1000))
    clearInterval(this.state.timer)
    if(!this.props.location.state.listen){
        clearInterval(this.state.timer)
        
        if(!this.props.location.state.playback){
        this.state.timer = setInterval( async () =>  {
           this.timmer()
        },
        12000
        )}
    }


     if(this.state.exclusiveShow)
     {
         this.setState({
             exclusiveShow: false
         })
     }
     else
     {
         await this.setState({
          currentPage: this.state.currentPage + 1
      })
      let lenghtCheck = this.props.location.state.state.json.pages.length
      if(this.props.location.state.recording){
        lenghtCheck = lenghtCheck + 1
    }
    if( this.props.location.state.recording &&  this.state.currentPage >= lenghtCheck - 1 ){
        if(!this.props.location.state.playback){
            clearInterval(this.state.timer)
        }
        this.state.recorder.stop().getMp3()    
        .then(async ([buffer, blob]) => {

            if(!this.state.readyUploaded){
                this.state.readyUploaded = true
                const file = new File(buffer, 'music.mp3', {
                  type: blob.type,
                  lastModified: Date.now()
                });
                const ref = store.ref();
                const name = (+new Date()) + '-' + file.name;
                const metadata = {
                contentType: file.type
                };
                if(!auth.currentUser.isAnonymous){
                    const task = ref.child(name).put(file, metadata);
                    task
                    .then(snapshot => snapshot.ref.getDownloadURL())
                    .then((url) => {
                        
                        let user_data = {
                            url: url,
                            times: {
        
                            },
                            user: auth.currentUser.email,
                            book: this.props.location.state.state.book
                         }
                        this.state.times.map( (data, i) => {
                            user_data.times[i.toString()] = Math.abs(data)
                        } )
                        let doc = new Date().getTime()
                        db.collection('user_records').doc(doc.toString()).set(user_data)
                    })
                    .catch(console.error);
                }
                var audioFile = new Audio(file)
                this.setState({
                    blobUrl:  URL.createObjectURL(blob)
                })
            }

            
          }).catch((e) => {
            console.error(e);
          });
    }
      if(this.state.currentPage >= lenghtCheck)
      { 
            this.props.history.push('/newwords', {  pages: this.props.location.state.state.json, shemaData: this.state.shemaData ,
            level: this.props.location.state.state.level, unit: this.props.location.state.state.unit, book: this.props.location.state.state.book, bg: this.props.location.state.bg})
      }
      else{
          let z = ''
            try {
                    const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/TutorialAudio/page-turn.mp3`);
                    z = src
                }
            catch (err) 
            {
                
            }
            if(!this.props.location.state.playback){
                this.state.song.src = z
                this.state.song.play()
            }
          if(this.props.location.state.listen){
                switch(this.props.location.state.state.level){
                    case '00001':
                        {
                            this.state.song.src = this.props.location.state.info.mp3[this.state.currentPage][0]
                            this.state.song.play();
                            break;
                        }
                    case '00002':
                        {
                            if(this.state.currentPage === 0)
                            {
                                try{
                                    this.state.song.src = this.props.location.state.info.mp3[this.state.currentPage][0]
                                    this.state.song.play(); 
                                }
                                catch(err){
                                } 
                            }
                            else
                            {
                                
                                this.state.song.src = this.props.location.state.info.mp3[this.state.currentPage][0]
                                this.state.song.play();
                                this.state.song.onended = () => {
                                    this.state.song.src = this.props.location.state.info.mp3[this.state.currentPage][1]
                                    this.state.song.play()
                                    this.state.song.onended = () =>{  }
                                }
                            }
                            break;
                        }
                    case '00003':
                        {
                            if(this.state.currentPage === 0)
                            {
                                try{
                                    this.state.song.src = this.props.location.state.info.mp3[this.state.currentPage][0]
                                    this.state.song.play(); 
                                }
                                catch(err){
                                } 
                            }
                            else
                            {
                                
                                this.state.song.src = this.props.location.state.info.mp3[this.state.currentPage][0]
                                this.state.song.play();
                                this.state.song.onended = () => {
                                    this.state.song.src = this.props.location.state.info.mp3[this.state.currentPage][1]
                                    this.state.song.play()
                                    this.state.song.onended = () =>{ 
                                        this.state.song.src = this.props.location.state.info.mp3[this.state.currentPage][2]
                                        this.state.song.play()
                                        this.state.song.onended = () =>{  }
                                     }
                                }
                            }
                            break
                        }
                    default: {
                    }

                }
          }
      }
     }
     
      }

  async Prev(){
    if(this.state.exclusiveShow)
    {
        this.setState({
            exclusiveShow: false
        })
    }
    else{
        

        if(this.state.currentPage - 1 < 0)
        {
            this.state.recorder.stop()
            this.props.history.goBack();
          
        }
        else{
        await this.setState({
            currentPage: this.state.currentPage - 1
            })
            let z = ''
            try {
                    const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/TutorialAudio/page-turn.mp3`);
                    z = src
                }
            catch (err) 
            {
                
            }
            this.state.song.src = z
            this.state.song.play()
            if(this.props.location.state.listen){
                switch(this.props.location.state.state.level){
                    case '00001':
                        {
                            this.state.song.src = this.state.pageData.mp3[this.state.currentPage][0]
                            this.state.song.play();
                            break;
                        }
                    case '00002':
                        {
                            if(this.state.currentPage === 0)
                            {
                                try{
                                    this.state.song.src = this.state.pageData.mp3[this.state.currentPage][0]
                                    this.state.song.play(); 
                                }
                                catch(err){
                                } 
                            }
                            else
                            {
                                
                                this.state.song.src = this.state.pageData.mp3[this.state.currentPage][0]
                                this.state.song.play();
                                this.state.song.onended = () => {
                                    this.state.song.src = this.state.pageData.mp3[this.state.currentPage][1]
                                    this.state.song.play()
                                    this.state.song.onended = () =>{  }
                                }
                            }
                            break
                        }
                    case '00003':
                        {
                            if(this.state.currentPage === 0)
                            {
                                try{
                                    this.state.song.src = this.state.pageData.mp3[this.state.currentPage][0]
                                    this.state.song.play(); 
                                }
                                catch(err){
                                } 
                            }
                            else
                            {
                                
                                this.state.song.src = this.state.pageData.mp3[this.state.currentPage][0]
                                this.state.song.play();
                                this.state.song.onended = () => {
                                    this.state.song.src = this.state.pageData.mp3[this.state.currentPage][1]
                                    this.state.song.play()
                                    this.state.song.onended = () =>{ 
                                        this.state.song.src = this.state.pageData.mp3[this.state.currentPage][2]
                                        this.state.song.play()
                                        this.state.song.onended = () =>{  }
                                        }
                                }
                            }
                            break
                        }
                    default:{
                    }
                }
            }
        
        
        }
    }
      
        

}
handleSelectedChange(selected) {
}



    render(){
        console.log('stateBookPage  ', this.state)
        return(
            <div className={classes.bg}>
            <div className={classes.rtBooksPage}>
                {this.state.loaded ? 
                <div style={{display:'flex', justifyContent:"center", width:'95%'}}> 
                     <Quid mode = {this.props.location.state.listen} history = {this.props.history}/>
            { this.state.exclusiveShow ? <Exclusive play = {this.state.exclusiveShow} onError = {this.onErrorExclusiv} words ={this.state.shemaData} text = {this.state.word} hide ={this.hideExclusive} /> : <div />}
            <div className={classes.prev} onClick={this.Prev}  > <img alt='Previous page' src={nextImg} /> </div>

            {
                // this.state.numberOfPage.map((data, i) => {
                //     return <img key = {i}  alt='' style={{display:'none'}} src={this.state.pageData.img[i]} />
                // })
                
            }
             <FlippingPages
             animationDuration={800}
             thresholdSpeed={1}
                    className="App-pages"
                    direction="horizontal"
                    selected={this.state.currentPage}
                    onSelectedChange={this.handleSelectedChange}
                    /* touch-action attribute is required by pointer events
                    polyfill */
                    touch-action="none"
                >
                    <div className="App-page">
            <div style={0 === this.state.currentPage ? {display: "block"} : {display: 'none'}} id='main' className={classes.content}>
                <div className={classes.title} onClick={this.playTitle}> {this.props.location.state.state.json.pages[0].assets[0].name} </div>
                <div className={classes.author} onClick={this.playTitle}> {this.props.location.state.state.json.pages[0].assets[0].author} </div>
                    <img  style={{maxHeight: '45opx'}} alt='' src={this.state.pageData.img[0]} />
            </div> 
            
            </div>
 {
          this.state.numberOfPage.map(data => {   
          return<div className="App-page"><div  id = 'text'  className={cn(classes.content, classes.text21)}>
               
                   
                         <div className={classes.page} style={data === this.state.currentPage ? {display: "block"} : {display: 'none'}}>
                            <img  alt='' src={this.state.pageData.img[data]} />
                            <div className={classes.text}>
                            {this.state.pageData.text[data-1].map((data,i) => {
                                if(data === '')
                                {          
                                         return <p key={i} className={classes.break} />   
                                }
                                else
                                return <div style={{marginRight: '15px'}} key={i} onClick={this.playAudio} index={i}> {data} </div>
                            })}
                            </div>
                        </div>
                   
                
                </div></div>}) }

                { this.props.location.state.recording ? <div className="App-page">
                    <a style={{display:'block'}} id='download'download ></a>
                <div id='em' className={classes.askUser}>
                <audio id='ema' style={{margin:'auto', marginTop:'450px', display:'block', width:'500px'}} ref="audio_tag" src={this.state.blobUrl} controls />
                    
                </div>
                </div> : <> </> }

                </FlippingPages>

            {/* {this.state.currentPage === 0 ? 
            <div id='main' className={classes.content}>
                <div className={classes.title} onClick={this.playTitle}> {this.props.location.state.state.json.pages[0].assets[0].name} </div>
                <div className={classes.author} onClick={this.playTitle}> {this.props.location.state.state.json.pages[0].assets[0].author} </div>
                    <img  style={{maxHeight: '45opx'}} alt='' src={this.state.pageData.img[this.state.currentPage]} />
            </div> 
            :
            <div  id = 'text'  className={cn(classes.content, classes.text21)}>
                {
                    this.state.numberOfPage.map(data => {
                        return <div className={classes.page} style={data === this.state.currentPage ? {display: "block"} : {display: 'none'}}>
                            <img  alt='' src={this.state.pageData.img[this.state.currentPage]} />
                            <div className={classes.text}>
                            {this.state.pageData.text[this.state.currentPage-1].map((data,i) => {
                                if(data === '')
                                {          
                                         return <p key={i} className={classes.break} />   
                                }
                                else
                                return <div style={{marginRight: '15px'}} key={i} onClick={this.playAudio} index={i}> {data} </div>
                            })}
                            </div>
                        </div>
                    })
                }
                </div>
            } */}
            <div className={classes.next}  onClick={this.Next} > <img alt='Next page' src={nextImg} /> </div>
                </div> : <div> Loading.... </div>}
           
            </div>
            </div>
        )
    }
}

export default BooksPage