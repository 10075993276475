import React from 'react'
import classes from './Preview.module.scss'
import ModeButton from '../../Books/components/BooksPreview/components/ModeButton/ModeButton'
import { BoxLoading } from 'react-loadingg';
import coins from '../../../source/Images/menus/coin-music@2x.png'
import FittedImage from 'react-fitted-image'
import icon from '../../Level/components/Unit/img/icon.png'
import BackButton from '../../BackArrow/BackArrow'
import { db,auth } from '../../../Firebase/fireconfig'
class Preview extends React.PureComponent{
    constructor(props){
        super(props);
        this.state={
            info: false, //must be false
            song: new Audio(),
            level: 0,
            module: 0,
            book: 0,
            loadPage: false,
            width: '',
            height: '',
            src: '',
            isBlocked: false,
            numberOfCoins: null,
            numberOfButton: 2,
            buttonClicled: false
        }
        this.ModeButtonClick = this.ModeButtonClick.bind(this)
    }

    clickedFalse(){
        this.setState({
            buttonClicled:false
        })
    }

    componentWillUnmount()
    {

        this.state.song.pause()
        
    }

    componentWillMount(){
        let tag = 'child_user'
		db.collection(tag).where('email', '==', auth.currentUser.email).get().then( response => {
			response.forEach( data => {
                let user = data.data()
                
				this.setState({ numberOfCoins: user.coins })
			} )
        } )
        db.collection('user_records').where('user', '==', auth.currentUser.email).where('book', '==', this.props.newState.unit).get().then( response => {
			response.forEach( data => {
				let data1 = data.data()
                this.setState({
                    recordedData: data1,
                    numberOfButton: 3
                })
                this.forceUpdate()                
			} )
		} )
    }
    ModeButtonClick(ev)
    {
        if(!this.state.buttonClicled){
            switch(ev){
                case "Record":
                    {
                        this.props.onClick('listenR', this.state.recordedData)
                        break;
                    }
                case "Listen":
                    {
                        this.props.onClick('listen')
                        break;
                    }
                case 'Read':
                    {
                        if(navigator.getUserMedia)
                        {
                           navigator.getUserMedia({ audio: true },
                                () => {
                                this.setState({ isBlocked: false });
                                this.props.onClick('record')
                                },
                                () => {
                                this.setState({ isBlocked: true }) 
                                alert('Unable to access microphone, please check system settings.')   
                                },
                            ); 
                        }
                        else
                        {
                            navigator.mediaDevices.getUserMedia({ audio: true },
                                () => {
                                this.setState({ isBlocked: false });
                                },
                                () => {
                                this.setState({ isBlocked: true })
                                alert('Unable to access microphone, please check system settings.')   
                            },
                            ); 
                        }
                        
                        break;
                    }
                default:{
                }
            }
            this.setState({
                buttonClicled: true
            })
        }
    }


    async componentDidMount()
    {
        try {
            const { default: src } = await import(/* webpackMode: "lazy" */ `../../../source/ReadingTrain-Core/ReadingTrain-Core/Songbooks/${this.props.newState.unit}/0.jpg`);
            // await this.setState({ src: src });
            this.setState({
                src: src
            })
        } catch (err) {
        }
    }

    render(){
        return(
            <div className={classes.bg}>
                <div>
                </div>
            <div className={classes.rtBooksPreview} style ={ { backgroundImage: "url("+this.props.newState.bg+")" } }>
                {/* <div style={this.state.loadPage ? {backgroundColor:'rgba(50, 50, 50, 0.8)', position:'fixed', width:'100vw', height:'100vh', zIndex:"100"} : {opacity:'0'}}>

<BoxLoading />

</div> */}
                <BackButton type='song' history={this.props.history} />
                    {/* <img id='arrow' onClick={() => {this.props.history.goBack()}} style={{width:'32px', height:'32px', margin:'15px', position:'absolute', top:"20px", left:'20px', zIndex:'10'}} alt='' src={icon} /> */}
                
                <div className={classes.info} > 
                    <img alt='Coin img' src={coins} />
                    <div className={classes.coinInfo}>
                        {this.state.numberOfCoins}
                    </div>
                 </div>
                <div className={classes.bookIntro}>
                    <div className={classes.title}> {this.props.newState.title} </div>    
                    <FittedImage
                        fit="contain"
                        loader={<BoxLoading />}
                        onLoad={(...args) => console.log(...args)}
                        onError={(...args) => console.log(...args)}
                        src={this.state.src}
                    />
                </div>
                <div className={classes.bookMode}> 
                {[...Array(this.state.numberOfButton)].map((x, i) =>
                    <ModeButton price={this.props.json.price} type='song' onClick={this.ModeButtonClick} index={i} key={i} />
                )}
                </div>
                
            </div>
            </div>
        )
    }
}

export default Preview;