import { connect } from 'react-redux';
import { compose } from 'recompose';

import AfterReadingTests from './Tests';

const mapStateToProps = state => ({
    data: state,
});

export default compose(
    connect(mapStateToProps),
)(AfterReadingTests);
