import React from 'react'
// import classes from ''
import Preview from './Preview/Preview'
import Alert from './Notification/Notification'
import { BoxLoading } from 'react-loadingg';
import {db,auth} from '../../Firebase/fireconfig'
class LoginPage extends React.PureComponent {
    constructor(props){
        super(props)
        this.state = {
            askUserToContinue: false, //must be false
            modeType: '',
            text: ['Spend coins to listen this song?', 'Record yourself singing?'],
            json: '',
            img: '',
            loading: false,
            numberOfCoins: 0,
            OKCliked: false
        }   

        this.userCheck = this.userCheck.bind(this)
        this.modeCheck = this.modeCheck.bind(this)
        this.preloadPages = this.preloadPages.bind(this)
        this.refs = React.createRef()
    }


    async preloadPages()
    {
        
    }
    async userCheck(ev){
        let tag = 'child_user'

        if(!this.state.OKCliked){
            this.setState({
                OKCliked: true
            })
            await db.collection(tag).where('email', '==', auth.currentUser.email).get().then( response => {
                if (response.empty) {
                    this.setState({ numberOfCoins: 5 })
                }else{
                   response.forEach(data => {
                    let user = data.data()
                    this.setState({ numberOfCoins: user.coins })
                } ) 
                }
                
            } )
            
           
                switch(ev){
                   
                    case 'yes':{
                        if(this.state.numberOfCoins === 0 && !this.state.recordedData){
                            this.setState({ askUserToContinue: false})
                            alert("You don't have enough coins")
                        }else{
                            if(!this.state.recordedData){
                                await db.collection(tag).where('email', '==', auth.currentUser.email).get().then( response => {
                                    response.forEach( data => {
                                        let user = data.data()
                                        user.coins = user.coins - 1
                                        db.collection(tag).doc(data.id).update(user)
                                    } )
                                } )
                            }
                        await this.setState({ loading: true })
                        let img = []
                        let mp3 = ''
                        for(let i = 0; i < this.state.json.pages.length; i++)
                        {
                            try {
                                const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/ReadingTrain-Core/ReadingTrain-Core/Songbooks/${this.props.location.state.unit}/${i+1}.jpg`);
                                img.push(src)
                                // let sentences = split(json.pages[0].text);
                    
                            } catch (err) {
                            }
                        }
                        if(!this.state.recordedData){
                            try {
                                const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/ReadingTrain-Core/ReadingTrain-Core/Songbooks/${this.props.location.state.unit}/song.mp3`);
                                mp3 = src
                            } catch (err) {
                            }
                        }else{
                            mp3 = this.state.recordedData.url
                        }
                        this.props.history.push('/sing/start', {json: this.state.json, img1: img, mp3: mp3, type: this.state.modeType, book: this.props.location.state.unit})
                        break;}
                    }
                    case 'no':{
                        this.refs.current.clickedFalse()
                        this.setState({ askUserToContinue: false})
                        break;
                    }
                    default:{
                    }
                }

        }
    }

   async  modeCheck(ev, data){
        this.setState({
            modeType: ev,
            askUserToContinue: ev === "listenR" ? false : true,
            recordedData: data,
            loading: true,
        })
        this.forceUpdate()
        if(ev === "listenR"){
            await this.setState({ loading: true })
            let img = []
            let mp3 = ''
            for(let i = 0; i < this.state.json.pages.length; i++)
            {
                try {
                    const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/ReadingTrain-Core/ReadingTrain-Core/Songbooks/${this.props.location.state.unit}/${i+1}.jpg`);
                    img.push(src)
                    // let sentences = split(json.pages[0].text);
        
                } catch (err) {
                }
            }
            if(!this.state.recordedData){
                try {
                    const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/ReadingTrain-Core/ReadingTrain-Core/Songbooks/${this.props.location.state.unit}/song.mp3`);
                    mp3 = src
                } catch (err) {
                }
            }else{
                mp3 = this.state.recordedData.url
            }
            this.props.history.push('/sing/start', {json: this.state.json, img1: img, mp3: mp3, type: 'listen', book: this.props.location.state.unit})
        }
    }
    
    async UNSAFE_componentWillMount(){
        this.refs = {}
        let plist = require('plist')
        try {
            const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/ReadingTrain-Core/ReadingTrain-Core/Songbooks/${this.props.location.state.unit}/songbook.js`);
            let json = plist.parse(src)
           
            this.setState({
                json: json
            })
        } catch (err) {
        }
    }


    render(){
        return(<div >
            {this.state.loading ? <BoxLoading /> : <div />}
            <Preview ref={this.refs} history={this.props.history} json={this.state.json} onClick={this.modeCheck} newState = {this.props.location.state}/>
            <div style={{display:'none'}}>
            <Alert text={this.state.modeType === 'listen' ? this.state.text[0] : this.state.text[1]} onClick={this.userCheck}/>
            </div>
            {this.state.askUserToContinue ? <Alert text={this.state.modeType === 'listen' ? this.state.text[0] : this.state.text[1]} onClick={this.userCheck}/> : <div />}
        </div>)
    }
}

export default LoginPage