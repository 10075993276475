import React from 'react'
import classes from './Traks.module.scss'
class Traks extends React.PureComponent {
    constructor(props){
        super(props);
        this.state={

        }
    }

    render(){
        return(
            <div className={classes.Traks}>
                {/* <img className={classes.trakImg} src={trak} alt ='' /> */}
            </div>
        )
    }
}

export default Traks