import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage'
const firebaseConfig = {
  apiKey: "AIzaSyAWAd7iMIF9I8gJ7aP5pO7tzrGSbgXyt3Q",
  authDomain: "reading-train-b9c37.firebaseapp.com",
  projectId: "reading-train-b9c37",
  storageBucket: "reading-train-b9c37.appspot.com",
  messagingSenderId: "72950811254",
  appId: "1:72950811254:web:7e8ed16cf9ca02ae766d57",
  measurementId: "G-K8HCCXSYYD"
};
  // Initialize Firebase
  let Firebase = firebase.initializeApp(firebaseConfig);

  export const store = Firebase.storage()
  export  const auth = Firebase.auth()
  export  const db = Firebase.firestore()
export default Firebase
