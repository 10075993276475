import React from 'react'
import classes from './Tests.module.scss'
import OneWord from './components/OneWord/Answers'
import Train from './components/Train/Train'
import Shop from './layout/Shop/Shop'
import Traks from './layout/Traks/Traks'
import Platform from './layout/Platform/Platform'
import Present from './components/Present/Present' 
import NextTextImg from '../../source/Images/station/lever-base@2x.png'
import signal_red from '../../source/Images/station/signal-red@2x.png'
import signal_green from '../../source/Images/station/signal-green@2x.png'
import check_mark from '../../source/Images/station/quiz-checkmark-light.png'
import station_sign from '../../source/Images/station/station-sign.png'
import quizMarkOn from '../../source/Images/station/quiz-checkmark-light-on@2x.png'
import quizMarkOff from '../../source/Images/station/quiz-checkmark-light-off@2x.png'
import HTML5Backend from 'react-dnd-html5-backend'
import backButton from '../Level/components/Unit/img/icon.png'
import cn from 'classnames'
import BackButton from '../BackArrow/BackArrow'
import { DndProvider } from "react-dnd";
import { auth, db } from '../../Firebase/fireconfig'
class Tests extends React.PureComponent {
    constructor(props){
        super(props)
        this.state = {
            quizTypes: [],
            ansSumm: [449,224,194,164,134,134,134],
            currentQuestion: 0, // must be 0
            numberOfAnswer: 1,
            quiz: ``,
            level: ``,
            trainWidth: 0,
            cardID: -1,
            carID: -1,
            correct: false,
            correctAns: false,
            checkMark: [false, false, false, false, false],
            makeWord: '',
            quizSetting: [],
            song: new Audio(),
            song1: new Audio(),
            finishTest: false,
            present: false,
            shake: false,
            falseTry: 0,
            showHelp: true,
            timerGoButton: '',
            count: 0,
            CheckClick: false,
            corectAnsTime: 0,
            falseAnsTime: 0,
        }

        this.workWithTraine = this.workWithTraine.bind(this)
        this.checkCorrect = this.checkCorrect.bind(this)
        this.falseTraine = this.falseTraine.bind(this)
        this.correctTrain = this.correctTrain.bind(this)
        this.trainStart = this.trainStart.bind(this)
        this.playAudio = this.playAudio.bind(this)
        this.testPresentShow = this.testPresentShow.bind(this)
        this.goAwai = this.goAwai.bind(this)
        this.refBlock = React.createRef()
    }


    componentWillUnmount()
    {
        this.state.song.pause()
        clearInterval(this.state.timerGoButton)
    }

    async playAudio(){
        let type = this.state.quizTypes[this.state.currentQuestion].slice(0,1)
        let correct = this.state.correct
        let word
        if(type === '0' && !this.state.finishTest)
        {
            try
            {
                word = correct[0]
                let z = ''
                word = word.toLowerCase()
                word = word.replaceAll('.', '')
                word = word.replaceAll(',', '')
                word = word.replaceAll('?', '')
                word = word.replaceAll('!', '')
                word = word.replaceAll(`'`, '')
                word = word.replaceAll(`"`, '')
                word = word.replaceAll(`"`, '')
                try 
                {
                    const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/QuizMusic/quiz-question-intro-0.mp3`);
                    z = src
                } 
                catch (err) {
                }
                this.state.song.src = z
                if(!this.state.finishTest)
                this.state.song.play();
                this.state.song.onended = async (some = '', audio = this.state.song, words = word) => {
                    let z = ''
                   try 
                {
                    const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/ReadingTrain-Core/ReadingTrain-Core/WordAudio/${words}.mp3`);
                    z = src
                } 
                catch (err) {
                    if(words.slice(words.length - 1, words.length) === ' ')
                    {
                        words = words.slice(0, words.length - 1)
                    }
                    if(words.slice(0,1) === ' ')
                    {
                        words = words.slice(1, words.length)
                    }
                    try 
                    {
                        const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/ReadingTrain-Core/ReadingTrain-Core/WordAudio/${words}.mp3`);
                        z = src
                    } 
                    catch (err) {
                       
                    } 
                } 
                audio.src = z
                audio.play()
                audio.onended = () => {}
                }

                
                
            }
            catch(err)
            {}
        }
        if(type === '1' && !this.state.finishTest)
        {   
            try
            {
                word = correct.join(' ')
                word = word.toLowerCase()
                word = word.replaceAll('.', '')
                word = word.replaceAll(',', '')
                word = word.replaceAll('?', '')
                word = word.replaceAll('!', '')
                word = word.replaceAll(`'`, '')
                word = word.replaceAll(`"`, '')
                word = word.replaceAll(`"`, '')
                let z1 = ''
                try {
                    const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/QuizMusic/quiz-question-intro-1.mp3`);
                    z1 = src
                } catch (err) {
                }
                this.state.song.src = z1
                if(!this.state.finishTest)
                this.state.song.play();
                this.state.song.onended = async (some='', audio = this.state.song, level = this.state.level, unit = this.props.location.state.pages.identifier.slice(0,-3), book = this.props.location.state.pages.identifier, words = word) => {
                    let z = ''
                    try {
                        const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/ReadingTrain-Core/ReadingTrain-Core/Courses/${level}/${unit}/${book}/${words}.mp3`);
                        z = src
                    } catch (err) {
                        if(word.slice(word.length - 1, word.length) === ' ')
                        {
                            word = word.slice(0, word.length - 1)
                        }
                        if(word.slice(0,1) === ' ')
                        {
                            word = word.slice(1, word.length)
                        }
                        try {
                            const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/ReadingTrain-Core/ReadingTrain-Core/Courses/${level}/${unit}/${book}/${words}.mp3`);
                            z = src
                        } catch (err) {
                            if(word.slice(word.length - 1, word.length) === ' ')
                            {
                                word = word.slice(0, word.length - 1)
                            }
                            if(word.slice(0,1) === ' ')
                            {
                                word = word.slice(1, word.length)
                            }
                            try {
                                const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/ReadingTrain-Core/ReadingTrain-Core/Courses/${level}/${unit}/${book}/${words}.mp3`);
                                z = src
                            } catch (err) {
                            }
                        }
                    }
                    audio.src = z
                    audio.play()
                    audio.onended = () => { }
                }
            }
            catch(err)
            {}
        }
        if(type === '2' && !this.state.finishTest)
        {
            try
            {
                word = correct
                let z = ''
                word = word.toLowerCase()
                word = word.replaceAll('.', '')
                word = word.replaceAll(',', '')
                word = word.replaceAll('?', '')
                word = word.replaceAll('!', '')
                word = word.replaceAll(`'`, '')
                word = word.replaceAll(`"`, '')
                word = word.replaceAll(`"`, '')
                try {
                    const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/QuizMusic/quiz-question-intro-2.mp3`);
                    z = src
                } catch (err) {
                }
                
                this.state.song.src = z  
                if(!this.state.finishTest)
                this.state.song.play();
                this.state.song.onended = async ( some='', audio = this.state.song,  words = word) =>
                {
                    let z =''
                     try {
                        const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/ReadingTrain-Core/ReadingTrain-Core/WordAudio/${words}.mp3`);
                        z = src
                    }
                    catch (err) 
                    {
                        if(words.slice(words.length - 1, words.length) === ' ')
                        {
                            words = words.slice(0, words.length - 1)
                        }
                        if(words.slice(0,1) === ' ')
                        {
                            words = words.slice(1, words.length)
                        }
                        try {
                            const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/ReadingTrain-Core/ReadingTrain-Core/WordAudio/${words}.mp3`);
                            z = src
                        }
                        catch (err) 
                        {
                            
                        }
                    }
                    audio.src = z
                    audio.play()
                    audio.onended = () => {}
                }
               
                
            }
            catch(err){}
            
        }
        if(type === '3' && !this.state.finishTest)
        {
            try
            {
                let word = this.props.location.state.pages.questions[3].prompt
                let z = ''
                word = word.toLowerCase()
                word = word.replaceAll('.', '')
                word = word.replaceAll(',', '')
                word = word.replaceAll('?', '')
                word = word.replaceAll('!', '')
                word = word.replaceAll(`'`, '')
                word = word.replaceAll(`"`, '')
                word = word.replaceAll(`"`, '')
                try {
                    const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/QuizMusic/quiz-question-intro-3.mp3`);
                    z = src
                } catch (err) {
                }
                
                this.state.song.src = z
                if(!this.state.finishTest)  
                this.state.song.play();
                this.state.song.onended = async (some='', audio = this.state.song, level = this.state.level, unit = this.props.location.state.pages.identifier.slice(0,-3), book = this.props.location.state.pages.identifier, words = word) => 
                {
                    let z = ''
                    if(words.slice(words.length - 1, words.length) === ' ')
                    {
                        words = words.slice(0, words.length - 1)
                    }
                    
                    try {
                        const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/ReadingTrain-Core/ReadingTrain-Core/Courses/${level}/${unit}/${book}/${words}.mp3`);
                        z = src
                    }
                    catch (err) 
                    {
                        if(words.slice(words.length - 1, words.length) === ' ')
                            {
                                words = words.slice(0, words.length - 1)
                            }
                            if(words.slice(0,1) === ' ')
                            {
                                words = words.slice(1, words.length)
                            }
                            try {
                                const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/ReadingTrain-Core/ReadingTrain-Core/Courses/${level}/${unit}/${book}/${words}.mp3`);
                                z = src
                            }
                            catch (err) 
                            {
                               
                            }
                    }
                    audio.src = z
                    audio.play()
                    audio.onended = () =>{}
                }
            }
            catch(err){ }
        }
        
    }

    async falseTraine() {
        this.state.falseAnsTime = this.state.falseAnsTime + 1
        let train = document.getElementById('train')
        let left = 30
        let z = ''
        try 
        {
            const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/SoundEffects/incorrect-${Math.floor(Math.random() * 27) + 1  }.mp3`);
            z = src
        } 
        catch (err) {
        }
        this.state.song.src = z
        this.state.song.play()
        let timer = setInterval( async () => {
            left = left - 10;
    
            train.style.left = left + 'px';
    
            if (left === 10) {
                clearInterval(timer)
                    let timer1 = setInterval(function() {
                    left = left + 10;
                    train.style.left = left + 'px';
                    let elms = document.querySelectorAll("[id='wheel']");
                    for(let i = 0; i < elms.length; i++) 
                    {
                        elms[i].style.transform=`rotate(${left*15}deg)`;
                    }
                    if (left === 50) {
                        clearInterval(timer1);
                            let timer2 = setInterval(function() {
                            left = left - 10;
                            train.style.left = left + 'px';
                            let elms = document.querySelectorAll("[id='wheel']");
                            for(let i = 0; i < elms.length; i++) 
                            {
                                elms[i].style.transform=`rotate(${left*-15}deg)`;
                            }
                            if (left === 10) {clearInterval(timer2);
                                let timer3 = setInterval(function() {
                                    left = left + 10;
                                    train.style.left = left + 'px';
                                    let elms = document.querySelectorAll("[id='wheel']");
                                    for(let i = 0; i < elms.length; i++) 
                                    {
                                        elms[i].style.transform=`rotate(${left*15}deg)`;
                                    }
                                    if (left === 30){
                                       
                                        clearInterval(timer3)
                                    };
                            
                                  }, 30);
                            }
                    
                        }, 30);
                    }
            
                }, 30);
            };
    
          }, 30);
        let c = 2
        if(this.state.level === '00001')
        c = 2
        else
        c = 3

        if(this.state.falseTry + 1 === c){
            setTimeout( () => {
 let i = 0
        let corrects = JSON.parse(JSON.stringify( this.state.correct )); 
           for(let i = 0; i < this.state.numberOfAnswer; i++){
            if(document.getElementById( i +'Car').childNodes[1])
            {
                if(document.getElementById( i +'Car').childNodes[2]){
                    if(document.getElementById( i +'Car').childNodes[2].id.includes('dragCopy')){
                        let id = document.getElementById( i +'Car').childNodes[1].id.slice(0,1)
                        if(!document.getElementById( i +'Car').childNodes[1].id.includes('Copy'))
                        document.getElementById( id + 'drag').appendChild(document.getElementById( i +'Car').childNodes[1])
                    }else{
                        let id = document.getElementById( i +'Car').childNodes[2].id.slice(0,1)
                        if(!document.getElementById( i +'Car').childNodes[2].id.includes('Copy'))
                        document.getElementById( id + 'drag').appendChild(document.getElementById( i +'Car').childNodes[2])
                    }
                }else{
                    let id = document.getElementById( i +'Car').childNodes[1].id.slice(0,1)
                    if(!document.getElementById( i +'Car').childNodes[1].id.includes('Copy'))
                    document.getElementById( id + 'drag').appendChild(document.getElementById( i +'Car').childNodes[1])
                }
            }
        }
        while(document.getElementById( i + 'drag')){
            if(document.getElementById( i + 'drag').childNodes[0].childNodes[0].childNodes[0])
            {
                let check = document.getElementById( i + 'drag').childNodes[0].childNodes[0].childNodes[0]
                if(check.childNodes[1])
                {
                    check = check.childNodes[1].childNodes[0].childNodes[0].childNodes[0].innerHTML
                }
                else{
                    check = check.childNodes[0].childNodes[0].childNodes[0].childNodes[0].innerHTML
                }
                //  check =  check.replaceAll(' ','')
                //  check =  check.replaceAll(' ','')
                if(corrects.indexOf(check) !== -1)
                {
                    let drag = document.getElementById(i+'drag').childNodes[0]
                    let draggg = document.getElementById(i + 'drag')
                    let drop = document.getElementById(corrects.indexOf(check) + 'Car')
                    let dragCopy = drag.cloneNode(true)
                    dragCopy.style.position = 'absolute'
                    dragCopy.style.bottom = '26%'
                    dragCopy.style.left = '20px'
                    dragCopy.style.opacity = '0.7'
                    dragCopy.id = i + 'dragCopy'
                    if(drop.childNodes[1]){

                    }else{
                        drop.appendChild(dragCopy)
                    }
                    if(draggg.childNodes[0]){

                    }else{
                        draggg.appendChild(drag)
                    }
                    if(typeof(corrects) === 'string')
                    {
                        corrects = corrects.substring(0, corrects.indexOf(check)) + '!' + corrects.substring(corrects.indexOf(check) + 1)
                    }
                    else{
                        corrects[corrects.indexOf(check)] = '!'
                    }
                }
                i++
            }
            
        }
            }, 10 )
           
        }
        else{
            this.setState({
                falseTry: this.state.falseTry+1
            })
        }
    }

    async testPresentShow(){
        for(let i = 0; i < 4; i++){
            let drop = document.getElementById(i + 'Car')
            drop.removeEventListener("click", this.testPresentShow);
        }
        let tag = 'child_user'
		db.collection(tag).where('email', '==', auth.currentUser.email).get().then( response => {
			response.forEach( data => {
			    let	user = data.data()
				user.correctAnswer = user.correctAnswer + this.state.corectAnsTime + 1
                user.falseAnswer = user.falseAnswer + this.state.falseAnsTime
                user.coins = user.coins + 1
                if(!Object.values(user.readedBook).includes(this.props.location.state.book))
                    user.readedBook[(Object.values(user.readedBook).length+2).toString()] = this.props.location.state.book
                db.collection(tag).doc(data.id).update(user)
			} )
		} )
        this.setState({
            present: true 
        })
        this.goAwai()
        
    }
    async trainStart(){
        this.setState({
            CheckClick: false
        })
        if(this.state.finishTest)
        {
                try
                { for(let i = 0; i < 4; i++){
                    let drag = document.getElementById(i+'drag').childNodes[0]
                    let drop = document.getElementById(i + 'Car')
                    drop.addEventListener("click", this.testPresentShow);
                    drop.appendChild(drag)}
                }
                catch(err)
                {   
                }
                let z = ''
                try {
                    const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/TutorialAudio/tutorial-quiz-pick-prize.mp3`);
                    z = src
                }
                catch (err) 
                {
                }
                this.state.song.src = z
                this.state.song.play()
        }
        else
        {
            
            this.playAudio()
        }
        let train = document.getElementById('train')
        let left = 1000
        let timer = setInterval(() => {
            left = left - 10;
            
            train.style.left = left + 'px';
            let elms = document.querySelectorAll("[id='wheel']");
            for(let i = 0; i < elms.length; i++) 
            {
                elms[i].style.transform=`rotate(${left*15}deg)`;
            }
            if (left === 30) 
            {
                clearInterval(timer)
                this.setState({
                    CheckClick: true
                })
                this.forceUpdate()
            };
    
          }, 30);

          this.refBlock.current.updateRef()
    }


    async correctTrain(){
        let copyCount = 0
        this.state.corectAnsTime = this.state.corectAnsTime + 1
        for( let i = 0; i < 10; i++){
            if(document.getElementById(i+'dragCopy')){
                let node = document.getElementById(i+'dragCopy')
                node.remove()
            }
        }
        this.setState({
            CheckClick: false
        })
        let train = document.getElementById('train')
        let left = 30
        let z = ''
        if(this.state.falseTry === 0)
        {
            try 
            {
                const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/SoundEffects/correct-${Math.floor(Math.random() * 79) + 1  }.mp3`);
                z = src
            } 
            catch (err) {
                
            }
            this.state.song.src = z
            this.state.song.play()
        }
        let timer3 = setInterval( async () => {
            left = left - 10;
            train.style.left = left + 'px';
            let elms = document.querySelectorAll("[id='wheel']");
            for(let i = 0; i < elms.length; i++) 
            {
                elms[i].style.transform=`rotate(${left*15}deg)`;
            }

            if (left === -1000){
                    clearInterval(timer3)
                    let marks = this.state.checkMark
                    marks[this.state.currentQuestion] = true
                    await this.setState({
                      checkMark: marks,
                      currentQuestion: this.state.currentQuestion + 1, 
                    })
                try
                {
                    for(let i = 0; i < this.state.numberOfAnswer; i++){
                        if(this.state.falseTry + 1 === 3)
                        {
                            if(document.getElementById( i +'Car').childNodes[2])
                            {
                                let id = document.getElementById( i +'Car').childNodes[1].id.slice(0,1)
                                document.getElementById( id + 'drag').appendChild(document.getElementById( i +'Car').childNodes[2])
                                document.getElementById(i + 'Car').removeChild(document.getElementById(i + 'Car').childNodes[1])
                            }
                        }
                        else
                        {
                            if(document.getElementById( i +'Car').childNodes[1])
                            {
                                let id = document.getElementById( i +'Car').childNodes[1].id.slice(0,1)
                                document.getElementById( id + 'drag').appendChild(document.getElementById( i +'Car').childNodes[1])
                            }
                        }
                    }
                }
                catch(err){}
                 
                 this.setState({
                     falseTry: 0
                 }) 
                  this.trainStart()
                };
    
          }, 30);
       

    }

    async goAwai(){
        let train = document.getElementById('train')
        let left = 30
        let z = ''
        if(this.state.falseTry === 0)
        {
            try 
            {
                const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/SoundEffects/correct-${Math.floor(Math.random() * 79) + 1  }.mp3`);
                z = src
            } 
            catch (err) {
            }
            this.state.song.src = z
            this.state.song.play()
        }
        let timer3 = setInterval( async () => {
            left = left - 10;
            train.style.left = left + 'px';
            let elms = document.querySelectorAll("[id='wheel']");
            for(let i = 0; i < elms.length; i++) 
            {
                elms[i].style.transform=`rotate(${left*15}deg)`;
            }

            if (left === -1000){
                    clearInterval(timer3)
                    let marks = this.state.checkMark
                    marks[this.state.currentQuestion] = true
                    await this.setState({
                      checkMark: marks,
                      currentQuestion: this.state.currentQuestion + 1, 
                    })
                try
                {
                    // for(let i = 0; i < this.state.numberOfAnswer; i++){
                    //     if(this.state.falseTry + 1 === 3)
                    //     {
                    //         if(document.getElementById( i +'Car').childNodes[2])
                    //         {
                    //             let id = document.getElementById( i +'Car').childNodes[1].id.slice(0,1)
                    //             document.getElementById( id + 'drag').appendChild(document.getElementById( i +'Car').childNodes[2])
                    //             document.getElementById(i + 'Car').removeChild(document.getElementById(i + 'Car').childNodes[1])
                    //         }
                    //     }
                    //     else
                    //     {
                    //         if(document.getElementById( i +'Car').childNodes[1])
                    //         {
                    //             let id = document.getElementById( i +'Car').childNodes[1].id.slice(0,1)
                    //             document.getElementById( id + 'drag').appendChild(document.getElementById( i +'Car').childNodes[1])
                    //         }
                    //     }
                    // }
                }
                catch(err){}
                 
                 this.setState({
                     falseTry: 0
                 }) 
                };
          }, 30);
       

    }


    async checkCorrect(){
        
        if(this.state.finishTest){
            alert('Select your present')
        }
        else
        if(this.state.CheckClick){
            try{
                clearInterval(this.state.timerGoButton)
                this.state.timerGoButton = setInterval( async () =>  {
                    if(this.state.showHelp){
                        let z = ''
                        try {
                            switch(this.state.count){
                                case 0: {
                                    this.state.count = 1
                                const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/TutorialAudio/tutorial-quiz-1.mp3`);
                                z = src
                                break;
                                }
                                case 1: {
                                    this.state.count = 2
                                    const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/TutorialAudio/tutorial-quiz-2.mp3`);
                                    z = src
                                    break;
                                }
                                case 2: {
                                    this.state.count = 3
                                    const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/TutorialAudio/tutorial-quiz-3.mp3`);
                                    z = src
                                    break
                                }
                                case 3: {
                                    this.state.count = 0
                                    const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/TutorialAudio/tutorial-quiz-4.mp3`);
                                    z = src
                                    this.state.showHelp = false
                                    break;
                                }
                            }
                        }
                        catch (err) 
                        {
                        }
                        if(this.state.song.ended){
                            this.state.song.src = z
                            this.state.song.play()
                        }
                    }
                },
                18000
                // 600
                )
                let correct = 0
                this.setState({
                    // showHelp: false 
                })
                for(let i = 0; i < this.state.numberOfAnswer; i++)
                {   
                    let blockText = '' 
                    if(document.getElementById( i +'Car').childNodes[1])
                    {
                        let checkId = ''
                        if(document.getElementById( i +'Car').childNodes[2]){
                            checkId = document.getElementById( i + 'Car').childNodes[2].childNodes[0].childNodes[0]
                        }else{
                            checkId = document.getElementById( i + 'Car').childNodes[1].childNodes[0].childNodes[0]
                        }
                        let id = 0
                        if(this.state.falseTry + 1 === 3){
                            id = 2
                        }
                        else
                        {
                            id = 1
                        }
                        
        
                        if(this.state.falseTry + 1 === 3 && document.getElementById( i + 'Car').childNodes.length !==3 ){
                            break;
                        }
                        if(checkId.childNodes[1])
                        {
                          blockText =   checkId.childNodes[1].childNodes[0].childNodes[0].childNodes[0].innerHTML
                        }
                        else
                        {
                            blockText =   checkId.childNodes[0].childNodes[0].childNodes[0].childNodes[0].innerHTML
                        }
                        if(this.state.quizTypes[this.state.currentQuestion] === '3a')
                        {
                            if(this.state.correct.includes(blockText))
                            {
                                correct++
                            }
                            else{
                                if(document.getElementById( i +'Car').childNodes[1])
                                {
                                    if(document.getElementById( i +'Car').childNodes[2]){
                                        let id = document.getElementById( i +'Car').childNodes[2].id.slice(0,1)
                                        document.getElementById( id + 'drag').appendChild(document.getElementById( i +'Car').childNodes[2])
                                    }else{
                                        let id = document.getElementById( i +'Car').childNodes[1].id.slice(0,1)
                                        document.getElementById( id + 'drag').appendChild(document.getElementById( i +'Car').childNodes[1])
                                    }
                                    // let id = document.getElementById( i +'Car').childNodes[1].id.slice(0,1)
                                    // document.getElementById( id + 'drag').appendChild(document.getElementById( i +'Car').childNodes[1])
                                }
                            }
                        }
                        else
                        {
                            if(this.state.correct[i] === blockText){
                                correct++
                            }
                            else{
                                if(document.getElementById( i +'Car').childNodes[1])
                                {
                                    if(document.getElementById( i +'Car').childNodes[2]){
                                            if(document.getElementById( i +'Car').childNodes[2]){
                                                let id = document.getElementById( i +'Car').childNodes[2].id.slice(0,1)
                                                if(!document.getElementById( i +'Car').childNodes[2].id.includes('Copy'))
                                                document.getElementById( id + 'drag').appendChild(document.getElementById( i +'Car').childNodes[2])
                                            }else{
                                                let id = document.getElementById( i +'Car').childNodes[1].id.slice(0,1)
                                                if(!document.getElementById( i +'Car').childNodes[1].id.includes('Copy'))
                                                document.getElementById( id + 'drag').appendChild(document.getElementById( i +'Car').childNodes[1])
                                            }
                                    }else{
                                        let id = document.getElementById( i +'Car').childNodes[1].id.slice(0,1)
                                        if(!document.getElementById( i +'Car').childNodes[1].id.includes('Copy'))
                                        document.getElementById( id + 'drag').appendChild(document.getElementById( i +'Car').childNodes[1])
                                    }
                                }
                            }
                        }
                    }
                    
                }


                if(correct === this.state.numberOfAnswer){
                    if(this.state.currentQuestion + 2 > 5)
                    {
                        for( let i = 0; i < 10; i++){
                            if(document.getElementById(i+'dragCopy')){
                                let node = document.getElementById(i+'dragCopy')
                                node.remove()
                            }
                        }
                        let train = document.getElementById('train')
                        let left = 30
                        await this.setState({
                                        correctAns: true
                                    })
                        let marks = this.state.checkMark
                        marks[this.state.currentQuestion] = true
                        await this.setState({
                            checkMark: marks, 
                        })
                        let timer3 = setInterval( async () => {
                            left = left - 10;
                            train.style.left = left + 'px';
                            let elms = document.querySelectorAll("[id='wheel']");
                            for(let i = 0; i < elms.length; i++) 
                            {
                                elms[i].style.transform=`rotate(${left*15}deg)`;
                            }
                            if (left === -1000){
                                    clearInterval(timer3)
                                    
                                    try
                                    {
                                        for(let i = 0; i < 10; i++)
                                        {
                                            if(document.getElementById( i +'Car').childNodes[1])
                                            {
                                                let id = document.getElementById( i +'Car').childNodes[1].id.slice(0,1)
                                                document.getElementById( id + 'drag').appendChild(document.getElementById( i +'Car').childNodes[1])
                                            }
                                        }
                                    }
                                    catch(err)
                                    {}
                                    clearInterval(this.state.timerGoButton)
                                    await this.setState({
                                        finishTest: true,
                                    })
                                    this.trainStart()
                                };
                    
                          }, 30);
                    }
                    else{
                        await this.setState({
                         correctAns: true,
                         CheckClick: false
                        })
                        this.correctTrain()
                    }
                    
                    
                }   
                else
                {
                    this.falseTraine()
                }
                setTimeout( () =>{
                    this.setState({
                    correctAns: false
                })
                }, 1500)
            }
            catch(err)  {
            }
        }
    }

   

    async UNSAFE_componentWillMount(){
        let z = ''
        let errG = false
        try {
            const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/TutorialAudio/quiz-intro-${Math.floor(Math.random()*9) + 1}.mp3`);
            z = src
        }
        catch (err) 
        {
        }
        this.state.song1.src = z
        try{
         this.state.song1.play().then(data => {
         })
         .catch(async err => { 
             errG = true
             clearInterval(this.state.timerGoButton)
            this.state.timerGoButton = setInterval( async () =>  {
                if(this.state.showHelp){
                    let z = ''
                    try {
                        switch(this.state.count){
                            case 0: {
                                this.state.count = 1
                            const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/TutorialAudio/tutorial-quiz-1.mp3`);
                            z = src
                            break;
                            }
                            case 1: {
                                this.state.count = 2
                                const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/TutorialAudio/tutorial-quiz-2.mp3`);
                                z = src
                                break;
                            }
                            case 2: {
                                this.state.count = 3
                                const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/TutorialAudio/tutorial-quiz-3.mp3`);
                                z = src
                                break
                            }
                            case 3: {
                                this.state.count = 0
                                const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/TutorialAudio/tutorial-quiz-4.mp3`);
                                z = src
                                this.state.showHelp = false
                                break;
                            }
                        }
                    }
                    catch (err) 
                    {
                    }
                    if(this.state.song.ended){
                        this.state.song.src = z
                        this.state.song.play()
                    }
                }
                
            },
            18000
            // 600
            )
            await this.props.data.quiz.then(await function(result) { z = result;});
            let x = 0
            for(let i = 0; i < this.state.numberOfAnswer; i++){
                x += this.state.ansSumm[i]
            }
            await this.setState({
                level: this.props.location.state.pages.identifier.slice(0,5),
                quiz: z,
                trainWidth: x / (parseInt(this.state.numberOfAnswer)+1)*this.state.numberOfAnswer
            }) 

            this.state.quizTypes.push('0a')
            this.state.quizTypes.push('0b')
            this.state.quizTypes.push('0c')
            this.state.quizTypes.push('1a')
    
            if(this.state.level !== '00001')
            {
                this.state.quizTypes.push('1b')
            }
            if(this.state.level !== '00003')
            {
                this.state.quizTypes.push('2a')
            }
    
            this.state.quizTypes.push('2b')
            this.state.quizTypes.push('3a')
            this.state.quizTypes.sort(function(){ return Math.random() - 0.5}) 
            this.forceUpdate()
            this.trainStart() })
        }
        catch(err){
            
        }
        this.state.song1.onended = () => {
            this.forceUpdate()
            this.trainStart()
        }

        if(!errG)
        {
            
            clearInterval(this.state.timerGoButton)
            this.state.timerGoButton = setInterval( async () =>  {
                if(this.state.showHelp){
                    let z = ''
                    try {
                        switch(this.state.count){
                            case 0: {
                                this.state.count = 1
                            const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/TutorialAudio/tutorial-quiz-1.mp3`);
                            z = src
                            break;
                            }
                            case 1: {
                                this.state.count = 2
                                const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/TutorialAudio/tutorial-quiz-2.mp3`);
                                z = src
                                break;
                            }
                            case 2: {
                                this.state.count = 3
                                const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/TutorialAudio/tutorial-quiz-3.mp3`);
                                z = src
                                break
                            }
                            case 3: {
                                this.state.count = 0
                                this.state.showHelp = false
                                const { default: src } = await import(/* webpackMode: "lazy" */ `../../source/TutorialAudio/tutorial-quiz-4.mp3`);
                                z = src
                                break;
                            }
                        }
                    }
                    catch (err) 
                    {
                    }
                    this.state.song.src = z
                    this.state.song.play()
                }
            },
            18000
            // 600
            )
            await this.props.data.quiz.then(await function(result) { z = result;});
            let x = 0
            for(let i = 0; i < this.state.numberOfAnswer; i++){
                x += this.state.ansSumm[i]
            }
            await this.setState({
                level: this.props.location.state.pages.identifier.slice(0,5),
                quiz: z,
                trainWidth: x / (parseInt(this.state.numberOfAnswer)+1)*this.state.numberOfAnswer
            }) 

            this.state.quizTypes.push('0a')
            this.state.quizTypes.push('0b')
            this.state.quizTypes.push('0c')
            this.state.quizTypes.push('1a')
            
            if(this.state.level !== '00001')
            {
                this.state.quizTypes.push('1b')
            }
            if(this.state.level !== '00003')
            {
                this.state.quizTypes.push('2a')
            }
    
            this.state.quizTypes.push('2b')
            this.state.quizTypes.push('3a')
            this.state.quizTypes.sort(function(){ return Math.random() - 0.5})
        }
        // console.log('refBlock', this.refBlock)
        // // this.refBlock.current.updateRef()
        // console.log('state generate')
        
    }

    async componentDidMount(){
        // console.log('refBlock', this.refBlock.current)
        // if(this.refBlock.current)
        // this.refBlock.current.updateRef()
    }

    async workWithTraine(correct, word = null)
    {
            await this.setState({
                numberOfAnswer: correct.length,
                correct: correct,
                makeWord: word
            })
        let k = 0
        while(document.getElementById(k + 'Car'))
        {
            let div = document.getElementById(k + 'Car')
            div.style.display = 'block'
            k++;
        }

        let i = correct.length;
        if(this.state.finishTest)
        i = 4
        while(document.getElementById(i + 'Car'))
        {
            let div = document.getElementById(i + 'Car')
            // div.style.display = 'none'
            i++;
        }
        
            this.forceUpdate()
            this.playAudio()
    }
    

    render(){
        return(
            <DndProvider backend={HTML5Backend}>
            <div className={classes.bg}>
                <div>
                </div>
            <div className={classes.tests}>
                <div style={{position:'absolute'}} className={classes.img}>
                    <BackButton history={this.props.history} />
                    {/* <img onClick={() => {this.props.history.goBack()}} style={{width:'32px', height:'32px', margin:'15px'}}  alt='' src={backButton} /> */}
                </div>
                    <div id="shop" >
                        <Shop />
                    </div>
                    <div className={classes.trackUnUsed}>
                        <Traks />
                    </div>
                    <div className={classes.trackUsed}>
                        <Traks />
                    </div>
                    <div
                    //  style={this.state.finishTest ? { visibility: 'false' } : {visibility: 'true'}} 
                    id='response'  className={classes.response}>
                        <OneWord  ref={this.refBlock} level = { this.state.level }  numberOfAnswer = {this.state.numberOfAnswer}  present = {this.state.finishTest} type = {this.state.quizTypes[this.state.currentQuestion]}  getCorrect ={this.workWithTraine} pages = {this.props.location.state.pages} />
                    </div>
                      {this.state.present 
                      ?
                      <Present history={this.props.history}  level = {this.state.level} unit = {this.props.location.state.pages.identifier.slice(0,-3)} book = {this.props.location.state.pages.identifier} />
                      : <div />
                      }
                    <div className={classes.platform}>
                        <Platform />
                    </div>

                    <div id='train' 
                    className= 
                        {cn(classes.train, classes.shake)} 
                    >
                        <Train
                         play = {this.playAudio}
                         shake = {this.state.shake}
                         present = {this.state.finishTest}
                         cardId = {this.state.cardID}
                         width = {this.state.trainWidth} 
                         numberOfAnswer = {this.state.numberOfAnswer} 
                         />
                    </div>
                    <div className={classes.signal}>
                        <img alt='' src={this.state.correctAns ? signal_green : signal_red} />
                    </div>
                    <div className={classes.nextTest}>
                        <div onClick={this.checkCorrect} className={classes.text}> GO </div>
                        <img onClick={this.checkCorrect} alt="" src={NextTextImg} />
                    </div>
                    <div className={classes.sign}>
                        <img alt='' src={station_sign} />
                        <p> {this.state.currentQuestion + 1} </p>
                    </div>
                    <div className={classes.checkMark}>
                        <img alt="" src={check_mark} />
                        <div className={classes.mark}>
                            <img alt='' src={this.state.checkMark[0] ? quizMarkOn : quizMarkOff} />
                            <img alt='' src={this.state.checkMark[1] ? quizMarkOn : quizMarkOff} />
                            <img alt='' src={this.state.checkMark[2] ? quizMarkOn : quizMarkOff} />
                            <img alt='' src={this.state.checkMark[3] ? quizMarkOn : quizMarkOff} />
                            <img alt='' src={this.state.checkMark[4] ? quizMarkOn : quizMarkOff} />
                        </div>
                    </div>
            </div>
            </div>
            </DndProvider>
        )
    }
}

export default Tests