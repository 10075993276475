import React from 'react'
import classes from './BookInfo.module.scss'
import InfoImg from '../../../BooksPreview/img/info.png'
import cn from 'classnames'
class Bookinfo extends React.PureComponent{
    constructor(props){
        super(props);
        this.state={
            song: new Audio(),
        }
        this.playInfo = this.playInfo.bind(this)

    }

    playInfo(e){
        e.stopPropagation();
        this.state.song.src = require('../../../../../../source/ReadingTrain-Core/ReadingTrain-Core/Courses/'+ this.props.level +'/'+ this.props.unit +'/'+ this.props.book +'/main idea.mp3')
        this.state.song.play()
    }
    componentWillUnmount()
    {
        this.state.song.pause()
    }
    
    render(){
        return(
            <div className={this.props.classes === 'show' ? classes.rtBookInfo : cn(classes.rtBookInfo, classes.hide)} onClick={() => {this.state.song.pause(); this.props.hide() } }>
                <div className={classes.rtInfoCard} onClick={this.playInfo}>
                    <div className={classes.title}>
                        <img alt = '' src = {InfoImg} />
                        Main idea
                    </div>
                    <div className={classes.text}>
                        {this.props.text}
                     </div>
                </div>
            </div>
        )
    }
}

export default Bookinfo