import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux'
import { withCookies, Cookies } from 'react-cookie';
import ReactCodeInput from 'react-verification-code-input';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import classes from './UserDetail.module.scss'
import {Doughnut} from 'react-chartjs-2';
import { db, auth } from '../../../Firebase/fireconfig'
import {CircleFadeLoader} from 'react-loaders-kit';
import RenderBook from './components/renderBook/RenderBook'
import coins from '../../../source/Images/menus/coin-music@2x.png'
import AdminUserDetail from './components/UserDeatilForTeacher'
import MaterialTable from 'material-table'
import { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ChildCreate from '../RegisterPage/components/UserInfo'
import { DeleteUser } from '../../../API/deleteUser'
const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox  color='rgba(0,0,0,0.5)' {...props} ref={ref} />),
    // Add: props => (
    //   <AddExerciseButton   >
    //   Додати вправу
    // </AddExerciseButton>
    // ),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete:  forwardRef((props, ref) => <DeleteOutline style={{opacity:'0.5'}} {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit style={{width:'18px', height:'18px',opacity:'0.5'}}  {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };
const TextField2 = withStyles({
    root: {
      borderRadius: 3,
      border: 0,
      color: 'white',
      padding: '0 30px',
      boxShadow: '0 3px 5px 2px rgba(100, 87, 88, .3)',
      padding:0,
      margin: '6px auto 3px auto',
      '& label.Mui-focused': {
        color: 'white',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#FFDEE0',
      },
      '& .MuiInputLabel-formControl':{
        // top: -15
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#FFDEE0',
        },
      },
    },
   
  })(TextField);
const DialogContentText1 = withStyles({
	root: {
	  color:'red !important'
	  
	},
   
  })(DialogContentText);
class UserDetail extends React.PureComponent{
    constructor(props){
		super(props)
		this.FA2Ref = React.createRef();
        this.state = {
			errorMessage: { show: false, error: '' },
			createChild: false,
			selectedClass: 0,
			data: {
				labels: [
					'False answer',
					'Correct answer',
				],
				datasets: [{
					data: [1, 1],
					backgroundColor: [
						'red',
						'green',
					],
					hoverBackgroundColor: [
						'red',
						'green',
					]
				}]
			},
			Book: {
				labels: [
					'All books',
					'Read books',
				],
				datasets: [{
					data: [326,  0],
					backgroundColor: [
						'red',
						'green',
					],
					hoverBackgroundColor: [
						'red',
						'green',
					]
				}]
			},
			loading: true,
			allBoks: 326,
			user: ''
		}
		this.renderBook = this.renderBook.bind(this)
		this.createClass = this.createClass.bind(this)
		this.setClass = this.setClass.bind(this)
		this.deleteClass = this.deleteClass.bind(this)
	}


	deleteClass(){
		let state_user = this.state.user
		let tag = 'child_user'
		// db.collection(tag).where('usedClassCode', '==', this.state.user.teacherClasses[this.state.selectedClass]).get().
		// then( response => {
		// 	let users = []
		// } )
		state_user.teacherClasses.splice(this.state.selectedClass, 1)
		
		db.collection(tag).where('email', '==', auth.currentUser.email).get().then( response => {
			response.forEach( data => {
			    let	user = data.data()
                user.teacherClasses = state_user.teacherClasses
               	db.collection(tag).doc(data.id).update(user)
			} )
		} )
		this.setState({
			user: state_user,
			deleteClass: false,
		})
		this.state.selectedClass = 0
 		db.collection(tag).where('usedClassCode', '==', this.state.user.teacherClasses[this.state.selectedClass]).get().then( response => {
			let users = []
			response.forEach( data => {
				let user = data.data()
				if(user.tag === 'child'){
					users.push(data.data())
				}
			} )
			this.setState({users: users, loading: false})
		} )
		this.forceUpdate()
	}

	setClass(index){
		this.setState({
			selectedClass: index
		})
		let tag = 'child_user'
		db.collection(tag).where('usedClassCode', '==', this.state.user.teacherClasses[index]).get().then( response => {
			let users = []
			response.forEach( data => {
				let user = data.data()
				if(user.tag === 'child'){
					users.push(data.data())
				}
			} )
			this.setState({users: users, loading: false})
			this.forceUpdate()
		} )

	}

	async createClass(){
		let state_user = this.state.user	
		if(state_user.teacherClasses.length === 0){
			state_user.teacherClasses.push(auth.currentUser.email + " " + 0)
		}else{
			let className = state_user.teacherClasses[state_user.teacherClasses.length-1]
			className = className.replace(auth.currentUser.email, '')
			className = parseInt(className, 10) + 1
			state_user.teacherClasses.push(auth.currentUser.email + ' ' + className)
		}
		let tag = 'child_user'
		db.collection(tag).where('email', '==', auth.currentUser.email).get().then( response => {
			response.forEach( data => {
				let	user = data.data()
				
                user.teacherClasses = state_user.teacherClasses
               	db.collection(tag).doc(data.id).update(user)
			} )
		} )
		this.setState({
			user: state_user,
			selectedClass: state_user.teacherClasses.length - 1
		})
		this.forceUpdate()
	}

	async renderBook(data){
		
	}

	componentWillMount(){
		let tag = 'child_user'
		db.collection(tag).where('email', '==', auth.currentUser.email).get().then( response => {
			response.forEach( data => {
				let user = data.data()
					if(user.tag === 'child'){
						if(!user.correctAnswer){
							user['correctAnswer'] = 0
						}
						if(!user.falseAnswer){
							user['falseAnswer'] = 0
						}
						if(user.readedBook){
							user['bookReaded'] = Object.values(user.readedBook).length 
							user['bookList'] = Object.values(user.readedBook)
						}else{
							user['bookReaded'] = 0
						}
						this.state.data.datasets[0].data = [ user['falseAnswer'],user['correctAnswer']]
						this.state.Book.datasets[0].data[1] = user['bookReaded']
						this.setState({
							user: user,
							loading: false
						})
					}else{
						let find =''
						if(user.teacherClasses[0]){
							find = user.teacherClasses[0]
						}
						else{
							find = ''
						}
						db.collection(tag).where('usedClassCode', '==', find).get().then( response => {
							let users = []
							response.forEach( data => {
								let user = data.data()
								if(user.tag === 'child'){
									users.push(data.data())
								}
							} )
							this.setState({users: users, loading: false, user})
						} )
					}
					this.forceUpdate()
			} )
		} )
		
		
	}


    render(){
		
		const loaderProps = {
            colors: ['red', 'black', 'red', 'black']
        }
        return(
            <div style={{minHeight:'100vh', padding:'10px'}}>
	<div>
		{
			this.state.loading ?
			<div style={{
				width: '100px', height: '100px', position: 'absolute'
				, top: '50%',
				left: '50%',
				marginTop: '30vh', minHeight: '100vh'
			}}><CircleFadeLoader size={50} loading={this.state.loading} color="black"  {...loaderProps} /></div> :
			
				this.state.user.tag === 'child' ? 
				<div >
					<div style={{display:'flex', justifyContent:'space-between'}}>
					<div> 
						<div className={classes.statBlock}> My Answers </div>
						<div className={classes.statBlock}>
							{this.state.data.datasets[0].data[1]} / {this.state.data.datasets[0].data[0] + this.state.data.datasets[0].data[1] }
						</div>
						{/* <Doughnut data={this.state.data} /> */}
					</div>
					<div> 
						<div className={classes.statBlock}> My Books </div>
						{/* <Doughnut data={this.state.Book} /> */}
						<div className={classes.statBlock}>
						{this.state.Book.datasets[0].data[1]}
						</div>
					</div>
					<div style={{width:'300px', textAlign:'center'}}>
						<div style={{width:'300px'}} className={classes.statBlock}> My Coins: {this.state.user.coins} </div>
						<img alt='' style={{width:'130px', marginTop:'30px'}} src={coins} />
					</div>
					</div>
					<div  className={classes.unit} style={{marginTop:'45px'}}>
						{this.state.user.bookList ? this.state.user.bookList.map( data => {
						 let array = data.split('-')
						 let img = ''
					   return(<div>
						  <RenderBook history={this.props.history} level={array[0]} unit={array[0]+'-'+array[1]} 
												 book={array[0]+'-'+array[1] +'-'+ array[2]}/>
					   </div>)
						} ) : <div />}
					</div>
				</div> 
				:
				this.state.createChild ? 
						<ChildCreate tag='child' class={this.state.user.teacherClasses[this.state.selectedClass]} create = { (t) => { 
							let tag = 'child_user'
							db.collection(tag).where('usedClassCode', '==', this.state.user.teacherClasses[this.state.selectedClass]).get().then( response => {
							let users = []
							response.forEach( data => {
							let user = data.data()
							if(user.tag === 'child'){
								users.push(data.data())
							}
							} )
							this.setState({users: users, loading: false})
							} )
							this.setState({ createChild: false }) } } /> 
				:
				<div>
					<div className={classes.buttonStyle} > 
						<button onClick={this.createClass} > Create class </button>
						<button onClick={ () => { 
							if(this.state.selectedClass >= this.state.user.teacherClasses.length){
								alert('Please select class')
							}else{
								this.setState({ createChild: true })
							}
							 }  } > Add student </button>
						<button onClick={ () => { this.setState({ deleteClass: true }) }  } > Delete Class </button>
					</div>
					<div className={classes.classMap} style={{marginTop:'20px'}} >
						{ this.state.user.teacherClasses.map( (data, index) => {
							return <button onClick={ () => {
								this.setClass(index)
							} } className={this.state.selectedClass === index ? classes.active : classes.disable} > Class {' '} {index + 1 } </button>
						} ) }
					</div>
				<MaterialTable
					style={{margin:'10px'}}
					columns={[
						{ title: 'Student', field: 'firstName', render: rowData => {
							return(
								<div>
									{rowData.firstName + ' ' + rowData.lastName}
								</div>
							)
						} },
						
					]}
					icons={tableIcons}
					data={this.state.users ? this.state.users : []}
					title="Users"
					detailPanel={rowData => {
						return (
						<AdminUserDetail history={this.props.history} email={rowData.email} />
						)
					}}
					onRowClick={(event, rowData, togglePanel) => togglePanel()}
					editable ={{
						onRowDelete: oldData =>
							new Promise((resolve, reject) => {
								setTimeout( async () => {
								await DeleteUser({userEmail: oldData.email}).then( response => {
									if(response.status === "success"){
										let users = [...this.state.users]
										users.splice(oldData.tableData.id, 1)
										this.setState({
											users
										})
										resolve()
									}else{
										alert('Delete user error')
									}
									reject()
								} )
								reject()
								}, 1000)
							})
					}}
					/>

				<Dialog
					open={this.state.deleteClass}
					onClose={()=>{this.setState({ deleteClass: false, value: '' }) }}
					// aria-labelledby="draggable-dialog-title"
					// style={{width: '1000px'}}
					>
					<DialogTitle  >
						Alert
					</DialogTitle>
					<DialogContent>
						<DialogContentText1 style={{color:'red !important'}} >
						{/* <p style={{color:'red !important'}}> */}
							Are you sure you want to delete the class?
						{/* </p> */}
						</DialogContentText1>
					
					</DialogContent>
					<DialogActions>
						<Button style={{color:"black"}}  onClick={ () => {this.setState({ deleteClass: false, value: '' })}} color="secondary">
						NO
						</Button>
						<Button style={{color:"black"}} onClick={ () => {   this.deleteClass()  }} color="secondary">
						YES
						</Button>
					</DialogActions>
					</Dialog>

					</div>
			
				
		}
		
	</div>

	<div></div>

</div>
        )
    }
}


const select = appState => ({
    results: appState.User,
  })
  

export default connect(select)(withCookies(UserDetail))