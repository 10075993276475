import React from 'react'
import classes from './ChosseLevel.module.scss'
import {getLevel } from '../../route'
import {auth} from '../../../../Firebase/fireconfig'
import Menu from '../Menu/Menu'
class ChosseLevel extends React.PureComponent{
    constructor(props){
        super(props);
        this.state={
            show: true
        }
        this.logout = this.logout.bind(this) 
    }


    logout(){
        auth.signOut().then( () => {
            this.props.history.push('/login')
        } )
    }

    componentDidMount(){
        if(auth.currentUser)
        if(auth.currentUser.isAnonymous){
            this.setState({
                show: false
            })
        }
    }

    


    render(){
        return(
            <div className={classes.rtChosseLevel}>
                <button className={this.props.level === '00001' ? classes.active : classes.disable} onClick={this.props.onClick}> Level A </button>
                <button className={this.props.level === '00002' ? classes.active : classes.disable} onClick={this.props.onClick}> Level B </button>
                <button className={this.props.level === '00003' ? classes.active : classes.disable} onClick={this.props.onClick}> Level C </button>
                <button className={this.props.level === '00004' ? classes.active : classes.disable} onClick={this.props.onClick}> Songs </button>
                <button style={ this.state.show ? {display:'block'} : { display: 'none' } } className={this.props.level === '00005' ? classes.active : classes.disable} onClick={this.props.onClick}> Account </button>
                <Menu history = {this.props.history} styleShow={ this.state.show  } ></Menu>
            </div>
        )
    }
}

export default ChosseLevel