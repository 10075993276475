import { connect } from 'react-redux';
import { compose} from 'recompose';

import Header from './Header';

const mapStateToProps = state => ({
    data: state,
});

export default compose(
    connect(mapStateToProps),
)(Header);
