import React from 'react'
import classes from './Unit.module.scss'
import RenderBook from './renderBook/RenderBook'
import './Carousel.css'
import 'swiper/css/swiper.css'
import BackButton from '../../../BackArrow/BackArrow'
class Unit extends React.PureComponent{
    constructor(props){
        super(props);
        this.state={
            song: new Audio(),
            modules: [
            ],
            currentActive: null,
            loaded: false,
            renderBookInfo: {
                identifier: [],
                img: [],
                json: [],
                bg: [],
            },
            backgroung: '',
            timer: '',
            count: 1,
            timer: '',
            playHelpAudio: true,
        }
        
        this.setClass = this.setClass.bind(this)
        this.getImg = this.getImg.bind(this)
        this.getRenderBookInfo = this.getRenderBookInfo.bind(this)
        this.playAudio = this.playAudio.bind(this)
        this.timmer = this.timmer.bind(this)
    }

    async timmer(){
        if(this.state.playHelpAudio){
            this.state.count = this.state.count + 1
            let z = ''
            try {
                if(this.state.count % 2 === 0)
                {
                    const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/TutorialAudio/tutorial-main-2.mp3`);
                    z = src
                }
                else 
                {
                        const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/TutorialAudio/tutorial-main-4.mp3`);
                        this.state.playHelpAudio = false
                        z = src
                }
            }
            catch (err) 
            {
            }
            
                this.state.song.src = z
                this.state.song.play()
        }
    }

    componentWillUnmount()
    {
        clearInterval(this.state.timer)
        this.state.song.pause()
    }
    async getRenderBookInfo(){
        await this.setState({
            backgroung: this.props.location.state.background,
            renderBookInfo: this.props.location.state.info,
            modules: this.props.location.state.module,
            loaded: true
        })
        this.setClass(0)
        this.playAudio(this.props.location.state.unitName ,this.props.location.state.unitId)
    }
    async playAudio(words, unit){
        let word = words
        let z = ''
        word = word.toLowerCase()
        try {
            const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/ReadingTrain-Core/ReadingTrain-Core/Courses/${this.props.location.state.level}/${unit}/${word}.mp3`);
            z = src
          } catch (err) {
            this.setState({ err: err.toString() });
          }
        this.state.song.src = z  
        this.state.song.play();
    }

    async UNSAFE_componentWillMount(){
        this.state.timer = setInterval( async () =>  {
           this.timmer()
        },
        18000
        )
        await this.getRenderBookInfo()
        
        this.forceUpdate()
    }
    async getImg(){
        try {
            const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/ReadingTrain-Core/ReadingTrain-Core/Courses/${this.props.location.state.level}/${this.props.location.state.unit}/cover.jpg`);
        await this.setState({ src: src });
      } catch (err) {
      }
    }

    setClass(num, level = null, unit = null, book = null, json = null, picture = null, name = null, bg = null){
        clearInterval(this.state.timer)
        this.state.timer = setInterval( async () =>  {
            this.timmer()
         },
         18000
         )
        if(this.state.currentActive !== "")
        {
            if(this.state.currentActive === num)
            {
                this.props.history.push('/preview', {level: level, unit: unit, book: book, json: json, picture: picture, name: name, bg: bg})
                return
            } 
        }

        for(let i =0; i < this.state.modules.length; i++)
        {            
            document.getElementById(i).classList.remove('active')
            document.getElementById(i).classList.remove('halfVisibler')
            document.getElementById(i).classList.remove('quartVisibler')
            document.getElementById(i).classList.remove('halfVisiblel')
            document.getElementById(i).classList.remove('quartVisiblel')
            document.getElementById(i).classList.remove('dontVisible')
            if(i === num){
                document.getElementById(i).classList.add('active')
                this.setState({currentActive: num})
            }
            else
            if(i - 1 === num || i + 1 === num)
            {
                if(i-1 === num)
                document.getElementById(i).classList.add('halfVisibler')
                else
                document.getElementById(i).classList.add('halfVisiblel')
            }
            else
            if(i - 2 === num || i + 2 === num)
            {
                if(i-2 === num)
                document.getElementById(i).classList.add('quartVisibler')
                else
                document.getElementById(i).classList.add('quartVisiblel')
            }
            else{
                document.getElementById(i).classList.add('dontVisible')
            }
        }

    }
    
    render(){
        return(
            <div className={classes.bg}>
                <div className={classes.rtRender} style={{backgroundImage: "url("+this.state.backgroung+")"}}>   
                <div className={classes.img}>
                    {/* <img onClick={() => {this.props.history.goBack()}} style={{width:'32px', height:'32px', margin:'15px'}} alt='' src={BackButton} /> */}
                    <BackButton history = { this.props.history} />
                </div>
                {
                    this.state.loaded ?  <div className={classes.carousel}>
                    {this.state.renderBookInfo.identifier.map((data, i) =>{
                        let check
                        if(this.state.currentActive === null)
                        {
                            check = 0
                        }
                        else
                        {
                            check = this.state.currentActive
                        }
                        if(i === check){
                            return <RenderBook type='a' id={i} onClick={this.setClass} key={i} level={this.props.location.state.level} unit={this.props.location.state.unit} 
                                book={data} img={this.state.renderBookInfo.img[i]} bg= {this.state.renderBookInfo.bg[i]}  json={this.state.renderBookInfo.json[i]} />
                        }
                        else
                        if(i - 1 === check || i + 1 === check)
                        {
                            return <RenderBook type='h' id={i} onClick={this.setClass} key={i} level={this.props.location.state.level} unit={this.props.location.state.unit} 
                                book={data} img={this.state.renderBookInfo.img[i]} bg= {this.state.renderBookInfo.bg[i]}  json={this.state.renderBookInfo.json[i]} />
                        }
                        else
                        if(i - 2 === check || i + 2 === check)
                        {
                            return <RenderBook type='q' id={i} onClick={this.setClass} key={i} level={this.props.location.state.level} unit={this.props.location.state.unit} 
                                book={data} img={this.state.renderBookInfo.img[i]} bg= {this.state.renderBookInfo.bg[i]}  json={this.state.renderBookInfo.json[i]} />
                        }
                        else{
                            return <RenderBook type='a' id={i} onClick={this.setClass} key={i} level={this.props.location.state.level} unit={this.props.location.state.unit} 
                                book={data} img={this.state.renderBookInfo.img[i]} bg= {this.state.renderBookInfo.bg[i]}  json={this.state.renderBookInfo.json[i]} />
                        }
                            })}
                    </div> :
                    <h1 style={{margin:'0'}}>Loading.....</h1>
                }
                
                </div>
            </div>
        )
    }
}

export default Unit