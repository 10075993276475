import React from 'react'
import classes from './Listening.module.scss'
import {split} from "sentence-splitter";
import { ReactMic } from 'react-mic';
import AskUser from './AskUser/AskUser'
import MicRecorder from 'mic-recorder-to-mp3'
import icon from '../../Level/components/Unit/img/icon.png'
import BackButton from '../../BackArrow/BackArrow'
import {store, db, auth} from '../../../Firebase/fireconfig'
//download.js v3.0, by dandavis; 2008-2014. [CCBY2] see http://danml.com/download.html for tests/usage
// v1 landed a FF+Chrome compat way of downloading strings to local un-named files, upgraded to use a hidden frame and optional mime
// v2 added named files via a[download], msSaveBlob, IE (10+) support, and window.URL support for larger+faster saves than dataURLs
// v3 added dataURL and Blob Input, bind-toggle arity, and legacy dataURL fallback was improved with force-download mime and base64 support

// data can be a string, Blob, File, or dataURL

		 
						 
    
let stopLoop = ''
function download(data, strFileName, strMimeType) {
	console.log('download')
	var self = window, 
		u = "application/octet-stream", 
		m = strMimeType || u, 
		x = data,
		D = document,
		a = D.createElement("a"),
		z = function(a){return String(a);},
		
		
		B = self.Blob || self.MozBlob || self.WebKitBlob || z,
		BB = self.MSBlobBuilder || self.WebKitBlobBuilder || self.BlobBuilder,
		fn = strFileName || "download",
		blob, 
		b,
		ua,
		fr;

	if(String(this)==="true"){ //reverse arguments, allowing download.bind(true, "text/xml", "export.xml") to act as a callback
		x=[x, m];
		m=x[0];
		x=x[1]; 
	}
	
	
	
	//go ahead and download dataURLs right away
	if(String(x).match(/^data\:[\w+\-]+\/[\w+\-]+[,;]/)){
		return navigator.msSaveBlob ?  // IE10 can't do a[download], only Blobs:
			navigator.msSaveBlob(d2b(x), fn) : 
			saver(x) ; // everyone else can save dataURLs un-processed
	}//end if dataURL passed?
	
	try{
	
		blob = x instanceof B ? 
			x : 
			new B([x], {type: m}) ;
	}catch(y){
		if(BB){
			b = new BB();
			b.append([x]);
			blob = b.getBlob(m); // the blob
		}
		
	}
	
	
	
	function d2b(u) {
		var p= u.split(/[:;,]/),
		t= p[1],
		dec= p[2] == "base64" ? atob : decodeURIComponent,
		bin= dec(p.pop()),
		mx= bin.length,
		i= 0,
		uia= new Uint8Array(mx);

		for(i;i<mx;++i) uia[i]= bin.charCodeAt(i);

		return new B([uia], {type: t});
	 }
	  
	function saver(url, winMode){
        if ('download' in a) { //html5 A[download] 
			a.href = url;
			a.setAttribute("download", fn);
            a.innerHTML = "downloading...";
            
            D.body.appendChild(a);
            
            setTimeout(function() {
                a.click();
				D.body.removeChild(a);
				if(winMode===true){setTimeout(function(){ self.URL.revokeObjectURL(a.href);}, 250 );}
			}, 66);
			return true;
		}
		
		//do iframe dataURL download (old ch+FF):
		var f = D.createElement("iframe");
		D.body.appendChild(f);
		if(!winMode){ // force a mime that will download:
			url="data:"+url.replaceAll(/^data:([\w\/\-\+]+)/, u);
		}
		 
	
		f.src = url;
		setTimeout(function(){ D.body.removeChild(f); }, 333);
		
	}//end saver 
		

	if (navigator.msSaveBlob) { // IE10+ : (has Blob, but not a[download] or URL)
		return navigator.msSaveBlob(blob, fn);
	} 	
	
	if(self.URL){ // simple fast and modern way using Blob and URL:
		saver(self.URL.createObjectURL(blob), true);
	}else{
		// handle non-Blob()+non-URL browsers:
		if(typeof blob === "string" || blob.constructor===z ){
			try{
				return saver( "data:" +  m   + ";base64,"  +  self.btoa(blob)  ); 
			}catch(y){
				return saver( "data:" +  m   + "," + encodeURIComponent(blob)  ); 
			}
		}
		
		// Blob but not URL:
		fr=new FileReader();
		fr.onload=function(e){
			saver(this.result); 
		};
		fr.readAsDataURL(blob);
	}	
	return true;
} /* end download() */
class Lintening extends  React.PureComponent
{
    constructor(props)
    {
        super(props);
        this.state ={
            currentPage: 0,
            currentSentence: [],
            song: new Audio(),
            pageTurningTime: [],
            songTime: 0,
            record: true,
            delay: true,
            blob: '',
            askUser: false,
            recorder: new MicRecorder({bitRate: 128}),
            file: '',
            loop: '',
            next: true,
            blobUrl: '',
            readyUploaded: false
        }
        this.getSentence = this.getSentence.bind(this)
        this.NextImg = this.NextImg.bind(this)
        this.onData = this.onData.bind(this)
        this.onStop = this.onStop.bind(this)
        this.UserCheck = this.UserCheck.bind(this)
        this.finishMusic = this.finishMusic.bind(this)
    }

    finishMusic(ev){

    }

    UserCheck(ev){
        switch(ev.target.name){
            case 'download':{
                download(this.state.blob.url, "YourMusic.mp3", "music/mp3");
            }

            case 'play':{

            }

            case 'exit':{

            }
            default:{
                console.log('error')
            }
        }
    }

    async NextImg(){
        if(this.state.currentPage + 1 < this.props.location.state.json.pages.length)
        {
            let sentences = split(this.props.location.state.json.pages[this.state.currentPage + 1].text);
            this.setState({
                currentSentence: sentences,
                currentPage: this.state.currentPage + 1,
                songTime: this.state.song.currentTime, 
            })
            
        }
        else
        {
            this.state.song.pause()
            this.setState({
                next: false
            })
            if(this.props.location.state.type === 'record'){
            clearTimeout(stopLoop)
            this.state.recorder.stop().getMp3()    
                .then(async ([buffer, blob]) => {

                    if(!this.state.readyUploaded){
                        const file = new File(buffer, 'music.mp3', {
                        type: blob.type,
                        lastModified: Date.now()
                        });
                        
                        const ref = store.ref();
                        const name = (+new Date()) + '-' + file.name;
                        const metadata = {
                        contentType: file.type
                        };
                        if(!auth.currentUser.isAnonymous){
                            const task = ref.child(name).put(file, metadata);
                            task
                            .then(snapshot => snapshot.ref.getDownloadURL())
                            .then((url) => {
                                console.log(url)
                                let user_data = {
                                    url: url,
                                    times: {
                
                                    },
                                    user: auth.currentUser.email,
                                    book: this.props.location.state.book
                                }
                                let doc = new Date().getTime()
                                console.log(doc)
                                db.collection('user_records').doc(doc.toString()).set(user_data)
                            })
                            .catch(console.error);
                        }


                        this.setState({
                            blobUrl:  URL.createObjectURL(blob)
                        })
                        document.getElementById('em').style.zIndex = 1
                        document.getElementById('ema').style.display = 'block'
                        document.getElementById('text').style.display = 'none'
                  }}).catch((e) => {
                    console.error(e);
                  });
            }
            else{
            this.props.history.goBack()
            }
        }
    }
    getSentence()
    {
        let sentences = split(this.props.location.state.json.pages[this.state.currentPage].text);
        this.setState({
            currentSentence: sentences
        })
    }
    async UNSAFE_componentWillMount(){
        let pictureChangTimer = []
        for(let i = 0; i < this.props.location.state.json.pages.length; i++)
        {
            pictureChangTimer.push(this.props.location.state.json.pages[i].pageTurningTime)
        }
        await this.setState({
            pageTurningTime: pictureChangTimer
        })
        this.getSentence() 
        if(this.props.location.state.type === 'record')
        {
             this.state.recorder.start().then(() => {
           
                }).catch((e) => {
                    console.error(e);
                });
                }
       
    }
    
    
  

    componentDidUpdate(){
        this.state.song.src = this.props.location.state.mp3
        this.state.song.currentTime = this.state.songTime
        if(this.state.next)
        {
            this.state.song.play()
        }
        let timer = 0
        if(this.state.currentPage > 0)
        {
            timer =  parseInt(this.state.pageTurningTime[this.state.currentPage])*1000  - parseInt(this.state.pageTurningTime[this.state.currentPage - 1])*1000

        }
        else
        {
            timer =  parseInt(this.state.pageTurningTime[this.state.currentPage])*1000 
        }
        if(this.state.next)
        {
            stopLoop = setTimeout(this.NextImg, timer)
        }
        else{
            clearTimeout(stopLoop)
        }
    }
    onData(recordedBlob) {
        console.log('chunk of real-time data is: ', recordedBlob);
    }
     
    onStop(recordedBlob) {
        this.setState({
            blob: recordedBlob,
            askUser: true
        })
        this.state.song.src = URL.createObjectURL(recordedBlob).replaceAll(`"`,'').replaceAll(`"`,'')
        this.state.song.play()
        download(URL.createObjectURL(recordedBlob).replace(`"`,'').replace(`"`,''), "YourMusic.mp3", "audio/webm;codecs=opus");
    }

    async stop(){
        await this.setState({
            record: false,
        })
        this.forceUpdate()
        await this.setState({
            delay: false
        })
    }

    async componentWillUnmount(){
        this.stop()
        this.state.song.pause()
        await this.setState({
            record: false,
        })
        this.forceUpdate()
    }
    render()
    {
        return(
            <div className={classes.bg}>
                <a style={{display:'none'}} id='download'download ></a>
                <div id='em' className={classes.askUser}>
                <audio id='ema' style={{margin:'auto', marginTop:'450px', display:'none', width:'500px'}} ref="audio_tag" src={this.state.blobUrl} controls />
                    {/* {this.state.askUser ? <AskUser /> : <AskUser />} */}
                    {/* <div className={classes.img}> */}
                    {/* <img id='arrow' onClick={() => {this.props.history.goBack()}} style={{width:'32px', height:'32px', margin:'15px', position:'absolute', top:"20px", left:'20px', zIndex:'10'}} alt='' src={icon} /> */}
                {/* </div> */}
                </div>
                <div className={classes.ListenignUp}>
                <div style={{position:'relative'}}> 
                 <BackButton history={this.props.history} />
                </div>
                    <div className={classes.ListenignDown}>
                        <div style={{height:'360px'}} className={classes.img}>
                            <img alt='' src={this.props.location.state.img1[this.state.currentPage]} />
                        </div>
                        <div style={{height:'10px'}}></div>
                        <div id='text' className={classes.text}>
                            {this.state.currentSentence.map(data => {
                                if(data.type === 'Sentence')
                                {
                                    return <div className={classes.text}> {data.raw} </div>
                                }
                                else
                                {
                                    return <div className={classes.clear} />
                                }
                                
                            })}
                        </div>
                    </div>
                </div>
                
            </div>
            
        )
    }
}

export default Lintening