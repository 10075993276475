import React from 'react'
import classes from './BooksPreview.module.scss'
import Info from './components/BookInfo/BookInfo'
import ModeButton from './components/ModeButton/ModeButton'
import { BoxLoading } from 'react-loadingg';
import count from '../../countWidthHeight'
import Quid from '../BooksPage/components/Quit/Quid'
import BackButton from '../../../BackArrow/BackArrow'
import {db, auth} from '../../../../Firebase/fireconfig'
class BooksPreview extends React.PureComponent{
    constructor(props){
        super(props);
        this.state={
            info: false, //must be false
            song: new Audio(),
            level: 0,
            module: 0,
            book: 0,
            loadPage: false,
            width: '',
            height: '',
            count: 0,
            timer: '',
            showButton: 3,
            numberCounts: 0,
            playHelpAudio: true
        }

        this.hideInfo = this.hideInfo.bind(this);
        this.playTitle = this.playTitle.bind(this);
        this.ModeButtonClick = this.ModeButtonClick.bind(this)
        this.preloadBook = this.preloadBook.bind(this)
    }


    async timmer(){
        if(!this.state.info && this.state.playHelpAudio){
            let z = ''
            try {
    
                switch(this.state.count){
                    case 0: {
                        this.state.numberCounts = this.state.numberCounts + 1 
                        if(this.state.numberCounts === 1){
                            this.state.count = 1
                            const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/TutorialAudio/tutorial-book-7.mp3`);
                            z = src
                            break
                        }else{
                            this.state.count = 5
                            clearInterval(this.state.timer)
                        }
                    }
                    case 1: {
                        this.state.numberCounts = this.state.numberCounts + 1 
                        this.state.count = 2
                        const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/TutorialAudio/tutorial-book-1.mp3`);
                        z = src
                        break
                    }
                    case 2: {
                        this.state.numberCounts = this.state.numberCounts + 1 
                        this.state.count = 3
                        const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/TutorialAudio/tutorial-book-9.mp3`);
                        z = src
                        break
                    }  
                    case 3: {
                        this.state.numberCounts = this.state.numberCounts + 1 
                        this.state.count = 0
                        const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/TutorialAudio/tutorial-book-8.mp3`);
                        z = src
                        this.state.playHelpAudio = false
                        break
                    }  
                    case 5:{
                        clearInterval(this.state.timer)
                    }
                }
                }
            catch (err) 
            {
            }
            if(this.state.count === 5){
                clearInterval(this.state.timer)
            }
            if(this.state.count !== 5){
                this.state.song.src = z
                this.state.song.play()
            }
        }
}

    hideInfo(){
        this.setState({
            info: false,
        })
    }

    componentWillUnmount()
    {
        clearInterval(this.state.timer)
        this.state.song.pause()
    }
    
    UNSAFE_componentWillMount(){
        db.collection('user_records').where('user', '==', auth.currentUser.email).where('book', '==', this.props.location.state.book).get().then( response => {
			response.forEach( data => {
				let data1 = data.data()
                this.setState({
                    recordedData: data1,
                    showButton: 4
                })
                this.forceUpdate()                
			} )
		} )
        this.state.timer = setInterval( async () =>  {
            this.timmer()
         },
         18000
         )
        this.playTitle(this.props.location.state.name)
        let img = new Image()
        img.src  = this.props.location.state.picture
        img.onload = () => {
            let size = count(img.naturalWidth, img.naturalHeight, 650 , 400)
            this.setState(
                {
                    height: size.countH,
                    width: size.countW
                }
            )
        }
    }
    
    async playTitle(e){
        let text = e
        text = text.toLowerCase()
        while(text.slice(text.length-1, text.length) === ' ')
        {
            text  = text.slice(0, text.length-1);
        }
        while(text.slice(0, 1) === ' ')
        {
            text  = text.slice(1, text.length);
        }
        text = text.replaceAll('?', '')
        text = text.replaceAll('.', '')
        text = text.replaceAll('!', '')
        text = text.replaceAll(',', '')
        // text = text.slice(1, -1)
        try {
            const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/ReadingTrain-Core/ReadingTrain-Core/Courses/${this.props.location.state.level}/${this.props.location.state.unit}/${this.props.location.state.book}/${text}.mp3`);
            this.state.song.src = src
            this.state.song.play();
      } catch (err) {
        this.setState({ err: err.toString() });
      }
    }

    async ModeButtonClick(text){
        switch(text){
            case 'Listen':{
                clearInterval(this.state.timer)
                this.state.song.pause()
                this.setState({
                    loadPage: true
                })
                let info = await this.preloadBook(true)
                this.props.history.push('/bookspage', {state: this.props.location.state, recording: false,playback: false, listen: true, bg: this.props.location.state.bg, info: info })
                break;
            }
            case 'Read': {
                clearInterval(this.state.timer)
                this.state.song.pause()
                this.setState({
                    loadPage: true
                })
                let info = await this.preloadBook(false)
                this.props.history.push('/bookspage', {state: this.props.location.state, recording: false, playback: false, listen: false, bg: this.props.location.state.bg, info: info})
                break
            } 
            case 'Record': {
                clearInterval(this.state.timer)
                this.state.song.pause()
                this.setState({
                    loadPage: true
                })
                let info = await this.preloadBook(false)
                this.props.history.push('/bookspage', {state: this.props.location.state, recording: true,playback: false, listen: false, bg: this.props.location.state.bg, info: info})
                break
            }
            case 'Playback':{
                clearInterval(this.state.timer)
                this.state.song.pause()
                this.setState({
                    loadPage: true
                })
                let info = await this.preloadBook(true)
                this.props.history.push('/bookspage', {recordedData: this.state.recordedData, state: this.props.location.state, recording: false, listen: false, playback: true, bg: this.props.location.state.bg, info: info })
                break;
            }
            default:{
            }
        }
    }

    async preloadBook(listen){
        let info = {
            img: [],
            text: [],
            mp3: [],
        }
        //parse all img for our book
        for(let i = 0; i < this.props.location.state.json.pages.length; i++)
        {
            try {
                const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/ReadingTrain-Core/ReadingTrain-Core/Courses/${this.props.location.state.level}/${this.props.location.state.unit}/${this.props.location.state.book}/${this.props.location.state.json.pages[i].assets[1].imageFilename}`);
                info.img.push(src)
            } catch (err) {
            this.setState({ err: err.toString() });
            }
            let s = this.props.location.state.json.pages[0].assets[0].name+ ' '
            if(i !== 0)
            {
                s = this.props.location.state.json.pages[i].assets[0].text.slice(3,-4);
                let words = s.split(" ");
                let CopyWords = words.slice(0, words.length)
                words = []
                CopyWords.map(data => {
                    words.push(data)
                    if(!data.includes('"')){
                        if(data.includes('.'))
                        {
                            words.push('')
                        }
                        if(data.includes('?'))
                        {
                            words.push('')
                        }
                        if(data.includes('!'))
                        {
                            words.push('')
                        }
                    }
                    return 0;
                    })
                info.text.push(words)
            }
            if(listen)
                {
                    switch(this.props.location.state.level){
                        case '00001': {
                            let word = s
                            let z = []
                            word = word.toLowerCase()
                            word  = word.slice(0, -1);
                            word = word.replaceAll('.', '')
                            word = word.replaceAll('"', '')
                            word = word.replaceAll(',', '')
                            word = word.replaceAll('', '')
                            try {
                                const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/ReadingTrain-Core/ReadingTrain-Core/Courses/${this.props.location.state.level}/${this.props.location.state.unit}/${this.props.location.state.book}/${word}.mp3`);
                                z.push(src)
                            } catch (err) {
                                this.setState({ err: err.toString() });
                            }
                            info.mp3.push(z)
                            break;
                        }
                        case '00002': {
                            let word = s
                            let firstSentence = word.slice(0, word.indexOf('.')).toLowerCase().replaceAll(',','').replaceAll('.', '').replaceAll('"', '').replaceAll('!', '')
                            console.log('firstSentence',firstSentence)
                            let secondSentence = word.slice(word.indexOf('.')+2, word.length - 1).toLowerCase().replaceAll(',', '').replaceAll('.', '').replaceAll('"', '').replaceAll('!', '')
                            let mp3 = []
                            try {
                                const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/ReadingTrain-Core/ReadingTrain-Core/Courses/${this.props.location.state.level}/${this.props.location.state.unit}/${this.props.location.state.book}/${firstSentence}.mp3`);
                                mp3.push(src)
                            } catch (err) {
                                // this.setState({ err: err.toString() });
                            }

                            try {
                                const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/ReadingTrain-Core/ReadingTrain-Core/Courses/${this.props.location.state.level}/${this.props.location.state.unit}/${this.props.location.state.book}/${secondSentence}.mp3`);
                                mp3.push(src)
                            } catch (err) {
                            }
                            info.mp3.push(mp3)
                            break;
                        }
                        case '00003': {
                            let word = s
                            let firstSentence = word.slice(0, word.indexOf('.')).toLowerCase().replaceAll(',','').replaceAll('.', '').replaceAll('"', '').replaceAll('!', '')
                            word = word.slice(word.indexOf('.')+2, word.length - 1).toLowerCase().replaceAll(',', '').replaceAll('"', '').replaceAll('!', '')
                            
                            let secondSentence = word.slice(0, word.indexOf('.')).toLowerCase().replaceAll(',','').replaceAll('.', '').replaceAll('.', '').replaceAll('"', '').replaceAll('!', '')
                            let thirdSentence = word.slice(word.indexOf('.')+2, word.length).toLowerCase().replaceAll(',', '').replaceAll('.', '').replaceAll('"', '').replaceAll('!', '')

                            let mp3 = []
                            try {
                                const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/ReadingTrain-Core/ReadingTrain-Core/Courses/${this.props.location.state.level}/${this.props.location.state.unit}/${this.props.location.state.book}/${firstSentence}.mp3`);
                                mp3.push(src)
                            } catch (err) {
                                // this.setState({ err: err.toString() });
                            }

                            try {
                                const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/ReadingTrain-Core/ReadingTrain-Core/Courses/${this.props.location.state.level}/${this.props.location.state.unit}/${this.props.location.state.book}/${secondSentence}.mp3`);
                                mp3.push(src)
                            } catch (err) {
                            }

                            try {
                                const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/ReadingTrain-Core/ReadingTrain-Core/Courses/${this.props.location.state.level}/${this.props.location.state.unit}/${this.props.location.state.book}/${thirdSentence}.mp3`);
                                mp3.push(src)
                            } catch (err) {
                            }
                            info.mp3.push(mp3)
                            break;
                        }
                        default: {
                        }
                    }
                    
                }
            }
            info.mp3.push([])
            info.text.push([])
            await this.setState({
                pageData: info
            })
            return info
    }

    render(){
        return(
            <div className={classes.bg}>
            <div className={classes.rtBooksPreview} style ={ { backgroundImage: "url("+this.props.location.state.bg+")" } }>
                <div style={this.state.loadPage ? {backgroundColor:'rgba(50, 50, 50, 0.8)', position:'fixed', width:'1000px', height:'693px', zIndex:"100"} : {opacity:'0'}}>

                <BoxLoading />

                </div>
                <div className={classes.quid}>
                    <BackButton history={this.props.history}/>
                    {/* <Quid history = {this.props.history}/> */}
                </div>
                <div className={classes.info} onClick={() => {
                    this.state.song.pause()
                    this.setState({info: true})}} > 
                    <img alt='Info button' src={require('./img/info.png')} />
                 </div>
                 {this.state.info ? <Info classes='show' hide={this.hideInfo} text ={this.props.location.state.json.introduction} level= {this.props.location.state.level}
                    unit = {this.props.location.state.unit} book ={ this.props.location.state.book}
                 /> : <Info hide={this.hideInfo} classes='hide' />}
                <div className={classes.bookIntro}>
                    <div className={classes.title}> {this.props.location.state.json.name} </div>    
                    <img style={{width:''+this.state.width+'px', height:''+ this.state.height+ 'px'}} alt='' src={this.props.location.state.picture} />
                </div>
                <div className={classes.bookMode}> 
                {[...Array(this.state.showButton)].map((x, i) =>
                    <ModeButton onClick={this.ModeButtonClick} index={i} key={i} />
                )}
                </div>
                
            </div>
            </div>
        )
    }
}

export default BooksPreview;