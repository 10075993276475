import { connect } from 'react-redux';
import { compose} from 'recompose';

import Unit from './Unit';

const mapStateToProps = state => ({
    data: state,
});

export default compose(
    connect(mapStateToProps),
)(Unit);
