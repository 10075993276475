import React from 'react'
import classes from './Quid.module.scss'
import triangle from '../../../../../../source/Images/menus/arrow-down@2x.png'
import exit from '../../../../../../source/patterns/exit.png'
class Quid extends React.PureComponent {
    constructor(props){
        super(props);
        this.state={
            show: true,
            modeImg: [
                require('../../../BooksPreview/img/read.png'),
                require('../../../BooksPreview/img/listen.png')
            ],
            back: [
                require('../../../../../../source/patterns/lesson-menu@2x1.png'),
                require('../../../../../../source/patterns/lesson-menu.png')
            ]

        }

        this.exit = this.exit.bind(this)
        this.show = this.show.bind(this)
    }

    async show(){
        await this.setState({
            show: !this.state.show
        })
        if(!this.state.show)
        {
            document.getElementById('quid').style.height = '200px'
            document.getElementById('quid').style.backgroundImage =  this.state.back[1]
        }   
        else {
            document.getElementById('quid').style.height = '100px'
            document.getElementById('quid').style.backgroundImage = this.state.back[0]
        }
        
    }

    exit(ev){
        ev.stopPropagation()
        this.props.history.goBack();
    }

    render(){
        return(
            <div onClick={this.show} id='quid' className={classes.Quid}>
         
                {this.state.show ?  <img alt='' className={classes.close} src={triangle} /> :
                    <div className={classes.quidOpen}> 
                        <img alt='' src = {this.props.mode ? this.state.modeImg[1] : this.state.modeImg[0]} className={classes.mode} />
                        <img alt='' onClick = {this.exit} src={exit} className={classes.quit} />
                        <img alt='' src={triangle} className={classes.triangle} />
                    </div>
                }
                
            </div>
        )
    }
}

export default Quid