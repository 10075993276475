import React from 'react'
import classes from  './RenderBook.module.scss'
import count from '../../../../Books/countWidthHeight'
import '../../../../Level/components/LevelRender/spiner.css'
import FittedImage from 'react-fitted-image'
import { LazyLoadComponent } from 'react-lazy-load-image-component';
class RenderBook extends React.PureComponent {
    constructor(props){
        super(props);
        this.state={
            src:'',
            json: {},
        }
        this.click = this.click.bind(this)
    }

    async click(){
        let src1
        let plist = require('plist');
        let module
        try {
            const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../../source/patterns/pattern-${Math.random(25)}.png`);
            src1 = src
            
        } catch (err) {
        
      }
      try {
        const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../../source/ReadingTrain-Core/ReadingTrain-Core/Courses/${this.props.level}/${this.props.unit}/${this.props.book}/module.js`);
        module = await plist.parse(src)
      } catch (err) {
      }
        this.props.history.push('/preview', {
            level: this.props.level, 
            unit: this.props.unit, 
            book: this.props.book, 
            json: module, 
            picture: this.state.src, 
            name: module.name, 
            bg: src1
        })
    }

    async getImg(){
        try {
            const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../../source/ReadingTrain-Core/ReadingTrain-Core/Courses/${this.props.level}/${this.props.unit}/${this.props.book}/0.jpg`);
        
            await this.setState({ src: src });
        } catch (err) {
        this.setState({ err: err.toString() });
      }
    }
    async UNSAFE_componentWillMount(){
        let widthModify = ''
        let heightModify = ''
        switch(this.props.type){
            case 'a':
                {
                    widthModify = 0.2368
                    heightModify = 0.362
                    break;
                }
            case 'h':
                {
                    widthModify = 0.1801
                    heightModify = 0.298
                    break;
                }
            case 'q':
                {
                    widthModify = 0.1234
                    heightModify = 0.17
                    break;
                }
            default:
                {
                    widthModify = 0.1234
                    heightModify = 0.17
                    break;
                }
        }
        let src1
        let plist = require('plist');
        let module
        try {
            const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../../source/patterns/pattern-${Math.random(25)}.png`);
            src1 = src
            
        } catch (err) {
        
      }
      try {
        const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../../source/ReadingTrain-Core/ReadingTrain-Core/Courses/${this.props.level}/${this.props.unit}/${this.props.book}/module.js`);
        module = await plist.parse(src)
      } catch (err) {
      }
        this.setState({
            level: this.props.level, 
            unit: this.props.unit, 
            book: this.props.book, 
            json: module, 
            picture: this.state.src, 
            name: module.name, 
            bg: src1
        })
        await this.getImg()
        let img = new Image()
        img.src  = this.state.src
        img.onload = () => {
            let size = count(img.naturalWidth, img.naturalHeight, window.innerWidth*parseFloat(widthModify) , window.innerHeight*parseFloat(heightModify))
            this.setState(
                {
                    height: size.countH,
                    width: size.countW
                }
            )
        }
        
        this.forceUpdate()
    }

    

    render(){
        return(
            <div  
            onClick={ () => { this.click()} }
            className={classes.rtRenderBook} 
            >
                <div className={classes.leftSide}> </div>
                <div className={classes.mainSide} id='mainSide' >
                    <div className={classes.mainInfo}>
                        <div className={classes.title}>  </div>
                        <LazyLoadComponent>
                        <div style={{height:'auto', maxHeight:'100%', display:'flex'}}>
                        <FittedImage
                            fit="auto"
                            loader={<div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>}
                            onLoad={(...args) => console.log(...args)}
                            onError={(...args) => console.log(...args)}
                            src={this.state.src}
                            />
                        {/* <img style={{maxWidth:'80%', maxHeight:'50%'}}  src={this.props.img} /> */}
                            </div>
                        </LazyLoadComponent>
                        <div>
                        {this.state.json.name}
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }
}

export default RenderBook