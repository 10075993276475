import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import infoAll from './info'
import quiz from './quiz'
import allData from './AllData'
const rootReducer = history =>
    combineReducers({
        infoAll,
        quiz,
        router: connectRouter(history),
        allData,
    });

export default rootReducer;
