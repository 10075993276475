import React from 'react'
import classes from './FinalPage.module.scss'
import Shema from './components/Shema/Shema'
import Exclusive from '../BooksPage/components/ExclusiveWord/ExclusiveWord'
import Info from './components/BookMain/BookInfo'
import nextImg from '../../../../source/Images/menus/book-page-next@2x.png'
import Quid from '../BooksPage/components/Quit/Quid'
class FinalPage extends React.PureComponent {
    constructor(props){
        super(props)
        this.state ={
            src: '',
            exclusiveShow: false,
            word:  ``,
            info: false,
            timer: '',
            song: new Audio,
            count: 1,
            playAudio: true
        }

        this.showInfo = this.showInfo.bind(this)
        this.hide = this.hide.bind(this)
        this.hideInfo = this.hideInfo.bind(this)
    }   

    async timmer(){
        if(this.state.playAudio){
            clearInterval(this.state.timer)
            this.state.count = this.state.count + 1
            let z = ''
            try {
                if(!this.state.info && !this.state.exclusiveShow ){
                    if(this.state.playAudio){
                        if(this.state.count % 2 === 0)
                        {
                            const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/TutorialAudio/tutorial-book-10.mp3`);
                            z = src
                        }
                        else{
                            const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/TutorialAudio/tutorial-book-8.mp3`);
                            this.state.playAudio = false
                            z = src
                        }
                    }
                }
                }
            catch (err) 
            {
            }
                this.state.song.src = z
                this.state.song.play()
        }
}

        hideInfo(){
            this.setState({
                info: false
            })
        }

        hide(){
        this.setState(
            {
                exclusiveShow: false,
                infoShow: false,
            }
        )
    }

    async UNSAFE_componentWillMount(){
        this.state.timer = setInterval( async () =>  {
            this.timmer()
         },
         12000
         )
    }

    componentWillUnmount(){
        this.state.song.pause()
        clearInterval(this.state.timer)
    }

        showInfo(ev){
        this.state.song.pause()
        clearInterval(this.state.timer)
            this.setState({
                exclusiveShow: true,
                word: ev.target.getAttribute("text")
            })
        }
   

    render(){
        return(
            <div className={classes.bg}>
                <div className={classes.rtFinalPage} style ={ { backgroundImage: "url("+this.props.location.state.bg+")" } }>
                    <div className={classes.quid}>
                        <Quid mode = {this.props.location.state.listen} history = {this.props.history} />
                    </div>
                    <div className={classes.prev} onClick={() => {this.props.history.goBack()}} > <img alt='Prev' src={nextImg}/> </div>
                    <div className={classes.main}>
                    { this.state.exclusiveShow ? <Exclusive padding='10px' words ={this.props.location.state.shemaData} text = {this.state.word} hide ={this.hide} /> : <div />}
                    <div className={classes.title}> 
                    <div className={classes.titleText}>
                        New Words 
                    </div>
                    </div>
                    
                    <div className={classes.schema}>
                        {this.props.location.state.pages.schema.map((data, i) =>
                        {
                            return <Shema show = {this.showInfo} text={data} key={i} />
                        })}
                    </div>
                    
                    {this.state.info ? <Info classes='show' hide={this.hideInfo} text ={this.props.location.state.pages.mainIdea} level= {this.props.location.state.level}
                        unit = {this.props.location.state.unit} book ={ this.props.location.state.book}
                    /> : <Info hide={this.hideInfo} classes='hide' />}
                    </div>

                    <div className={classes.next} onClick={() =>{this.props.history.push('/test', {pages: this.props.location.state.pages, 
                    book:this.props.location.state.book,
                    })}}> <img alt='Next' src={nextImg}/>  </div>
                    <div className={classes.info} onClick={() => {
                        this.state.song.pause()
                        this.setState({info: true})}} > 
                        <img alt='Info button' src={require('../BooksPreview/img/info.png')} />
                    </div>
                </div>
            </div>
        )
    }

}

export default FinalPage