import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux'
import { withCookies, Cookies } from 'react-cookie';
import ReactCodeInput from 'react-verification-code-input';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import classes from './UserDetail.module.scss'
import {Doughnut} from 'react-chartjs-2';
import { db, auth } from '../../../../../Firebase/fireconfig'
import {CircleFadeLoader} from 'react-loaders-kit';
import RenderBook from '../renderBook/RenderBook'
import coins from '../../../../../source/Images/menus/coin-music@2x.png'
const TextField2 = withStyles({
    root: {
      borderRadius: 3,
      border: 0,
      color: 'white',
      padding: '0 30px',
      boxShadow: '0 3px 5px 2px rgba(100, 87, 88, .3)',
      padding:0,
      margin: '6px auto 3px auto',
      '& label.Mui-focused': {
        color: 'white',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#FFDEE0',
      },
      '& .MuiInputLabel-formControl':{
        // top: -15
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#FFDEE0',
        },
      },
    },
   
  })(TextField);
const DialogContentText1 = withStyles({
	root: {
	  color:'red !important'
	  
	},
   
  })(DialogContentText);
class UserDetail extends React.PureComponent{
    constructor(props){
		super(props)
		this.FA2Ref = React.createRef();
        this.state = {
			errorMessage: { show: false, error: '' },
			data: {
				labels: [
					'False answer',
					'Correct answer',
				],
				datasets: [{
					data: [1, 1],
					backgroundColor: [
						'red',
						'green',
					],
					hoverBackgroundColor: [
						'red',
						'green',
					]
				}]
			},
			Book: {
				labels: [
					'All books',
					'Read books',
				],
				datasets: [{
					data: [326,  0],
					backgroundColor: [
						'red',
						'green',
					],
					hoverBackgroundColor: [
						'red',
						'green',
					]
				}]
			},
			loading: true,
			allBoks: 326,
			user: ''
		}
		this.renderBook = this.renderBook.bind(this)
	}

	async renderBook(data){
		
	}

	componentDidMount(){
		let tag = 'child_user'
		db.collection(tag).where('email', '==', this.props.email).get().then( response => {
			response.forEach( data => {
				let user = data.data()
				if(user.tag === 'child'){
					if(!user.correctAnswer){
						user['correctAnswer'] = 0
					}
					if(!user.falseAnswer){
						user['falseAnswer'] = 0
					}
					if(user.readedBook){
						user['bookReaded'] = Object.values(user.readedBook).length 
						user['bookList'] = Object.values(user.readedBook)
					}else{
						user['bookReaded'] = 0
					}
					this.state.data.datasets[0].data = [user['falseAnswer'], user['correctAnswer']]
					this.state.Book.datasets[0].data[1] = user['bookReaded']
					this.setState({
						user: user,
						loading: false
					})
					this.forceUpdate()
				}
			} )
		} )
		
	}


    render(){
		
		const loaderProps = {
            colors: ['red', 'black', 'red', 'black']
        }
        return(
            <div className={classes.bg} style={{minHeight:'100vh'}}>
	<div>
		{
			this.state.loading ?
			<div style={{
				width: '100px', height: '100px', position: 'sticky'
				, top: '50%',
				left: '50%',
				marginTop: '30vh',
				marginLeft: '40vw', minHeight: '100vh'
			}}><CircleFadeLoader size={50} loading={this.state.loading} color="black"  {...loaderProps} /></div> :
				<div >
					<div style={{display:'flex', justifyContent:'space-between'}}>
					<div> 
						<div className={classes.statBlock}> Student Answers </div>
						{/* <Doughnut data={this.state.data} /> */}
						<div className={classes.statBlock}>
						{this.state.data.datasets[0].data[1]} / {this.state.data.datasets[0].data[0] + this.state.data.datasets[0].data[1]}
						</div>
					</div>
					<div> 
						<div className={classes.statBlock}> Student Books Readed </div>
						<div className={classes.statBlock}>
						{this.state.Book.datasets[0].data[1]}
						</div>
					</div>
					<div style={{width:'300px', textAlign:'center'}}>
						<div style={{width:'300px'}} className={classes.statBlock}> Student Coins: {this.state.user.coins} </div>
						<img alt='' style={{width:'130px', marginTop:'30px'}} src={coins} />
					</div>
					</div>
					<div  className={classes.unit} style={{marginTop:'45px'}}>
						{this.state.user.bookList ? this.state.user.bookList.map( data => {
						 let array = data.split('-')
						 let img = ''
					   return(<div>
						  <RenderBook history={this.props.history} level={array[0]} unit={array[0]+'-'+array[1]} 
												 book={array[0]+'-'+array[1] +'-'+ array[2]}/>
					   </div>)
						} ) : <div />}
					</div>
				</div>
		}
		
	</div>

	<div></div>

</div>
        )
    }
}


const select = appState => ({
    results: appState.User,
  })
  

export default connect(select)(withCookies(UserDetail))