import React from 'react'
import classes from './AskUser.module.scss'

class AskUser extends React.PureComponent{
    constructor(props){
        super(props)
        this.state={

        }
    }

    render(){

        return(
            <div className={classes.AskUser}>
                <div className={classes.mainSide}>
                    <button name='download' > Download </button>
                    <button name='play'> Play </button>
                    <button name='exit'> Exit </button>
                </div>
            </div>
        )
    }
}

export default AskUser