import React from 'react'
import classes from './Input.module.scss'

class Input extends React.PureComponent{
    constructor(props){
        super(props);
        this.state = {

        }
    }

    render(){

        return(
            <div className={classes.input} style={this.props.customStyle ? this.props.customStyle : {}}>
                <div className={classes.title}>
                    <div className={classes.text}> {this.props.title} </div>
                    <div className={classes.help}></div>
                </div>
                {
                this.props.type === 'input' ? <input id={this.props.name}  value={this.props.value} onChange={(ev) => {this.props.onChange(ev, 'input')} } name={this.props.name} placeholder={this.props.placeholder} /> : 
                <select id={this.props.name} onChange={(ev) => {this.props.onChange(ev, 'select')} } name={this.props.name} required>
                    <option value="" disabled selected hidden>{this.props.placeholder}</option>
                    {this.props.option.map(data => {
                        return <option value={data}>{data}</option>
                    })}
                </select>
                }


            </div>
        )
    }
}

export default Input