import React from 'react'
import classes from './Shema.module.scss'
// import z from '../../../../../../'
class Shema extends React.PureComponent{
    constructor(props){
        super(props);
        this.state={
            src: ''
        }
    }


    async UNSAFE_componentWillMount(){
        let word = this.props.text
        word = word.toLowerCase()
        word = word.replace('.', '')
        try {
            const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../../../source/ReadingTrain-Core/ReadingTrain-Core/SchemaImages/${word}.jpg`);
            await this.setState({ src: src });
           
      } catch (err) {
            word = word.slice(0, word.length - 1)
            try {
                const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../../../source/ReadingTrain-Core/ReadingTrain-Core/SchemaImages/${word}.jpg`);
            await this.setState({ src: src });
          } catch (err) {
          }
        }
      }

    render(){
        return(
            <div text={this.props.text} onClick = {this.props.show} className={classes.rtShema}>
                <img text={this.props.text} alt='' src={this.state.src} />
                <div text={this.props.text} className={classes.text}> {this.props.text} </div>
            </div>
        )
    }
}

export default Shema