import React from 'react'
import classes from './Notification.module.scss'
import sign from '../../../source/Images/menus/alert-sign@2x.png'
import alert_post from '../../../source/Images/menus/alert-post@2x.png'
import alert_light from '../../../source/Images/menus/alert-light@2x.png'
import alert_light_green from '../../../source/Images/menus/alert-light-green-on.png'
import alert_light_red from '../../../source/Images/menus/alert-light-red-on.png'
class Notification extends React.PureComponent
{
    constructor(props)
    {
        super(props);
        this.state = {
            
        }
    }

    componentDidMount()
    {
        
    }
    render(){
return(
            <div className={classes.bg}>
                <div className={classes.Notification}>
                    <div className={classes.alert}>
                        <p className={classes.alertText}> {this.props.text} </p>
                        <img alt='' className={classes.alertSign}  src={sign}/>
                        <img alt='' className={classes.alertPost}  src={alert_post}/>
                    </div>
                    <div className={classes.button}>
                        <img alt='' className={classes.alert_light}  src={alert_light}/>
                        <img alt='' className={classes.alertPostLight}  src={alert_post}/>
                        <div onClick={ () => {this.props.onClick('yes') }} className={classes.okBlock}>
                            <img alt='' className={classes.greenLight} src={alert_light_green}/>
                            <p className={classes.okay}>OK</p>
                        </div>
                        <div onClick={ () => {this.props.onClick('no')} } className={classes.noBlock}>
                            <img alt='' className={classes.redLight} src={alert_light_red}/>
                            <p className={classes.no}>NO</p>
                        </div>
                        
                    </div>
                </div>
            </div>
        )
        
    }
}

export default Notification