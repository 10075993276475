import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {auth, store, db} from '../../../../Firebase/fireconfig'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
let options = [
  'Додати застереження',
  'Додати відео'
];

const ITEM_HEIGHT = 48;

export default function LongMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [show, setShow] = React.useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation()
    
    setAnchorEl(null);
  };

  return (
    <div style={props.style} >
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ opacity:'0.6', border: 'solid 5px transparent' }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '200px',
          },
        }}
      >
        <MenuItem key={'delete'} style ={props.styleShow ? { display:'block'}:{display:'none'}}  onClick={(ev) => {
          setShow(true)
        } }>
          Delete account 
        </MenuItem>
        <MenuItem key={'logout'}  onClick={(ev) => {
          auth.signOut().then( () => {
            props.history.push('/login')
          } )
        } }>
          Log out 
        </MenuItem>
      </Menu>

      <Dialog
        open={show}
        onClose={()=>{setShow(false)}}
      >
        <DialogTitle  >
        Delete account
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/* <p style={{color:'red !important'}}> */}
            Are you sure you want to delete the account?
            {/* </p> */}
          </DialogContentText>
        
        </DialogContent>
        <DialogActions>
          <Button style={{color:'black'}} onClick={ () => { 
            let tag = 'child_user'
            db.collection(tag).where('email', '==', auth.currentUser.email).get().then( response => {
              response.forEach( data => {
                db.collection(tag).doc(data.id).delete()
                auth.currentUser.delete()
                props.history.push('login')
              } )
            } )
           }} color="secondary">
            Delete
          </Button>
          <Button style={{color:'black'}}  onClick={ () => {setShow(false)}} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}