
export function validetaName(name){
    let regexp = /[A-Z][a-zA-Z][^:&<`'^]{0,20}$/g
    if(name.replace(' ', '').length > 0)
    {
        return true
    }
    else return false
}

export function validetaEmail(email){
    let regexp = /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/g
    if(regexp.exec(email))
    {
        return true
    }
    else return false
}

export function validetaZip(zip){
    let regexp = /^\d{5}-\d{4}|\d{5}|[A-Z]\d[A-Z] \d[A-Z]\d$/g
    if(regexp.exec(zip))
    {
        return true
    }
    else return false
}

export function validetaData(data){
    if(data === '')
    {
        return true
    }
    else return false
}

export function validetaPassword(password){
    let regexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/g
    if(regexp.exec(password))
    {
        return true
    }
    else return true
}