import React from 'react'
import classes from './FullBlock.module.scss'
import Block from './Block/Block'
import { DragSource } from 'react-dnd'
// import img from '../../../../source/ReadingTrain-Core/ReadingTrain-Core/SchemaImages'
import backButton from '../../../Level/components/Unit/img/icon.png'

const itemSource={
    beginDrag(props, monitor, component){
        return {component};
    },

    async endDrag(props, monitor, component) {
        const dropResult = monitor.getDropResult()
        if(dropResult)
        {   
            if(dropResult.type === 'move')
            {
                let car = document.getElementById(dropResult.id)
                if(car.childNodes.length >= 2)
                {
                    if(car.childNodes[car.childNodes.length - 1].id.slice(1, car.childNodes[car.childNodes.length - 1].id.length) !== 'dragCopy')
                    {
                        if(dropResult.ids === car.childNodes[1].id){
                        
                        }
                        else
                        {
                            let cardSwap = car.childNodes[2]
                            if(!cardSwap)
                            {   
                                cardSwap = car.childNodes[1]
                            }
                            let cardSwapId = cardSwap.id
                            cardSwap = document.getElementById(cardSwapId)
                            let drag = document.getElementById(cardSwapId.slice(0,1) + 'drag')
                            drag.appendChild(cardSwap)
                            let card = document.getElementById(dropResult.ids)
                            document.getElementById(dropResult.id).appendChild(card)
                        }
                    }
                    else{
                        let card = document.getElementById(dropResult.ids)
                        document.getElementById(dropResult.id).appendChild(card)    
                    }
                }
                else{
                    let card = document.getElementById(dropResult.ids)
                    document.getElementById(dropResult.id).appendChild(card)
                }
            }
            else
            if(dropResult.type === 'info')
            {
                if(document.getElementById(dropResult.ids))
                {
                    let drag = document.getElementById(dropResult.ids)
                    let drop = document.getElementById('dropInfo')
                    drop.appendChild(drag)

                    let blockText = ''
                    let z =''
                    let checkId = document.getElementById(dropResult.ids).childNodes[0].childNodes[0]
                    if(checkId.childNodes[1])
                    {
                    blockText =   checkId.childNodes[1].childNodes[0].childNodes[0].childNodes[0].innerHTML
                    }
                    else
                    {
                        blockText =   checkId.childNodes[0].childNodes[0].childNodes[0].childNodes[0].innerHTML
                }
                blockText = blockText.replaceAll(' ', '')
                blockText = blockText.replaceAll(' ', '')
                blockText = blockText.replaceAll('.', '')
                blockText = blockText.toLowerCase()
                try {
                        const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/ReadingTrain-Core/ReadingTrain-Core/WordAudio/${blockText}.mp3`);
                        z = src
                    }
                    catch (err) 
                    {
                    }
                    let audio = new Audio()
                    audio.src = z
                    audio.play()
                    audio.onended = () => {
                        let num = parseInt(dropResult.ids)
                        let drop = document.getElementById(num + 'drag')
                        let drag = document.getElementById(dropResult.ids)
                        drop.appendChild(drag)
                    }
                }
            }
            else{
            }
            
           
        }
        else{
            let drop = document.getElementById(props.id + 'drag')
            let drag = document.getElementById(props.id + 'Card')
            drop.appendChild(drag)
        }

        return props.handleDrop(props.id);
    }

    
}

function collect(connect, monitor) {
    return{
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        isDragging: monitor.isDragging(),
    }
}

let left 

class FullBlock extends React.PureComponent{
    constructor(props){
        super(props);
        this.state ={
            img: '',
            png: []
        }
    }



    async componentWillReceiveProps(){
        switch(this.props.leng){
            case 0: {left = 0; break;}
            case 1: {left = 17; break;}
            case 2: {left = 23; break;}
            case 3: {left = 20; break;}
            case 4: {left = 20; break;}
            case 5: {left = 18; break;}
            case 6: {left = 16; break;}
            case 7: {left = 10; break;}
            case 8: {left = 7; break;}
            case 9: {left = 3; break;}
            case 10: {left = 2; break;}
            default: { left = 20}
        }
        if(this.props.present)
        {
            let png = []
            for(let i = 0; i < 8; i++)
            {
                try 
                {
                    const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/Images/trains/present-${Math.floor(Math.random()*5)}@2x.png`);
                    png.push(src)
                } catch (err) {
                } 
            }
            this.setState({
                png: png
            })
            this.forceUpdate()

        }
        else if(this.props.type){
            let txt = this.props.text
            if(txt.slice(txt.length - 1, txt.length) === ' ')
            {
                txt = txt.slice(0,-1)
            }
            try {
                txt = txt ? txt.replaceAll("_", " ") : txt
                const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/ReadingTrain-Core/ReadingTrain-Core/SchemaImages/${txt}.jpg`);
            await this.setState({ img: src });
            this.forceUpdate()
            this.forceUpdate()
          } catch (err) {
            this.setState({ err: err.toString() });
          }
        }
        else{
        if(this.props.shema.includes(this.props.text) )
        {
            let txt = this.props.text
            if(txt.slice(txt.length - 1, txt.length) === ' ')
            {
                txt = txt.slice(0,-1)
            }
            try {
                txt = txt ? txt.replaceAll("_", " ") : txt
                const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/ReadingTrain-Core/ReadingTrain-Core/SchemaImages/${txt}.jpg`);
            await this.setState({ img: src });
            this.forceUpdate()
            this.forceUpdate()
          } catch (err) {
          }
        }
        else 
        if(this.props.type)
        {
            let txt = this.props.text
            if(txt.slice(txt.length - 1, txt.length) === ' ')
            {
                txt = txt.slice(0,-1)
            }
            try {
                txt = txt ? txt.replaceAll("_", " ") : txt
                const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/ReadingTrain-Core/ReadingTrain-Core/SchemaImages/${txt}.jpg`);
                await this.setState({ img: src });
            this.forceUpdate()
            this.forceUpdate()
          } catch (err) {
          }
        }}
        this.forceUpdate()
    }

    async componentDidUpdate(prevProps, prevState){
        if(prevProps.text !== this.props.text){
            let txt = this.props.text
            if(txt.slice(txt.length - 1, txt.length) === ' ')
            {
                txt = txt.slice(0,-1)
            }
            try {
                txt = txt ? txt.replaceAll("_", " ") : txt
                txt = txt.toLowerCase()
                txt = txt.replaceAll('.','')
                txt = txt.replaceAll('!','')
                txt = txt.replaceAll('?','')
                txt = txt.replaceAll(',','')
                const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/ReadingTrain-Core/ReadingTrain-Core/SchemaImages/${txt}.jpg`);
                this.setState({ img: src });
            this.forceUpdate()
            this.forceUpdate()
          } catch (err) {
          }
            this.forceUpdate()
        }

    }
    render(){
        const {isDragging, connectDragSource} = this.props
        const opacity = isDragging ? 0 : 1;
            return connectDragSource(
                <div id={this.props.id + 'Card'} style={this.props.present ? {position:'absolute', bottom:'26%', left:"17px" ,opacity, }  :{position:'absolute', bottom:'26%', left:left+'px' ,opacity,  display: this.props.display }} >
                    
    
                    <div draggable="true"
                     className={classes.FullBlock}>
                        { 
                        this.props.present ?
                            <div onClick id=''>
                                <Block style1={true} img = {this.state.png[Math.floor(Math.random()*7)]} />
                                <Block style1={true} img = {this.state.png[Math.floor(Math.random()*7)]} />
                            </div>
                        :
                        this.props.type ?   
                            <div id=''>
                                <Block img = {this.state.img} />
                                <Block text = {this.props.text} />
                            </div>
                            :
                        !this.props.shema.includes(this.props.text) 
                        ? 
                        <div id=''>
                        <Block text = {this.props.text} />
                        </div>
                        :
                        <div id=''>
                            <Block img = {this.state.img} />
                            <Block text = {this.props.text} />
                        </div>
                        }
    
                    </div>
                </div>
            )
    }
}

export default DragSource('item', itemSource, collect)(FullBlock)