import React from 'react'
import classes from './Shop.module.scss'
import shop from '../../../../source/Images/station/station-shop@2x.png'
import sign from '../../../../source/Images/station/station-shop-sign@2x.png'
import dog from '../../../../source/Images/conductors/Spot/conductor-spot-forward-body@2x.png';
import leftHand from '../../../../source/Images/conductors/Spot/conductor-spot-forward-arm-left-resting@2x.png'
import rightHand from '../../../../source/Images/conductors/Spot/conductor-spot-forward-arm-right-resting@2x.png'
import { DropTarget } from 'react-dnd';


function collect(connect, monitor) {
    return {
      connectDropTarget: connect.dropTarget(),
      hovered: monitor.isOver(),
      item: monitor.getItem(),
    }
  }
  
  
  const itemSource={
      
  
      drop(props, monitor, component) {
          const item = monitor.getItem()
          return {
              id: props.id + "Car",
              ids: item.component.props.id + "Card",
              type: 'info'
            }
      }
  
      
  }



class Shop extends React.PureComponent{
    constructor(props){
        super(props);
        this.state={

        }
    }

    render(){
        const { connectDropTarget} = this.props;
        return(
            connectDropTarget(
                <div className={classes.Shop}>
                <div className={classes.sign}>
                    <div className={classes.signText}> Help </div>
                    <img className={classes.signImg} src={sign} alt='' />
                </div>
                <div id='dropInfo' className={classes.dropInfo}>

                </div>
                <img src = {shop} alt='' />
                <img className={classes.dog} src={dog} alt='' />
                <img className={classes.leftHand} src={leftHand} alt='' />
                <img className={classes.rightHand} src={rightHand} alt='' />
            </div>
            )
            
        )
    }
}

export default DropTarget('item', itemSource, collect)(Shop)
// export default Shop