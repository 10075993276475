import React from 'react'
import classes from './UserInfo.module.scss'

import  '../../../components/validate'
import { validetaName, validetaZip, validetaEmail, validetaPassword } from '../../../components/validate'
import firebase from '../../../../../Firebase/fireconfig'
import {auth, db} from '../../../../../Firebase/fireconfig'
import Input from '@material-ui/core/OutlinedInput'
import educators from '../../img/fox.jpg'
import { Button } from '@material-ui/core'

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
class UserInfo extends React.PureComponent {
    constructor(props){
        super(props)
        this.state = {
            title: '',
            firstName: '',
            lastName: '',
            educatorRole: '',
            grade: '',
            readingLevelSystem: '',
            schollAddress: '',
            schoolName: '',
            schoolType: '',
            zipCode: '',
            city: '',
            usedClassCode: '',
            MainDataSuccessful: false,
            email: '',
            password: '',
            passwordrep: '',
            keyData: '',
            createChild: false,
        }

        this.checkText = this.checkText.bind(this)
        this.submitRegisterEmail = this.submitRegisterEmail.bind(this)
    }
    async submitRegisterEmail(evnt){
        evnt.preventDefault()
        let ev = this.state
        let form = document.getElementById('registerForm')
        if(validetaName(ev.firstName))
        {   
            if(validetaName(ev.lastName))
            {
                if(true)
                {
                    if(validetaPassword(ev.password))
                    {
                        if(ev.password === ev.passwordrep)
                        {
                            if(ev.password.length < 8){
                                alert("Password must be at least 8 character")
                            }else{
                                let errorr = false
                                let email = validetaEmail(ev.email) ? ev.email : ev.email + '@gmail.com' 
                                await auth.createUserWithEmailAndPassword(email,ev.passwordrep).catch(function(error) {
                                    // Handle Errors here.
                                    var errorCode = error.code;
                                    var errorMessage = error.message;
                                    if (errorCode) {
                                        errorr = true
                                        alert(errorCode)
                                        return;
                                    } 
                                    
                                    // ...
                                });
    
                                auth.signInWithEmailAndPassword(ev.email,ev.passwordrep)
            
                                if(!errorr)
                                {
                                    let email = validetaEmail(this.state.email) ? this.state.email : this.state.email + '@gmail.com' 
                                    let user_data = {
                                        firstName: this.state.firstName,
                                        lastName: this.state.lastName,
                                        usedClassCode: this.props.class ? this.props.class : this.state.usedClassCode,
                                        email: email.toLowerCase(),
                                        tag: this.props.tag,
                                        coins: 5,
                                        readedBook: {},
                                        correctAnswer: 0,
                                        falseAnswer: 0,
                                        teacherClasses: []
                                    }
                                    db.collection('child_user').doc(auth.currentUser.uid).set(user_data).then( () => {
                                        alert('User created')
                                        this.setState({
                                            title: '',
                                            firstName: '',
                                            lastName: '',
                                            educatorRole: '',
                                            grade: '',
                                            readingLevelSystem: '',
                                            schollAddress: '',
                                            schoolName: '',
                                            schoolType: '',
                                            zipCode: '',
                                            city: '',
                                            usedClassCode: '',
                                            MainDataSuccessful: false,
                                            email: '',
                                            password: '',
                                            passwordrep: '',
                                            keyData: '',
                                            createChild: false,
                                        })
                                        if( this.props.tag === 'educators' )
                                        this.props.history.push('level')
                                    } ).catch( (err) => {
                                    } )
                                }
                            }
                          
                        }   
                        else
                        {
                            alert('Password must be same')
                        }
                    }
                    else
                    {
                        alert('Password must contain at least 1 lowercase, 1 uppercase, 1 numeric, one special character, must be eight characters or longer ')
                    }
                }
            }else{
                alert('incorrect second name')
            }
        }else{
            alert('incorrect name')
        }
        
    }

    
    checkText(item) {
        if( item.value === '' )
        {
            item.style.borderColor = 'red'
            return false
        }
        else
        {
            item.style.borderColor = 'inherit'
            return true
        }
    }

    render(){
        return(
        <div className={classes.UserInfo}>
            <div className={classes.form}>
            <div style={{display:'flex', justifyContent:'center'}}>
                <img alt="" src={educators} />
            </div>
             <form id='registerForm' name='registerForm' onSubmit={this.submitRegisterEmail}>
             <div className={classes.line}>
                 <Input 
                    style={{width:'100%', marginBottom:'10px'}}
                    autoComplete='false'
                     title='First Name'
                     name='firstName'
                     type='input'
                     help={false}
                     heplText=''
                     value={this.state.firstName}
                     placeholder='First Name'
                     onChange={(ev) => { this.setState({ firstName: ev.target.value }) }}
                 /> 
             </div>
         
             <Input 
             style={{width:'100%', marginBottom:'10px'}}
             autoComplete='false'
                 title='Last Name'
                 name='lastName'
                 type='input'
                 help={false}
                 heplText=''
                 value={this.state.lastName}
                 placeholder='Last Name'
                 onChange={(ev) => { this.setState({ lastName: ev.target.value }) }}
                 />
             
         
           
                   
             <Input
             inputProps={{
                autocomplete: 'new-password',
                form: {
                  autocomplete: 'off',
                },
              }} 
             style={{width:'100%', marginBottom:'10px'}}
                     title={ this.props.tag === 'child' ? 'Nickname':'Email'}
                     autoComplete='false'
                     name='email'
                     type='input'
                     help={false}
                     heplText=''
                     value={this.state.email}
                     placeholder={ this.props.tag === 'child' ? 'Nickname':'Email'}
                     onChange={(ev) => { this.setState({ email: ev.target.value }) }}
                     />     
                     <Input 
                     inputProps={{
                        autocomplete: 'new-password',
                        form: {
                          autocomplete: 'off',
                        },
                      }}
                     style={{width:'100%', marginBottom:'10px'}}
                        title='Password'
                        autoComplete='false'
                        name='password'
                        type='password'
                        help={false}
                        heplText=''
                        value={this.state.password}
                        placeholder='Password'
                        onChange={(ev) => { this.setState({ password: ev.target.value }) }}
                     />  
                     <Input 
                     inputProps={{
                        autocomplete: 'new-password',
                        form: {
                          autocomplete: 'off',
                        },
                      }}
                     style={{width:'100%', marginBottom:'10px'}}
                        title='Repeat Password'
                        autoComplete='false'
                        name='repeatPassword'
                        type='password'
                        help={false}
                        heplText=''
                        value={this.state.passwordrep}
                        placeholder='Repeat Password'
                        onChange={(ev) => { this.setState({ passwordrep: ev.target.value }) }}
                     />   
                     <Button type='submit' style={{backgroundColor:'#FFDEE0'}}> Register </Button>
                     <Button style={{marginTop:'10px', backgroundColor:'#FFDEE0'}} onClick={(ev) => {this.props.create('') }} > Cancel </Button>
         </form>
                
                
            </div>
        </div>)
    }
}

export default UserInfo