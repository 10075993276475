import { connect } from 'react-redux';
import { compose} from 'recompose';

import UserType from './UserType';

const mapStateToProps = state => ({
    data: state,
});

export default compose(
    connect(mapStateToProps),
)(UserType);
