import React from 'react'
import './main.css'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux'
import { withCookies, Cookies } from 'react-cookie';
import ReactCodeInput from 'react-verification-code-input';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { auth } from '../../../Firebase/fireconfig'
import {validetaEmail} from '../components/validate'
const TextField2 = withStyles({
    root: {
      borderRadius: 3,
      border: 0,
      color: 'white',
      padding: '0 30px',
      boxShadow: '0 3px 5px 2px rgba(100, 87, 88, .3)',
      padding:0,
      margin: '6px auto 3px auto',
      '& label.Mui-focused': {
        color: 'white',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#FFDEE0',
      },
      '& .MuiInputLabel-formControl':{
        // top: -15
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#FFDEE0',
        },
      },
    },
   
  })(TextField);
const DialogContentText1 = withStyles({
	root: {
	  color:'red !important'
	  
	},
   
  })(DialogContentText);
class LoginPage extends React.PureComponent{
    constructor(props){
		super(props)
		this.FA2Ref = React.createRef();
        this.state = {
			errorMessage: { show: false, error: '' }
		}
		this.login = this.login.bind(this)
		this.loginAnonim = this.loginAnonim.bind(this)
	}

	loginAnonim(){
		auth.signInAnonymously()
			.then((firebaseUser) => {
			console.log("firebaseUser", firebaseUser)
			this.props.history.push('level')
		})
		.catch(function(error) {
			// Handle Errors here.
			var errorCode = error.code;
			var errorMessage = error.message;
			// ...
		});
	}

	login(){
		if(this.state.email){
			if(this.state.pass){
				let email = validetaEmail(this.state.email) ? this.state.email : this.state.email + '@gmail.com' 
				auth.signInWithEmailAndPassword(email, this.state.pass)
				.then((firebaseUser) => {
					this.props.history.push('level')
				})
			   .catch((error) => {
					this.setState({
						errorMessage: { show: true, error: error.message }
					})
			   });
			}else{
				this.setState({
					errorMessage: { show: true, error: 'Input password' }
				})
			}
		}else{
			this.setState({
				errorMessage: { show: true, error: 'Input email' }
			})
		}
		
	}

	renderMainLogin(){
		return(
			<div className="wrap-login100">
				<form onSubmit={ (ev) => { ev.stopPropagation() } } className="login100-form validate-form">
					<div style={{display:'flex', justifyContent:'center', marginTop:'-55px'}} >
					</div>
					<span className="login100-form-title p-b-26">
						Login
					</span>
					<span className="login100-form-title p-b-48">
						<i className="zmdi zmdi-font"></i>
					</span>

					<div className="wrap-input100 validate-input" style={{marginBottom: '37px'}} data-validate = "Valid email is: a@b.c">
					<input
						value = {this.state.email}
						onChange = { (ev) => this.setState({ email: ev.target.value }) }
						 className="input100" type="email" placeholder='Email or Nickname' />
					</div>

					<div className="wrap-input100 validate-input" data-validate="Enter password">
						<span className="btn-show-pass">
							<i className="zmdi zmdi-eye"></i>
						</span>
						<input
						value = {this.state.pass}
						onChange = { (ev) => this.setState({ pass: ev.target.value }) }
						 className="input100" type="password" placeholder='Password'/>
						
					</div>

					<div>
						<div >
							<div></div>
							<Button style={{backgroundColor:'#FFDEE0'}} onClick={this.login} >
								Login
							</Button>
							<div style={{marginTop:'15px'}}>
							<Button style={{backgroundColor:'#FFDEE0'}} onClick={ () => {this.loginAnonim(); } } >
							Log in as Guest
							</Button>
							</div>
							<div style={{marginTop:'15px'}}>
							<Button style={{backgroundColor:'#FFDEE0'}}  onClick={() => { this.props.history.push('register') }} >
								Register
							</Button>

							</div>
							
						</div>
					</div>
				</form>
			</div>
		)
	}


    render(){

        return(
            <div>
	
	<div className="limiter">
		<div className="container-login100">
			{
				
				this.renderMainLogin()
			}

		</div>
		<Dialog
              open={this.state.errorMessage.show}
              onClose={()=>{this.setState({ errorMessage: { show: false, error: '',  }, value: '' }) }}
              // aria-labelledby="draggable-dialog-title"
              // style={{width: '1000px'}}
            >
              <DialogTitle  >
                  Error
              </DialogTitle>
              <DialogContent>
                <DialogContentText1 style={{color:'red !important'}} >
                  {/* <p style={{color:'red !important'}}> */}
                      {this.state.errorMessage.error}
                  {/* </p> */}
                </DialogContentText1>
              
              </DialogContent>
              <DialogActions>
                {/* <Button  onClick={ () => {this.setState({ addCategory: false, newCategotryName: '', id: '', addCategoryError:'' })}} color="secondary">
                  Скасувати
                </Button> */}
                <Button onClick={ () => { this.setState({ errorMessage: { show: false, error: '',  }, value: '' }) }} color="secondary">
                  ОК
                </Button>
              </DialogActions>
            </Dialog>

		
	</div>

	<div id="dropDownSelect1"></div>

</div>
        )
    }
}


const select = appState => ({
    results: appState.User,
  })
  

export default connect(select)(withCookies(LoginPage))