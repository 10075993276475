import React from 'react'
import classes from './ModeButton.module.scss'
import coin_music_price from '../../../../../../source/Images/menus/coin-music-price@2x.png'
class ModeButton extends React.PureComponent{
    constructor(props){
        super(props);
        this.state={
            text:['Listen','Read','Record', 'Playback'],
            img:[
                require('../../img/listen.png'),
                require('../../img/read.png'),
                require('../../img/record.png'),
                require('../../img/playback.png')
            ],
        }
    }

    

    render(){
        return(
            <div className={classes.rtModeButton} onClick={()=> {this.props.onClick(this.state.text[this.props.index])}}>
                    {this.props.type === 'song' ? 
                    <div className={classes.price}> 
                        <img className={classes.priceImg} alt='' src={coin_music_price} /> 
                        <p className={classes.priceText}>  { this.props.index !== 2 ? this.props.price : 0} </p>
                    </div> : <div/>}
                        <img alt='' src={this.state.img[this.props.type==='song' ? this.props.index===1 ? this.props.index+1 : this.props.index===2 ? this.props.index+1 : this.props.index : this.props.index]} />    
                        <div className={classes.text}> {this.state.text[this.props.type==='song' ? this.props.index===1 ? this.props.index+1 :this.props.index===2 ? this.props.index+1 : this.props.index : this.props.index]} </div>
            </div>
        )
    }
}

export default ModeButton