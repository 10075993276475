import React from 'react'
import classes from './Platform.module.scss'
// import partOfPlatform from '../../../../source/Images/station/platform@2x.png'
class Platform extends React.PureComponent {
    constructor(props){
        super(props);
        this.state={

        }
    }

    render(){
        return(
            <div className={classes.Platform}>
                {/* <img src={partOfPlatform} alt='' /> */}
            </div>
        )
    }
}

export default Platform