import React from 'react'
import classes from './LevelRender.module.scss'
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import FittedImage from 'react-fitted-image'
import './spiner.css'
class LevelRender extends React.PureComponent{
    constructor(props){
        super(props);
        this.state ={
            level: this.props.level,
            unit: this.props.unit,
            src: '',
            name: this.props.name,
            bg: ''
        }
    }


    componentDidUpdate(prevProps) {
            this.getXML()
      } 

    UNSAFE_componentWillMount(){
        this.getXML()
    }

    

    async getXML(){
        let plist = require('plist');
        let obj = {
            name: '',
            src: '',
            bg: '',
        }
        if(this.props.title === null && this.props.level !== '00004')
        {
            try {
            const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/ReadingTrain-Core/ReadingTrain-Core/Courses/${this.props.level}/${this.props.unit}/unit.js`);
            let json = await plist.parse(src)
            obj.name = json.name
            this.props.getTitle(json.name)
          } catch (err) {
          }
        }
        else
         {
            obj.name = this.props.title
        }
        if(this.props.level === '00004')
        {
            try {
                const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/ReadingTrain-Core/ReadingTrain-Core/Songbooks/${this.props.unit}/0.jpg`);
                obj.src = src
            } catch (err) {
            }
        }
        else
        {
            try {
                const { default: src } = await import(/* webpackMode: "lazy" */ `../../../../source/ReadingTrain-Core/ReadingTrain-Core/Courses/${this.props.level}/${this.props.unit}/cover.jpg`);
                obj.src = src
            } catch (err) {
            }
        }
        this.setState({
            name: obj.name,
            src: obj.src,
        })
    }




    render(){
        return(
            <div onClick={ () => {this.props.checkUnit(this.props.unit, this.state.name)} } className={classes.rtLevelRender}>
                <div className={classes.leftSide}></div>
                <div className={classes.mainSide} 
                style ={ { backgroundImage: "url("+this.props.bg+")" } }
                >
                    {/* <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> */}
                    <div className={classes.imgBox}>
                            <div id='text' className={classes.unitTitle}> 
                                {this.state.name}
                            </div> 
                        <LazyLoadComponent>
                        <FittedImage
                            fit="auto"
                            loader={<div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>}
                            onLoad={(...args) => console.log(...args)}
                            onError={(...args) => console.log(...args)}
                            src={this.state.src}
                            />
                        </LazyLoadComponent>
                    </div>
                </div>
            </div>
        )
    }
}

export default LevelRender